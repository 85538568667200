import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  CustomInput,
} from 'reactstrap';
import Flex from '../../components/clienttable/Flex';
import Accordion from '../../components/common/accordion/Accordion';
import FalconCardHeader from '../../components/clienttable/FalconCardHeader';

import setupChecklist from '../../data/clienttable/setupChecklist';

import Icon from '@iconify/react';
import closeOutlined from '@iconify/icons-ant-design/close-outlined';

import SessionService from '../../services/SessionService';


const CloseButton = ({ onClick }) => {
  return (
    <Icon
      className="ml-2"
      style={{ width: "1.75rem", height: "1.75rem" }}
      icon={closeOutlined}
      onClick={onClick}
    />
  );
}

const SetupChecklistCard = () => {
  const [cardHidden, setCardHidden] = useState(parseInt(SessionService.getSetupHidden()));
  const [bitmap, setBitmap] = useState(SessionService.getSetupBitmap());

  useEffect(() => {
    SessionService.setSetupBitmap(bitmap);
  }, [bitmap])

  const onCloseCard = () => {
    // Hide card, remember in session storage, and inform the user of button to restore it
    setCardHidden(true);
    SessionService.setSetupHidden(1);
    toast.info("Card can be found under the Organization page");
  }

  const toggleBit = (index) => {
    // ^ is bitwise XOR
    setBitmap(bitmap ^ Math.pow(2, index));
  }

  const getBit = (index) => {
    // & is bitwise AND
    return (bitmap & Math.pow(2, index)) > 0;
  }

  return (
    <Card className="mb-3" hidden={cardHidden}>
      <FalconCardHeader title="Account Setup Checklist" light={false} children={<CloseButton onClick={onCloseCard} />} />
      <CardBody className="bg-light">
        {setupChecklist.map((step, index) =>
          <Accordion
            title={
              <Flex inline={true} align="center" className="mb-2">
                {getBit(index) ? <s>{index+1}. {step.title}</s> : <>{index+1}. {step.title}</>}
                <Icon className="ml-2" style={{ width: "1.75rem", height: "1.75rem" }} icon={step.icon} />
                <CustomInput
                  id={`setup-checkbox-${index}`}
                  className="ml-3"
                  onChange={() => toggleBit(index)}
                  type="checkbox"
                  checked={getBit(index)}
                  label="Mark as Completed"
                />
              </Flex>
            }
            description={step.description}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default SetupChecklistCard;