import React from "react";
import useHover from "../../../foundations/hooks/useHover";
import { Title } from "../../../foundations/components/presentation";
import { Avatar, AvatarVariants } from "../../../foundations/components/chat";
import { fullNameInitials } from "../../../../../utils/util";
import { Icon } from "@iconify/react";
import userDeleteOutlined from "@iconify-icons/ant-design/user-delete-outlined";
import { Button, ListGroupItem } from "reactstrap";

export interface UserFragment {
  name: string;
  id: string;
  custom: {
    title: string;
  };
  profileUrl: string;
}

interface MemberDescriptionProps {
  user: UserFragment;
  you: boolean;
  canRemove: boolean;
  onRemoveMember: () => void;
}

const MemberDescription = ({ user, you, canRemove, onRemoveMember }: MemberDescriptionProps) => {
  const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
  var userName = ('name' in user) ? user.name : ""
  const nameWithYou = `${user.name}${you ? " (you)" : ""}`;

  return (
    <ListGroupItem
      className="d-flex flex-row p-3"
      {...hoverProps}
    >
      <div className="d-flex align-items-center mr-2">
        <Avatar
          variant={AvatarVariants.ROUND}
          bg="#344050"
        >
          <b className="text-light fs--2">{fullNameInitials(userName)}</b>
        </Avatar>
      </div>
      <Title className="mr-auto text-primary" style={{paddingTop: "4px"}} heading={nameWithYou} capitalize></Title>
      {canRemove &&
        <Button
          color="falcon-danger"
          size="sm"
          onClick={e => {
            e.stopPropagation();
            onRemoveMember();
          }}
          className="p-0"
        >
          <Icon icon={userDeleteOutlined} height="25px" width="25px" />
        </Button>
      }
    </ListGroupItem>
  );
};

export { MemberDescription };
