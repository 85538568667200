import React, { useEffect } from "react";
import { checkIfSubdomainExists } from "../../api/organization.api";
import { getSubDomain } from "../../utils/util";

const Filedrop = () => {
  
  useEffect(() => {
    setScriptTag()
  })

  const setScriptTag = async e => {
    const subdomain = getSubDomain()
    const url = process.env.REACT_APP_URL_PREFIX + subdomain + '.' + process.env.REACT_APP_BASE_URL
    // url format https://{subdomain}.{cydlio.com}
    const res = await checkIfSubdomainExists(subdomain);
    const d = document;
    const s = d.createElement("script");
    s.src=`${url}/${res.data.organizationId}/filedrop/filedrop.js`;
    // src format https://{subdomain}.{cydlio.com}/{orgId}/filedrop/filedrop.js
    s.async = true;
    d.getElementsByTagName("body")[0].appendChild(s);
  }

  return (
    <div>
      <div className="ct-filedrop" />
    </div>
  );
}

export default Filedrop;