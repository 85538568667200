import React, {useState} from 'react';
import PropTypes from 'prop-types';
import amplitude from 'amplitude-js';
import {toast} from 'react-toastify';
import {
  Alert,
  Button,
  Form,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, 
  InputGroup
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FormGroupInput from '../components/common/FormGroupInput';
import { Icon } from '@iconify/react';
import infoCircleOutlined from '@iconify/icons-ant-design/info-circle-outlined';
import InfoLabel from '../components/clienttable/common/InfoLabel';

import { createClient, createStaff, getInviteLink, sendInvitationCode } from '../api/user.api';
import driveApi from "../api/drive.api";
import { get } from "../api/organization.api";
import SessionService from '../services/SessionService';

const generateInviteLink = async (id, setInviteLink) => {
  const result = await getInviteLink(id);
  const link = result.data.link;
  setInviteLink(link);
};

const AddUser = ({userData, setUserData, onClose, onCreate, role}) => {
  return (
    <Form onSubmit={onCreate}>
      <ModalHeader>{role === "CLIENT" ? "Create New Client" : "Add Staff user"}</ModalHeader>
      <ModalBody>
        <FormGroupInput
          id="first-name"
          label="First Name*"
          value={userData.firstName}
          onChange={({target}) => setUserData({...userData, firstName: target.value})}
        />
        <FormGroupInput
          id="last-name"
          label="Last Name"
          value={userData.lastName}
          onChange={({target}) => setUserData({...userData, lastName: target.value})}
        />
        <FormGroupInput
          id="email"
          label="Email*"
          value={userData.email}
          onChange={({target}) => setUserData({...userData, email: target.value})}
          type="email"
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
        <Button color="primary" type="submit" onClick={onCreate} disabled={!userData.firstName || !userData.email}>Create and Continue</Button>
      </ModalFooter>
    </Form>
  );
}

const NotifyUser = ({sendEmail, userData, setUserData, onSkip}) => {
  return <>
      <ModalHeader>Notify User</ModalHeader>
      <ModalBody>
      <p className="font-weight-bold">Invitation Link</p>
      <div className="input-group my-3 pr-4">
        <InputGroup>
          <CustomInput
            id="activation-link"
            className="form-control"
            label=""
            value={userData.inviteLink}
            type="link"
            disabled={true}
          />
          <CopyToClipboard className="ml-1 my-0" text={userData.inviteLink} onCopy={() => toast.success(`Successfully copied link to your clipboard`)}>
            <Button color="falcon-default" size="sm" >
              <FontAwesomeIcon icon="copy"/>
            </Button>
          </CopyToClipboard>
        </InputGroup>
      </div>
      <div className="d-flex flex-row">
        <Icon icon={infoCircleOutlined} className="fs-3 mr-2 text-primary"/>
        <div>
          <p className="font-weight-bold mb-0">Share this invitation link directly with the user.</p> 
          <p>Anyone with this link will be able to create an account for this user.</p>
        </div>
      </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSkip}>Skip</Button>
      <Button color="primary" onClick={sendEmail}>{"Send Invite Email"}</Button>
      </ModalFooter>
  </>
}

const CreateSharedFolder = ({sharedFolderData, setSharedFolderData, onSkip, onCreate, isDriveConnected}) => {
  return (
    <Form onSubmit={onCreate}>
      <ModalHeader>Create New Shared Folder</ModalHeader>
      <Alert color='danger' isOpen={!isDriveConnected}>No drive is connected - cannot create folder</Alert>
      <ModalBody>
        <FormGroupInput
          id="first-name"
          label="Folder Name"
          value={sharedFolderData.folderName}
          onChange={({target}) => setSharedFolderData({...sharedFolderData, folderName: target.value})}
        />
        <InfoLabel className="mb-2" bold={true} text="Clients without shared folders cannot upload documents" />
        <p style={{textAlign:"center"}}>A folder can also be shared from the Documents page</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSkip}>Skip</Button>
        <Button color="primary" type="submit" onClick={onCreate} disabled={!sharedFolderData.folderName || !isDriveConnected}>Create and Continue</Button>
      </ModalFooter>
    </Form>
  );
}

const AddUserModal = ({isOpen, onClose, role}) => {
  // State for the clients profile information. This is the first step of the flow.
  const [createData, setCreateData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  })

  // State for drive connection. We need this when figuring out whether a folder can be created.
  const [isDriveConnected, setIsDriveConnected] = useState(false);

  // State for the new shared folder. When this state is populated, we move to the next step of the flow.
  const [sharedFolderData, setSharedFolderData] = useState({
    rootFolderId: '',
    clientId: '',
    folderName: ''
  })

  // State for the notification window. When this state is populated we move to the final step of the flow.
  const [notifyData, setNotifyData] = useState({
    id: '',
    notifyByEmail: true,
    inviteLink: ''
  })

  // Reset functions.
  const resetCreateData = () => {
    setCreateData({
      firstName: '',
      lastName: '',
      email: ''
    });
  }

  const resetNotifyData = () => {
    setNotifyData({
      id: '',
      notifyByEmail: true,
      inviteLink: ''
    });
  }

  const resetSharedFolderData = () => {
    setSharedFolderData({
      rootFolderId: '',
      clientId: '',
      folderName: ''
    });
  }

  // Skip folder creation.
  const onSkipFolder = () => {
    resetCreateData();
    resetSharedFolderData();
  }

  // Skip the notification.
  const onSkipNotify = () => {
    onCloseModal();
  }

  // Create shared folder.
  const onCreateFolder = async e => {
    e.preventDefault();
    try {
      if (!isDriveConnected) {
        return;
      }
      await driveApi.createFolderForClient(sharedFolderData.folderName, sharedFolderData.clientId);
      toast.success(`Successfully created new shared folder`)

      // Trigger the next step.
      resetCreateData();
      resetSharedFolderData();
    } catch (err) {
      console.log(err);
    }
  }

  // Close the create new client flow.
  const onCloseModal = () => {
    onClose();
    if (notifyData.id !== ''){
      resetCreateData();
      resetNotifyData();
      resetSharedFolderData();
    }
  }

  // Create the new user. Note: This also checks for the drive connectivity and set up for the next step: Creating shared folder.
  const onCreate = async e => {
    e.preventDefault();
    try{
      const res = role == "STAFF" ? await createStaff(createData) : await createClient(createData);
      const id = res.data;
      await generateInviteLink(id, async (data) => {
        if (role == "CLIENT") {
          // Check if there is a drive connected.
          const sessionOrg = SessionService.getOrganization()
          const resp = await get(sessionOrg.id)
          var org = resp.data
          setIsDriveConnected(org.provider != null || org.temp_provider != null)

          // Set default variable for folder name
          var defaultFolderName = `${createData.firstName} ${createData.lastName}`.trim() + " Shared";
          setSharedFolderData({rootFolderId: sessionOrg.folderId, clientId: id, folderName: defaultFolderName});
        }

        setNotifyData({...notifyData, inviteLink: data, id});
      });

      const toastMessage = role == "CLIENT" ? "client" : "staff memeber"
      toast.success(`Successfully created new ${toastMessage}`)
      if (role == "CLIENT") {
        const amplitudeInstance = amplitude.getInstance();
        if (amplitudeInstance){
          amplitudeInstance.logEvent("Add Client");
        }
      }
    } catch (e) {
    }
  };

  // Cancel the flow.
  const onCreateCancel = () => {
    onClose();
    resetCreateData();
  }

  // Send Email. Its the final step of the flow so it also closes the modal.
  const sendEmail = async e => {
    if (notifyData.notifyByEmail)
      await sendInvitationCode(notifyData.id);

    toast.success(`Successfully sent invite`)
    onCloseModal();
  }

  // This is the flow when you create a new user.
  // 1. Get their details and create a record with the backend
  // 2. Create shared folder for the newly added client
  // 3. Notify them via email
  const userFlowModal = () => {
    // Step 1.
    if ((notifyData.id == '') && (sharedFolderData.clientId == '')) {
      return <AddUser
          onClose={onCreateCancel}
          onCreate={onCreate}
          userData={createData}
          setUserData={setCreateData}
          role={role}
        />
    } else if (sharedFolderData.clientId != '') {
      // Step 2.
      return <CreateSharedFolder
        sharedFolderData={sharedFolderData}
        setSharedFolderData={setSharedFolderData}
        onSkip={onSkipFolder}
        onCreate={onCreateFolder}
        isDriveConnected={isDriveConnected}
      />
    } else {
      // Step 3.
        return <NotifyUser
          sendEmail={sendEmail}
          userData={notifyData}
          onSkip={onSkipNotify}
          setUserData={setNotifyData}
        />
    }
  }

  // Putting it all together.
  return (
    <Modal size="md" isOpen={isOpen} toggle={onCloseModal}>
      {userFlowModal()}
    </Modal>
  );
};

AddUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  role: PropTypes.string.isRequired
};

export default AddUserModal;
