import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getConversationTasksView } from "../layout/Selectors";
import { UsersIndexedById, getUsersById } from "../users/userModel";
import TaskCard from '../conversationTasks/taskCard';
import { EditTask } from '../conversationTasks/EditTask';
import { DragDropContext } from 'react-beautiful-dnd';
import IconButton from '../../../../components/clienttable/common/IconButton'
import { Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {
  getCurrentConversationId,
  getCurrentConversation
} from "../currentConversation/currentConversationModel";

import is from 'is_js';
import {
  ScrollView,
  Drawer,
} from "../../foundations/components/layout";
import { fetchChannelMembers } from "pubnub-redux";
import {
  conversationTasksViewHidden,
  currentConversationViewHidden,
  composeViewDisplayed,
  currentConversationViewDisplayed,
} from "../layout/LayoutActions";
import { getLoggedInUserId } from "../authentication/authenticationModel";

import SessionService from "../../../../services/SessionService";
import { Icon } from "@iconify/react";
import closeOutlined from "@iconify-icons/ant-design/close-outlined";
import taskAddOutlined from "@iconify-icons/fluent/task-list-square-add-24-regular";
import { deleteTasks, setTasks, getTasks } from '../../../../api/tasks.api';
import {
  Button
} from "reactstrap";
import KanbanColumn from '../../../kanban/KanbanColumn';
import KanbanProvider from '../../../kanban/KanbanProvider';
import { kanbanItemsDefault } from '../../../kanban/Data';
import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import { toast } from "react-toastify";
import { getGrays } from "../../../../helpers/utils";

const ConversationTasks = () => {
  const [showForm, setShowForm] = useState(false);
  const [newTask, setNewTask] = useState("");
  const myUserId = useSelector(getLoggedInUserId);
  const currentConversationId = useSelector(getCurrentConversationId);
  const dispatch = useDispatch();
  const viewConversationTasks = useSelector(getConversationTasksView);
  const conversation = useSelector(getCurrentConversation);
  const { role: myRole } = SessionService.getUser();
  const id = 1;

  const [items, setItems] = useState(kanbanItemsDefault[0]["items"]) 
  const [currTaskIndex, setCurrTaskIndex] = useState(-1);
  const containerRef = useRef(null);

  useEffect(() => {
    refreshView(currentConversationId)
  }, [])

  useEffect(() => {
    saveTasks();
  }, [items])

  const onAdd = async e => {
    e.preventDefault();
    try {
    if (newTask == "") {
      return
    }
    var newAddTask = {title: newTask, id: Date.now(), complete: false }
    var newArray = [newAddTask].concat(items);
    await setItems(newArray);
    await setNewTask("");
    } catch (error) {
      toast.error('Failed to add a task')
    }
  }

  const saveTasks = async () => {
    try {
    if (items.length == 1 && items[0].id == 1) {
      // Initial load before we get from the DB.
      return
    }
    var newTasksStr = JSON.stringify(items);
    const resp = await setTasks(currentConversationId, newTasksStr);
    } catch (error) {
      toast.error('Failed to save tasks')
    }
  }

  const refreshView = async (channelId) => {
    try{

      const result = await getTasks(channelId)
      var tasksForChannel = result.data
      if (tasksForChannel == null) {
        setItems([]);
      } else {
        var parsedTasks = JSON.parse(tasksForChannel.tasks)
        setItems(parsedTasks)
      }
    } catch (error) {
      toast.error('Failed to load tasks')
    }
  }

  const reorderArray = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const handleDragEnd = async result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const reorderedItems = reorderArray(
      items,
      source.index,
      destination.index
    );

    try {
      var newTasksStr = JSON.stringify(reorderedItems);
      const resp = await setTasks(currentConversationId, newTasksStr);
      setItems(reorderedItems);
      } catch (error) {
        toast.error('Failed to reorder tasks')
      }
  };

  return (
    <Drawer
      open={viewConversationTasks}
      edge="right"
      style={{ width: 385, position: "absolute", right: "0", padding: "0" }}
      className="border-left"
      z-index="1"
      wide
    >
      <div className="p-3 border-bottom">
        <div className="d-flex flex-row justify-content-between mb-3">
          <h5 className="d-flex align-items-center mb-0">Tasks</h5>
          <div onClick={() => dispatch(conversationTasksViewHidden())}>
            <Icon icon={closeOutlined} height="25px" width="25px" />
          </div>
        </div>
      {["STAFF", "OWNER"].includes(myRole) &&
        <div className="d-flex flex-row align-items-center" style={{ width: "100%" }}>
          <InputGroup>
              <Input required type='text' pattern='^(?!\s*$).+'
              placeholder={"Add Task..."} value={newTask}
              onChange={(e) => {setNewTask(e.target.value)}}
              onKeyPress={event => event.key === "Enter" && onAdd(event)}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary" size="sm" className="p-0" onClick={onAdd}>
                  <Icon style={{cursor: 'pointer', width: "2.0rem", height: "2.0rem" }} icon={taskAddOutlined} />
                </Button>
              </InputGroupAddon>
          </InputGroup>
          </div>
      }
      </div>

      <ScrollView ref={containerRef}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="kanban-container me-n3 scrollbar" ref={containerRef} style={{minWidth:'99%', height:'66%'}}>
        <div className={classNames('kanban-column', { 'form-added': showForm })} style={{minWidth:'99%', height:'100%'}}>
          <div className="d-flex justify-content-start" style={{height:'5%', backgroundColor: getGrays(false)[100]}}>
            <span className="text-700 ml-3 mt-1 mb-1" style={{color: "primary", fontWeight: "bold"}}>To Do</span>
          </div>
          <Droppable droppableId={`incomplete-${id}`} type="KANBAN" position="absolute">
            {provided => (
              <>
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  id={`container-${id}`}
                  className="kanban-items-container scrollbar"
                  style={{minWidth:'100%', height:'95%'}}
                  position="absolute"
                >
                  {items.map((task, index) => (
                    !task.complete &&
                    <TaskCard key={task.id} index={index} task={task} setCurrTaskIndex={setCurrTaskIndex} items={items} setItems={setItems} />
                  ))}
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
        </div>
        </div>
      </DragDropContext>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="kanban-container me-n3 scrollbar" ref={containerRef} style={{minWidth:'99%', height:'33%'}}>
        <div className={classNames('kanban-column', { 'form-added': showForm })} style={{minWidth:'99%', height:'100%'}}>
          {/* <hr className="border-dashed mx-4 mb-0" /> */}
          <div className="d-flex justify-content-start" style={{backgroundColor: getGrays(false)[100]}}>
            <span className="text-700 ml-3 mt-1 mb-1" style={{color: "#fff", fontWeight: "bold"}}>Completed</span>
          </div>
          <Droppable droppableId={`complete-${id}`} type="KANBAN" position="absolute">
            {provided => (
              <>
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  id={`container-${id}`}
                  className="kanban-items-container scrollbar"
                  style={{minWidth:'100%', height:'90%'}}
                  position="absolute"
                >
                  {items.map((task, index) => (
                    task.complete &&
                    <TaskCard key={task.id} index={index} task={task} setCurrTaskIndex={setCurrTaskIndex} items={items} setItems={setItems} />
                  ))}
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
        </div>
        </div>
      </DragDropContext>
      <EditTask items={items} setItems={setItems} currTaskIndex={currTaskIndex} />
      </ScrollView>
    </Drawer>
  );
};

export { ConversationTasks };
