import { createSignalReducer, Signal } from "pubnub-redux";
import { createSelector } from "reselect";
import { AppActions } from "../../main/AppActions";
import { SignalState } from "pubnub-redux/dist/features/signal/SignalReducer";
import { MessageActionType } from "pubnub-redux";
import { AppState } from "../../main/storeTypes";
import { Message } from "pubnub-redux";

export type LatestMessageEnvelope = Required<
  Pick<Signal, "channel" | "message" | "timetoken" | "publisher">
> & {
  message: object;
};

const defaultState = { byId: {} };

/**
 * This Slice selector is used internally to access the state of the reducer,
 * primarily as the base selector function for creating other selectors.
 */
const getLatestMessageSlice = (
  state: AppState
): SignalState<LatestMessageEnvelope> => state.lastMessage;

/**
 * Returns an index which can be used to find latest Messages
 */
export const getLatestMessagesById = createSelector(
  [getLatestMessageSlice],
  lastMessages => {
    return lastMessages.byId;
  }
);

/**
 * updateLatestMEssage takes in a message and updates the latestMessage state if the message
 *  is sent after the latest current message for the channel.
 * The message object is a simple message object with a text field.
 */
const updateLatestMessage = (
  state: SignalState<LatestMessageEnvelope>,
  channel: string,
  message: object,
  userId: string,
  timetoken?: number
): SignalState<LatestMessageEnvelope> => {
  let newState = {
    byId: { ...state.byId }
  };

  if (newState.byId[channel]) {
    if (newState.byId[channel][0].timetoken < (timetoken ? timetoken : -1)) {
      newState.byId[channel] = [{
          message: message,
          channel: channel,
          timetoken: (timetoken ? timetoken : -1),
          publisher: userId,
        }
      ]
    }
  } else {
    newState.byId[channel] = [{
        message: message,
        channel: channel,
        timetoken: (timetoken ? timetoken : -1),
        publisher: userId,
      }
    ]
  }

  return newState;
};

// LatestMessageActionType is the type of the dispatched action for updating latest messsage.
export enum LatestMessageActionType {
  LATEST_MESSAGE_UPDATED = "last-message-updated"
}

// LatestMessagePayload is the payload interface for the dispatched call when new messages are fetched.
export interface LatestMessagePayload {
  channel: string;
  messages: Message[];
}

// LatesstMessageAction is the action fired by the dispatcher when new messages are fetched that redux needs to learn about.
export interface LatestMessageAction {
  type: typeof LatestMessageActionType.LATEST_MESSAGE_UPDATED;
  payload: LatestMessagePayload;
}

// latestMessage is the helper function that the clients will use to dispatch to reduc that new messages are ready to be processed.
// This is so the latestMessage per channel can be kept upto date.
// This is the entrypoint for client side code.
export const latestMessage = (
  payload: LatestMessagePayload
): LatestMessageAction => ({
  type: LatestMessageActionType.LATEST_MESSAGE_UPDATED,
  payload
});

/**
 * create a reducer which holds all latest message signal objects in a normalized form
 */
export const LastMessageStateReducer = (
  state: SignalState<LatestMessageEnvelope>,
  action: AppActions
): SignalState<LatestMessageEnvelope> => {
  let newState = state ? {
    byId: { ...state.byId }
  }: defaultState;

  switch (action.type) {
    case MessageActionType.MESSAGE_RECEIVED: // So new messages are kept up to date.
      var payload = action.payload;
      var timetoken = payload.timetoken;
      var channel = payload.channel;
      var userId = payload.publisher;

      return updateLatestMessage(state, channel, payload.message, userId, timetoken)
    case MessageActionType.MESSAGE_HISTORY_RETRIEVED: // Just in case newer messages are retrieved. Seems redundant.
      var historyPayload = action.payload;
      if (historyPayload.status.error) {
        return newState || defaultState
      }

      var channel = historyPayload.request.channel;

      for (const historyMessage of historyPayload.response.messages ) {
        var timetoken = Number((historyMessage.timetoken ? historyMessage.timetoken : -1));
        var userId = String(historyMessage.entry.senderId);

        newState = updateLatestMessage(newState, channel, historyMessage.entry, userId, timetoken)
      }

      return newState || defaultState;
    case LatestMessageActionType.LATEST_MESSAGE_UPDATED: // In case specific messages are found outside of pubnub-redux. For example the fetchMessages API call.
      var latestPayload = action.payload
      
      for (const message of latestPayload.messages) {
        newState = updateLatestMessage(newState, latestPayload.channel, message.message, (("senderId" in message.message) ? message.message["senderId"]: ""), message.timetoken)
      }
      return newState || defaultState
    default:
      return state || defaultState;
  }
};
