import apiHandler from './apiHandler';
import SessionService from '../services/SessionService';

const upload = (folderId, data) => {
  return apiHandler.post(`/drive/upload/${folderId}`, data, { headers: {
    'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data'
  }})
}

const rename = (id, name) => {
  return apiHandler.put(`/drive/item/${id}`, { name })
}

const createFolder = (name, folderId) => {
  return apiHandler.put(`/drive/folder`, { name, folderId })
}

const createFolderForClient = (name, clientId) => {
  return apiHandler.put(`/drive/folder/client`, { name, clientId })
}

const remove = (fileId) => {
  return apiHandler.delete(`/drive/${fileId}`)
}

//const getFolder = () => {
//  return apiHandler.get(`/drive`)
//}

const getItemPath = (itemId) => {
  return itemId ? apiHandler.get(`/drive/path?itemId=${itemId}`) : apiHandler.get(`/drive/path`);
}

const getSubFolders = (folderId) => {
  return apiHandler.get(`/drive/folder?folderId=${folderId}`)
}

const preview = (id, name) => {
  return apiHandler.get(`/drive/preview/${id}?name=${name}`)
}

const download = (id) => {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/drive/file/${id}`, {
    method: "GET",
    headers: { 'x-access-token' : SessionService.getAccessToken() }
  })
}

const move = (sourceId, targetId, ids) => {
  return apiHandler.post(`/drive/move/${sourceId}/to/${targetId}`, { ids })
}

const duplicate = (id, targetId, isFolder) => {
  return apiHandler.post(`/drive/copy/${id}/to/${targetId}`, { isFolder })
}

const getProviderInfo = () => {
  return apiHandler.get(`/drive/info`);
}

const getTempProviderInfo = () => {
  return apiHandler.get(`/drive/info/temp`);
}

const authorize = (provider, scope = true, accountType) => {
  return apiHandler.post(`/drive/auth`, { provider, scope, accountType })
}

const reconnect = (provider, scope = true) => {
  return apiHandler.post(`/drive/auth/reconnect`, { provider, scope })
}

const sendAuthorizationErrorEmail = () => {
  return apiHandler.get(`/drive/authorization/error`)
}

const initializeViewSession = (id, body) => {
  return apiHandler.post(`/drive/initializeViewSession/${id}`, body);
}

const updateDefaultFolder = (clientId, folderId) => {
  return apiHandler.put(`/drive/folder/default`, { clientId, folderId })
}

export default {
  upload,
  rename,
  createFolder,
  createFolderForClient,
  remove,
  getItemPath,
  getSubFolders,
  preview,
  download,
  move,
  duplicate,
  getProviderInfo,
  getTempProviderInfo,
  authorize,
  reconnect,
  sendAuthorizationErrorEmail,
  initializeViewSession,
  updateDefaultFolder,
}
