import apiHandler from './apiHandler';

const portalSession = () => {
  return apiHandler.post(`/subscriptions/sessions/portal`);
}

const checkoutSession = (plan, interval, quantity) => {
  return apiHandler.post(`/subscriptions/sessions/checkout`, {plan, interval, quantity});
}

export default {
  portalSession,
  checkoutSession,
}