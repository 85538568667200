import React, { createRef, useEffect, useState } from "react";
import { getEditTaskView } from "../layout/Selectors";
import { useSelector, useDispatch } from "react-redux";
import Icon from "@iconify/react";
import { ScrollView } from "../../foundations/components/layout";
import {
  editTaskViewHidden,
  conversationMembersViewHidden,
  currentConversationViewDisplayed,
  editTaskViewDisplayed,
} from "../layout/LayoutActions";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormGroupInput from "../../../../components/common/FormGroupInput";
import { getCurrentConversationId,
  getCurrentConversation,
  ConversationFragment,
 } from "../currentConversation/currentConversationModel";
import { editChannelViewHidden } from "../layout/LayoutActions";
import { getTasks, setTasks, deleteTasks } from "../../../../api/tasks.api";
import { toast } from "react-toastify";


const EditTask = ({items, setItems, currTaskIndex}) => {
  const viewEditTask: any = useSelector(getEditTaskView);
  const dispatch = useDispatch();
  const currentConversationId: string = useSelector(getCurrentConversationId);
  const conversation: ConversationFragment = useSelector(
    getCurrentConversation
  );
  const [task, setTask] = useState(
      {
        title: "",
        complete: false,
        id: 1
      }
  );

  useEffect(() => {
    if (currTaskIndex >= 0) {
      // It is a valid value

      var newTask = items[currTaskIndex]
      setTask(newTask)
    }
  }, [])

  useEffect(() => {
    if (currTaskIndex >= 0) {
      // It is a valid value

      var newTask = items[currTaskIndex]
      setTask(newTask)
    }
  }, [currTaskIndex])

  const onSubmit = async e => {
    e.preventDefault();
    try {
    var newArray = [...items];
    newArray[currTaskIndex] = task
    var newTasksStr = JSON.stringify(newArray);
    const resp = await setTasks(currentConversationId, newTasksStr);
    setItems(newArray);
    } catch (error) {
      toast.error('Failed to edit a task')
    }

    dispatch(editTaskViewHidden());
  }

  // Reset description when navigating away from the page
  // useEffect(() => {
  //   if (!viewAddTask)
  //     setChannalData({
  //       name: conversation.name,
  //       description: conversation.description,
  //     });
  // }, [viewAddTask])

  return (
    <Modal isOpen={viewEditTask}>
      <Form onSubmit={onSubmit}>
        <ModalHeader>{"Edit Task"}</ModalHeader>
        <ModalBody>
          <FormGroupInput
            id="task-description"
            label="Task description"
            type="textarea"
            maxLength="500"
            className="max-vh-25"
            value={task.title}
            onChange={({ target }) => setTask({ ...task, title: target.value, id: Date.now() })}
          />
          <div className="d-flex justify-content-between">
            {task.title.length}/500 characters
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => { dispatch(editTaskViewHidden()) }}>Cancel</Button>
          <Button color="primary" type="submit" disabled={false}>Save</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export { EditTask };

