import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, Button} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { get, edit, updateCustomFieldsValues } from '../../api/user.api';
import driveApi from "../../api/drive.api";
import { toast } from 'react-toastify';
import ManageAccountCard from '../../components/clienttable/ManageAccountCard';
import LoginAndPasswordCard from '../../components/clienttable/LoginAndPasswordCard';
import AboutCard from '../../components/clienttable/AboutCard';
import ClientContactInformation from '../../components/clienttable/ClientContactInformation';
import DefaultFolderModal from "../../components/clienttable/DefaultFolderModal";
import logger from '../../utils/logger';

const ClientProfile = () => {
  const { id } =  useParams();

  const defaultUserData = {    
    id: id,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    phone: "",
    email: "",
    street: "",
    unitNumber: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    lastActivity: null,
    setupCompletedAt: null,
    group: '',
    customFieldsValues: null,
    defaultFolderId: "",
    defaultFolderName: "",
  }

  const [user, setUser] = useState(defaultUserData)
  const [showDefaultFolderModal, setShowDefaultFolderModal] = useState(false);

  useEffect(() => {
    getUserDetails()
  }, [])

  const getUserDetails = async () => {
    setUser(defaultUserData)
    try {
      const result = await get(id)
      setUser(result.data)
    } catch (error) {
      if (error.response.status == 404) {
        window.location.href = '/404'
      }
    }
  }

  const submitChanges = async e => {
    e.preventDefault();
    try {
      await edit(user)
      await driveApi.updateDefaultFolder(user.id, user.defaultFolderId);
      if (typeof user.customFieldsValues == 'string'){
        await updateCustomFieldsValues(id, JSON.parse(user.customFieldsValues));
      } else {
        await updateCustomFieldsValues(id, user.customFieldsValues);
      }
      
      toast.success(`Successfully edited user`)
    } catch (err) {
      logger.warn(err, `Error while editing user ${user.id}`)
      toast.error(err);
    }
  }

  return (
    <>
      <Breadcrumb style={{paddingLeft: "1rem"}}>
        <BreadcrumbItem>
          <Link to="/clients">Browse Clients</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Client Profile</BreadcrumbItem>
      </Breadcrumb>
      
      <h3 style={{paddingLeft: "1rem"}}>{user.firstName} {user.lastName}</h3> 
      
      <AboutCard
        user={user}
        setUser={setUser}
        toggleDefaultFolderModal={() => setShowDefaultFolderModal((show) => !show)}
      />
      <ClientContactInformation user={user} setUser={setUser}/>
      <LoginAndPasswordCard user={user} setUser={setUser}/>
      <ManageAccountCard user={user}/>
      <DefaultFolderModal
        onSubmit={async ({id, name}) => {
          try {
            const updatedUser = {...user, defaultFolderId: id, defaultFolderName: name}
            await edit(updatedUser)
            await driveApi.updateDefaultFolder(updatedUser.id, updatedUser.defaultFolderId);
            setUser(updatedUser);
            toast.success(`Successfully edited user`)
          } catch (err) {
            logger.warn(err, `Error while editing user ${user.id}`)
            toast.error(err);
          }
        }}
        isOpen={showDefaultFolderModal}
        toggle={() => setShowDefaultFolderModal((show) => !show)}
      />

      <div style={{marginBottom:"20px"}}>
        <Button color={'primary'} size="sm" className="px-4 ml-2" onClick={getUserDetails}>
          Cancel
        </Button>
        <Button color={'primary'} size="sm" className="px-4 ml-2" onClick={submitChanges}>
          Save
        </Button>
      </div>
    </>
  )
}

export default ClientProfile;