import React from "react";

export default [
  {
    title: `What is included in the Professional Plan?`,
    description: <>
      <p>In addition to all our platform features, you unlock:</p>
      <ul>
        <li>The ability to add unlimited clients</li>
        <li>Add and manage staff (based on user seats purchased)</li>
        <li>Remove ClientTable branding</li>
      </ul>
    </>,
  },
  {
    title: `How much storage is included?`,
    description: <>
      <p> ClientTable will link directly to your choice of storage: Google Drive,
        Microsoft OneDrive, or Dropbox. Files added to your portal by any user
        are stored directly in your storage drive. This direct integration
        allows you to maintain your internal document workflow and desktop sync,
        with your preferred storage solution.
      </p>
      <p>ClientTable doesn't provide storage space. You may purchase additional
        storage directly from your provider.
      </p>
      <p>Client users are not made aware of the underlying storage solution.
      </p>
    </>,
  },
  {
    title: `How many staff can I add?`,
    description: <>
      <p> You must purchase a subscription for each staff account. For example, if
        you upgrade to professional and purchase 3 total seats:
      </p>
      <ul>
        <li>1 will be for the business owner (admin account).</li>
        <li>2 staff can then be added with the additional seats.</li>
      </ul>
    </>,
  },
  {
    title: `Can I get a refund?`,
    description: <>
      <p> Yes. If you're not satisfied, we offer a full refund of the most recent
        payment in the prior 30 days. Refunds must be requested within <b>30 days of
        the original charge.</b>
      </p>
    </>,
  },
  {
    title: `Do you provide support?`,
    description: <>
      <p> Yes! You may email
        <a href="mailto:support@clienttable.com" target="_blank"> support@clienttable.com</a> or
        <a href="https://meetings.hubspot.com/ghufran/support-call" target="_blank"> book a Support Meeting</a> (hosted on Zoom for screen sharing needs).
      </p>
      <p> Professional tier subscribers can expect email responses within 24 hours. Free
        tier members can expect email responses within 48 hours.
      </p>
    </>,
  }
];
