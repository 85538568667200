import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { MessageListItem, MessageFragment } from "../MessageListItem";
import { getCurrentConversationId, getCurrentConversation } from "../currentConversationModel";
import { getUsersById } from "../../../features/users/userModel";
import { getMessagesById } from "../../../features/messages/messageModel";
import { fullNameInitials } from "../../../../../utils/util";
import { Avatar, AvatarVariants } from "../../../foundations/components/chat";
import { ScrollView, FlexColumn } from "../../../foundations/components/layout";
import {
  usePagination,
  GetNextPage,
  SavePaginationState
} from "../../../foundations/hooks/usePagination";
import { fetchMessageHistory, fetchChannelMembers } from "pubnub-redux";
import {
  MessageHistoryRetrievedAction,
  HistoryResponseMessage
} from "pubnub-redux/dist/features/message/MessageActions";
import { useDispatch } from "react-redux";
import { getHistoryPaginationStateById } from "../../../features/pagination/Selectors";
import { setHistoryPagination } from "../../../features/pagination/PaginationActions";
import moment from "moment";
import SessionService from "../../../../../services/SessionService";

const MESSAGING_CLUMP_INTERVAL_MAX_SECONDS = 60 * 60;
/**
 * Create a selector that that returns the list of messages in the currentConversation joined
 * to the user that sent that message
 *
 * TODO:
 * This implementation will cause the dependant component to re-render if any user data has changed
 * if the current conversation has changed or if any message has changed or if any user has changed.
 * This needs to be reduced in scope
 *
 * TODO: This needs to sort by time token; object keys are not guarenteed to retain order in js.
 */
export const getCurrentConversationMessages = createSelector(
  [getMessagesById, getCurrentConversationId, getUsersById],
  (messages, conversationId, users): MessageFragment[] => {
    return messages[conversationId]
      ? Object.values(messages[conversationId])
        .filter(message => message.channel === conversationId)
        .map(
          (message): MessageFragment => {
            // if the user is unknown queue up a request for the missing data
            return {
              ...message,
              timetoken: String(message.timetoken),
              sender:
                (users[message.message.senderId] as {
                  id: string;
                  name: string;
                }) ||
                (users[message.message.senderId]
                  ? {
                    id: message.message.senderId,
                    name: message.message.senderId
                  }
                  : {
                    id: message.message.senderId,
                    name: "Unknown User"
                  })
            };
          }
        )
        .sort((messageA, messageB) => {
          if (messageA.timetoken === messageB.timetoken) {
            return 0;
          } else if (messageA.timetoken > messageB.timetoken) {
            return 1;
          } else {
            return -1;
          }
        })
      : [];
  }
);

const MessageList = ({chatProfileList}) => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(0);
  type ConversationScrollPositionsType = { [conversationId: string]: number };
  const conversationId: string = useSelector(getCurrentConversationId);
  const conversation = useSelector(getCurrentConversation);
  const readerId = SessionService.getUser().id;
  const messages: MessageFragment[] = useSelector(
    getCurrentConversationMessages
  );

  const [
    conversationsScrollPositions,
    setConversationsScrollPositions
  ] = useState<ConversationScrollPositionsType>({});

  const updateCurrentConversationScrollPosition = (scrollPosition: number) => {
    setConversationsScrollPositions({
      ...conversationsScrollPositions,
      [conversationId]: scrollPosition
    });
  };

  const handleScroll = (e: any) => {
    const scrollPosition = e.target.scrollTop;
    if (scrollPosition !== 0) {
      updateCurrentConversationScrollPosition(scrollPosition);
    }
  };

  const storedPaginationState = useSelector(getHistoryPaginationStateById)[
    conversationId
  ];

  const restorePaginationState = useCallback(() => {
    return storedPaginationState;
  }, [storedPaginationState]);

  const savePaginationState: SavePaginationState<
    string | undefined,
    string
  > = useCallback(
    (channel, pagination, count, pagesRemain) => {
      dispatch(
        setHistoryPagination(channel, { pagination, count, pagesRemain })
      );
    },
    [dispatch]
  );

  const getNextPage: GetNextPage<
    HistoryResponseMessage<unknown>,
    string | undefined,
    string
  > = useCallback(
    async (tt, total, channel) => {
      const pageSize = 100;
      const action = ((await dispatch(
        fetchMessageHistory({
          count: pageSize,
          channel,
          start: tt || undefined,
          includeMeta: true,
          stringifiedTimeToken: true
        })
      )) as unknown) as MessageHistoryRetrievedAction<unknown, unknown>;
      const response = action.payload.response;
      return {
        results: response.messages,
        pagination: `${response.startTimeToken}`,
        pagesRemain: response.messages.length === pageSize
      };
    },
    [dispatch]
  );

  const { containerRef, endRef } = usePagination(
    getNextPage,
    conversationId,
    savePaginationState,
    restorePaginationState
  );

  const restoreConversationScrollPosition = (conversationId: string) => {
    const conversationScrollPosition: number =
      conversationsScrollPositions[conversationId];
    if (containerRef.current) {
      if (conversationScrollPosition) {
        containerRef.current.scrollTo(0, conversationScrollPosition);
      } else {
        // scroll to bottom
        containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
      }
    }
  };

  const memoizedRestoreConversationScrollPositionCallback = useCallback(
    restoreConversationScrollPosition,
    [conversationId]
  );

  const el = containerRef.current;

  // when history is pulled, scroll down to compensate
  const newHeight = el?.scrollHeight;
  useEffect(() => {
    if (height === 0 && newHeight) {
      setHeight(newHeight);
    } else if (newHeight && newHeight !== height) {
      if (el) {
        el.scrollTop += newHeight - height;
      }
      setHeight(newHeight);
    }
  }, [newHeight, height, el]);

  const scrollToBottom = useCallback(() => {
    return el && (el.scrollTop = el.scrollHeight - el.clientHeight);
  }, [el]);

  const hasReachedBottom = el
    ? el.scrollHeight - el.clientHeight === el.scrollTop
    : false;

  useEffect(() => {
    if (hasReachedBottom) {
      scrollToBottom();
    }
  }, [messages.length, hasReachedBottom, scrollToBottom]);

  useEffect(() => {
    memoizedRestoreConversationScrollPositionCallback(conversationId);
  }, [memoizedRestoreConversationScrollPositionCallback, conversationId]);

  useEffect(() => {
    if (!(conversation.custom && conversation.custom.chat) && conversationId !== "") {
      dispatch(
        fetchChannelMembers({
          limit: 100,
          channel: conversationId,
          include: {
            UUIDFields: true,
            customUUIDFields: true,
            totalCount: true
          },
        })
      );
    }
  }, [conversationId])

  let currentDate;
  let previousDate;
  let previousMessage;
  let nextMessage;
  let displayMessageDate = true;
  let displayAvatar = true;
  let displayFooter = true;
  let nextDiffDate = false;
  let nextTooLate = false;
  let prevTooLate = false;

  return (
    <ScrollView ref={containerRef} onScroll={handleScroll}>
      <FlexColumn minHeight="100%" flexGrow={1} paddingBottom="1">
        {/* This moves the list of messages to the bottom, since there's a bug with flex-end scroll */}
        <FlexColumn flex="1 1 auto"></FlexColumn>

        <div ref={endRef} />
        {messages.map((message, index) => {
          currentDate = new Date(parseInt(message.timetoken) / 10000);
          let nextMessageDate = new Date(message.timetoken);

          nextMessage = (index + 1 < messages.length) ? messages[index + 1] : null
          nextMessageDate = nextMessage ? new Date(parseInt(nextMessage.timetoken) / 10000) : nextMessageDate;
          
          // Is the next message from a different date?
          nextDiffDate = nextMessage &&
            (nextMessageDate.getMonth() !== currentDate.getMonth() ||
            nextMessageDate.getDate() !== currentDate.getDate());
          
          // Is the prev message from over an hour ago?
          prevTooLate = (index > 0) &&
            Math.abs(currentDate.getTime() - previousDate.getTime()) > (MESSAGING_CLUMP_INTERVAL_MAX_SECONDS * 1000);

          // Is the next message from over an hour later?
          nextTooLate = nextMessage &&
            Math.abs(nextMessageDate.getTime() - currentDate.getTime()) > (MESSAGING_CLUMP_INTERVAL_MAX_SECONDS * 1000);

          // Sould we display the current date?
          displayMessageDate = index === 0 ||
            previousDate.getMonth() !== currentDate.getMonth() ||
            previousDate.getDate() !== currentDate.getDate();
          
          // Should we show an avatar? Yes - if its the first message, if its a new day, if the prev message is too long ago
          // or if the prev message was from another sender.
          displayAvatar = index === 0 || displayMessageDate || prevTooLate ||
            message.sender.id !== previousMessage.sender.id;

          // Should we show the footer (name and time)? Yes - if its the last message, if the next message is too long after,
          // if the next message comes in a new day, or if the next message is from another sender.
          displayFooter = (index === messages.length - 1) || nextDiffDate || nextTooLate ||
            message.sender.id !== nextMessage.sender.id;
          
          previousDate = currentDate;
          previousMessage = message;

          var chatMember = chatProfileList[message.sender.id.toString()]
          if (chatMember) {
            message.sender.name = `${chatMember.firstName} ${chatMember.lastName}`
          }

          return (
            <>
              {displayMessageDate &&
                <FlexColumn>
                  <hr className="border-dashed mx-4 mb-0" />
                  <div className="d-flex justify-content-center">
                    <span className="text-500">{moment(currentDate).format("MMMM DD").toUpperCase()}</span>
                  </div>
                </FlexColumn>
              }

              <MessageListItem
                readerId={readerId}
                messageFragment={message}
                key={message.timetoken}
                avatar={
                  <Avatar
                    variant={AvatarVariants.ROUND}
                    bg={"#344050"}
                  >
                    <b className="text-light fs--2">{fullNameInitials(message.sender.name)}</b>
                  </Avatar>
                }
                displayAvatar={displayAvatar}
                displayFooter={displayFooter}
              />
            </>
          )
        })}
      </FlexColumn>
    </ScrollView>
  );
};

export { MessageList };
