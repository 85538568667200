import React, { createRef, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { uploadFiles } from './DocumentHelpers';
import DocumentLabel from './DocumentLabel';
import { Spinner, Col, Row } from 'reactstrap';

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => {}}
      ref={input => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);

const selectRow = onSelect => ({
  mode: 'checkbox',
  columnClasses: 'py-2 align-middle',
  clickToSelect: false,
  selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
  selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
  headerColumnStyle: { border: 0, width: '7vh' },
  selectColumnStyle: { border: 0, verticalAlign: 'middle', width: '7vh'},
  onSelect: onSelect,
  onSelectAll: onSelect
});

const DocumentData = ({ 
  setSelected, 
  files = [], 
  columns, 
  selected, 
  rowClick, 
  setRowClick, 
  shouldDisplayMoveCard ,
  isClientInRootFolder,
  folderId,
  documentLabel,
  sortOrder,
  isLoading,
  reloadViewRef,
}) => {
  const toastId = useRef(null);

  const onDrop = async (acceptedFiles) => {
    await uploadFiles(acceptedFiles, folderId, toastId);
    reloadViewRef.current();
  };

  let table = createRef();
  
  const onSelect = () => {
    setImmediate(() => {
      setSelected(table.current.selectionContext.selected);
      setRowClick(false)
    });
  };

  const removePreviousAndSelectNewRow = (rowIndex) => {
    table.current.selectionContext.selected = table.current.selectionContext.selected.filter((item) => item == selected)
    table.current.selectionContext.handleRowSelect(selected, true, rowIndex)
    table.current.selectionContext.selected = [`${selected}`]
    setSelected(table.current.selectionContext.selected);
  }

  useEffect(() => {
    if (!isEmpty(selected)) {      
      const rowIndex = files.findIndex((file) => file.id === selected)
      if (rowClick) {
        removePreviousAndSelectNewRow(rowIndex)
      }
      setRowClick(false)
    } else {
      table.current.selectionContext.selected = []
      setRowClick(false)
    }
  }, [selected, table, table.current])

  const loadingSpinner = () => {
    return (
    <Col>
      <Row className='justify-content-center'>
        <Spinner color='primary' style={{width: '150'}} className='mt-6 mb-10'/>
      </Row>
    </Col>
    )}

  return (
    <Dropzone noClick={true} onDrop={onDrop} disabled={isClientInRootFolder()}>
      {({getRootProps, getInputProps}) => (
      <div className="table-responsive min-vh-50" {...getRootProps()}>
        <input {...getInputProps()} />
        <BootstrapTable
          ref={table}
          bootstrap4
          keyField="id"
          data={files}
          columns={columns}
          selectRow={selectRow(rowClick ? () => { } : onSelect, shouldDisplayMoveCard)}
          bordered={false}
          classes={`${files.length ? "table-hover" : ""} table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap`}
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
          noDataIndication={isLoading ? loadingSpinner() : <DocumentLabel {...documentLabel} className="mt-6 mb-10"/>}
          sort={sortOrder ? sortOrder : {dataField: 'name', order: 'asc'}}
        />
        <div className="mt-3 fs--1"style={{textAlign:"center"}}hidden={!files.length}>{documentLabel.tooltip}</div>
      </div>
      )}
    </Dropzone>
  );
};

DocumentData.propTypes = {
  setShouldDisplayMoveCard: PropTypes.func
};

export default DocumentData;
