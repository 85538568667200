import { dateFormatter, avatarFormatter, sendFormatter, composeNameFormatter } from '../../../../../utils/formatter';

const AddMembersColumns = [
  {
    dataField: 'avatar',
    text: 'Name',
    headerClasses: 'border-0 align-middle td-small',
    classes: 'border-0 py-2 align-middle',
    formatter: avatarFormatter
  },
  {
    dataField: 'name',
    text: '',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: composeNameFormatter,
  },
  {
    dataField: 'group',
    text: 'Organization',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'email',
    text: 'Email',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
];

export default AddMembersColumns;