import React from "react";
import { Link } from "react-router-dom";

/**
 * Reminds the user that they need to setup their storage connection before adding files
 */
const SetupStorageAlert = ({...rest}) => (
  <div className="alert alert-warning" role="alert" {...rest}>
    You must setup a storage drive for your organization. <Link to='/settings/documents/setup'>Go to storage settings</Link>
  </div>
);

export default SetupStorageAlert;