import React, { useEffect, useState } from "react";
import useHover from "../../../foundations/hooks/useHover";
import { Avatar, AvatarVariants } from "../../../foundations/components/chat";
import { FlexColumn, FlexRow } from "../../../foundations/components/layout";
import { ListGroupItem } from "reactstrap";
import teamOutlined from "@iconify/icons-ant-design/team-outlined";
import userDeleteOutlined from "@iconify/icons-ant-design/user-delete-outlined";
import { Icon } from "@iconify/react"
import SessionService from "../../../../../services/SessionService";
import { Message } from "pubnub-redux";
import { directChatMember } from "../MyConversations"
import { fullNameInitials } from "../../../../../utils/util";
import { grays } from "../../../../../helpers/utils";

interface ConversationItemProps {
  selected: boolean;
  name: string;
  isChat: boolean;
  onClick: () => void;
  latestMessage: Message;
  chatProfileList: object;
  lastReadMessageTimetoken: number,
}

const isMessageUnread = (myUserId, latestMessage : Message, lastReadMessageTimetoken : number) => {
  var publisherUUID = ""
  var lastMessageTimetoken = -1

  if ("message" in latestMessage.message) {
    publisherUUID = ("publisher" in latestMessage.message) ? latestMessage.message["publisher"] : "";
    lastMessageTimetoken = ("timetoken" in latestMessage.message) ? latestMessage.message["timetoken"] : -1;
  }

  return (lastMessageTimetoken > lastReadMessageTimetoken) && publisherUUID.toString() !== myUserId.toString();
};

/**
 * Show a single joined conversation
 *
 * Similiar to ConversationDescription but with different style and more functionality
 */
const ConversationItem = ({
  selected,
  name,
  chatProfileList,
  isChat,
  onClick,
  latestMessage,
  lastReadMessageTimetoken,
}: ConversationItemProps) => {
  const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
  const myUserId = SessionService.getUser().id;
  const [conversationName, setConversationName] = useState('');
  const [isUserRemoved, setIsUserRemoved] = useState(false);

  useEffect(() => {
    if (isChat && chatProfileList) {
      const chatMember = directChatMember(myUserId, name, chatProfileList);
      setConversationName(chatMember ? `${chatMember.firstName} ${chatMember.lastName}` : "Removed User");
      setIsUserRemoved(!chatMember);
    }
    else setConversationName(name);
  }, [name, chatProfileList])

  var publisherUUID = "";
  var lastMessageText = "";
  var messageDate = ""

  if ("message" in latestMessage.message) {
    lastMessageText = ("text" in latestMessage.message["message"]) ? latestMessage.message["message"]["text"] : ""
    publisherUUID = ("publisher" in latestMessage.message) ? latestMessage.message["publisher"] : "";
  }

  var chatMember = chatProfileList[publisherUUID.toString()]
  var publisherName = "";
  if (publisherUUID.toString() === myUserId.toString()) {
    publisherName = "You"
  } else {
    publisherName = chatMember ? `${chatMember.firstName} ${chatMember.lastName}` : ""
  }

  // Remove the publisher name in chat if it isn't you.
  if (isChat && (publisherUUID.toString() !== myUserId.toString())) {
    publisherName = ""
  }
  var publisherText = (publisherName !== "") ? publisherName + ": " : "";
  var isUnread = isMessageUnread(myUserId, latestMessage, lastReadMessageTimetoken);
  return (
    <ListGroupItem
      onClick={onClick}
      className={`chat-contact p-3 hover-actions-trigger ${selected? `bg-200` : `bg-white`} w-100`}
      style={{borderColor: grays[200], padding:"0.2rem"}}
      active={selected}
      {...hoverProps}
    >
      <FlexRow
        justifyContent="flex-start"
        mx={0} marginBottom={0} marginTop={0}
        alignItems="center"
      >
        <Avatar
          variant={AvatarVariants.ROUND}
          className={isUserRemoved ? "bg-500" : isChat ? "bg-900" : "bg-primary"}
        >
          {isUserRemoved
            ? <Icon className="text-light w-75 h-75" icon={userDeleteOutlined} />
            : isChat
              ? <b className="text-light fs--2">{fullNameInitials(conversationName)}</b>
              : <Icon className="text-light w-75 h-75" icon={teamOutlined} />
          }
        </Avatar>
        <FlexColumn
          overflow="hidden"
          justifyContent="start"
          className={`ml-2 flex-grow-1 chat-contact-title ${isUnread && "font-weight-bold"}`}
        >
          <FlexRow alignItems="center">
            <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "black" }}>{conversationName}</span>
            <span className="ml-auto text-right text-secondary" style={{ whiteSpace: "nowrap" }}>{messageDate}</span>
          </FlexRow>
          <FlexRow justifyContent="space-between" alignItems="center" >
            <span className={ isUnread ? "" : "text-secondary"} style={{maxHeight: "35px", overflow: "hidden", color: "gray", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
              {publisherText + lastMessageText}
            </span>
            { isUnread && 
              <span className="ml-2" style={{height: "14px", width: "14px", minWidth: "14px", backgroundColor: "#0099ff", borderRadius: "50%", display: "inline-block"}}></span>
            }
          </FlexRow>
        </FlexColumn>
     {/* </div> */}
      </FlexRow>
    </ListGroupItem>
  );
};

ConversationItem.unreadCount = 0;

export { ConversationItem };
