import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Form,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';
import { findChatProfiles } from '../../../api/user.api';
import { Link } from 'react-router-dom';
import Avatar from '../../clienttable/common/Avatar';
import InfoLabel from '../../clienttable/common/InfoLabel';
import UsersSelect from './UsersSelect';
import { formatName } from '../../../helpers/strings';
import { isEmpty } from 'lodash';
import fileAccessApi from '../../../api/file-access.api';
import { toast } from 'react-toastify';
import ShareDropdown from "./ShareDropdown";
import SessionService from "../../../services/SessionService";

import ellipsisOutlined from "@iconify-icons/ant-design/ellipsis-outlined";
import teamOutlined from "@iconify-icons/ant-design/team-outlined";
import { Icon } from "@iconify/react";

const ShareSettingsModal = ({ file, isOpen, onClose }) => {
  // List of clients
  const [userDataMap, setUserDataMap] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [activeTab, setActiveTab] = useState('CLIENT');
  const permissionsFilteredByRole = useMemo(() =>
    permissions.filter((permission) => userDataMap[permission.userId] && userDataMap[permission.userId].role === activeTab)
  , [permissions, userDataMap, activeTab])

  // Mappings of userIds to access grouping (i.e. 'EDIT', 'DELETE')
  const [newAccessMap, setNewAccessMap] = useState({});
  const [existingAccessMap, setExistingAccessMap] = useState({});

  const [notifyEmail, setNotifyEmail] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(-1);
  const [canEdit, setCanEdit] = useState(false);

  const user = SessionService.getUser()

  useEffect(() => {
    onLoad();
  }, [])

  // Refresh modal when it is open
  useEffect(() => {
    if (isOpen) fetchPermissions();
  }, [isOpen])

  // Enable/disable ability to edit
  useEffect(() => {
    const myPermission = permissions.find((permission) => permission.userId === user.id);
    setCanEdit(user && (user.role === 'OWNER' || (user.role === 'STAFF' && myPermission && myPermission.share)));
  }, [user, permissions])

  const onLoad = async () => {
    const { data } = await findChatProfiles();
    setUserDataMap(data || {});
  }

  // Fetch existing permissions for item, and format response
  const fetchPermissions = async () => {
    setIsLoading(true);

    const { data } = await fileAccessApi.getPermissions(file.id, true);
    const unique = new Set();

    // Flatten inherited permissions into one list
    const inheritedPermissions = data.inherited
      // Order by furthest down from the root
      .reverse()
      // Flatten permissions into one array
      .flatMap(folder => folder.permissions.map((permission) => ({
        ...permission,
        folderId: folder.id,
        folderName: folder.name,
      })));

    // Add 'inheritGroup property to each direct permission
    const directPermissions = data.permissions.map((permission) => {
      const foundPermission = inheritedPermissions.find((item) => permission.userId === item.userId);
      if (foundPermission) permission.inheritGroup = foundPermission.group;
      return permission
    }).filter((item) => item !== undefined);

    // Combine both permissions, filter out duplicate userIds
    const processedPermissions = directPermissions.concat(inheritedPermissions)
      .filter((permission) => {
        const isUnique = !unique.has(permission.userId);
        unique.add(permission.userId);
        return isUnique;
      })
    setPermissions(processedPermissions || []);
    setIsLoading(false);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    console.log("Update map: ", existingAccessMap);
    console.log("Create map: ", newAccessMap);

    if (!isEmpty(newAccessMap))
      await fileAccessApi.processPermissionsToItem(file.id, newAccessMap, notifyEmail);

    if (!isEmpty(existingAccessMap))
      await fileAccessApi.processPermissionsToItem(file.id, existingAccessMap);

    toast.success('Updated share settings')
    if (notifyEmail && !isEmpty(newAccessMap))
      toast.success('Notification email sent to added users')
    onReset()
  }

  // Select userId from the dropdown
  const onNewAccess = async (id) => {
    setNewAccessMap((accessMap) => {
      if (!accessMap[id])
        accessMap[id] = 'EDIT';
      return { ...accessMap };
    })
  }

  // Updating access type for existing permission
  const onUpdateExistingAccess = async (id, access) => {
    setExistingAccessMap((accessMap) => {
      accessMap[id] = access;
      return accessMap;
    })
  }

  // Updating access type for new permission
  const onUpdateNewAccess = async (id, access) => {
    setNewAccessMap((accessMap) => {
      if (access === 'REMOVE')
        delete accessMap[id];
      else
        accessMap[id] = access;
      return accessMap;
    })
  }

  const isSubmitDisabled = () => {
    return isEmpty(newAccessMap) && isEmpty(existingAccessMap)
  }

  const addUserIdOptions = () => {
    const permissionsIds = permissions.map(permission => permission.userId);
    return Object.keys(userDataMap).filter(id =>
      userDataMap[id].role === activeTab &&
      !permissionsIds.includes(id) &&
      !newAccessMap[id]
    )
  }

  const onReset = () => {
    setPermissions([]);
    setExistingAccessMap({});
    setNewAccessMap({});
    setNotifyEmail(false);
    setActiveDropdown(-1);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} toggle={onReset}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader> Share Settings: {(file && file.name) || ''}</ModalHeader>
        {isLoading ? <div className="d-flex justify-content-center my-5" ><Spinner color="primary" /> </div> :
          <ModalBody>
            <Nav tabs className="mb-3">
              <NavItem>
                <NavLink className={activeTab === 'CLIENT' ? 'active' : ''} onClick={() => { setActiveTab('CLIENT') }}>
                  Client
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={activeTab === 'STAFF' ? 'active' : ''} onClick={() => { setActiveTab('STAFF') }}>
                  Staff
                </NavLink>
              </NavItem>
            </Nav>
            {canEdit && <>
              {isEmpty(addUserIdOptions()) && isEmpty(newAccessMap) ?
                <div className="d-flex flex-column align-items-center text-center">
                  <Icon icon={teamOutlined} color="#74A8ED" width="90" />
                  {isEmpty(permissionsFilteredByRole) ?
                    <b>No {activeTab === 'STAFF' ? 'staff' : 'clients'} have been added</b> :
                    <b>All {activeTab === 'STAFF' ? 'staff' : 'clients'} have already been added</b>
                  }
                </div> :
                <>
                  <h6>Add {activeTab === 'STAFF' ? 'staff' : 'clients'}</h6>
                  <UsersSelect
                    className="border"
                    placeholder={`Select ${activeTab === 'STAFF' ? 'Staff' : 'Client'}...`}
                    dataMap={userDataMap}
                    options={Object.keys(userDataMap).map((id) => parseInt(id))}
                    filterOption={({ value }) => {
                      // Fixes selection menu overlapping checkbox
                      const input = document.getElementById("customShouldSendEmailNotification")
                      if (input) input.parentElement.className = 'custom-checkbox checkbox-input-notification'
                      const permissionIds = permissions.map(permission => permission.userId);
                      return (
                        userDataMap[value] &&
                        userDataMap[value].role === activeTab &&
                        !permissionIds.includes(value) &&
                        !newAccessMap[value]
                      );
                    }}
                    onChange={data => onNewAccess(data.value)}
                    closeMenuOnSelect={true}
                    controlShouldRenderValue={false}
                    searchable
                  />
                  <CustomInput
                    id="customShouldSendEmailNotification"
                    onChange={({ target }) => setNotifyEmail(target.checked)}
                    label="Send email notification"
                    type="checkbox"
                    disabled={isEmpty(newAccessMap)}
                  />
                  {!isEmpty(newAccessMap) &&
                    <ListGroup className="mt-3" >
                      {Object.keys(newAccessMap).map((userId) => {
                        if (!userDataMap[userId] || userDataMap[userId].role !== activeTab) return null;
                        const user = userDataMap[userId];
                        return (
                          <ListGroupItem
                            key={`create-map-${userId}`}
                            style={{ position: "relative" }}
                            className="d-flex align-items-center"
                          >
                            <Avatar
                              className="mr-2"
                              size="xl"
                              round="circle"
                              name={formatName(user.firstName, user.lastName)}
                            />
                            <div className={`d-flex flex-column`}>
                              <Link to={`${user.role === "STAFF" ? "/staff/" : "/client/"}${userId}`} >
                                <span className={`font-weight-semi-bold`}>
                                  {formatName(user.firstName, user.lastName)}
                                </span>
                              </Link>
                              <span className={`fs--1`}>{user.email}</span>
                            </div>
                            <ShareDropdown
                              className="ml-auto"
                              style={{ position: "absolulte", overflow: "visible" }}
                              userId={userId}
                              selected={newAccessMap[userId]}
                              canRemove={true}
                              canAdmin={activeTab === 'STAFF'}
                              disabled={false}
                              isOpen={activeDropdown === userId}
                              toggle={() => setActiveDropdown(activeDropdown === userId ? -1 : userId)}
                              onClick={onUpdateNewAccess}
                            />
                          </ListGroupItem>
                        )
                      })}
                    </ListGroup>
                  }
                </>
              }
            </>
            }
            <div className="mt-3">
              <h6>Currently shared with</h6>
              {isEmpty(permissionsFilteredByRole) ?
                <div className="d-flex flex-column align-items-center text-center">
                  <Icon icon={ellipsisOutlined} color="#74A8ED" width="90" />
                  <b>Not shared with {activeTab === 'STAFF' ? 'staff' : 'clients'}</b>
                </div> :
                <ListGroup>
                  {permissionsFilteredByRole.map((permission) => {
                    if (!userDataMap[permission.userId]) return null;
                    const user = userDataMap[permission.userId];
                    const userId = permission.userId;
                    return (
                      <ListGroupItem
                        key={permission.id}
                        style={{ position: "relative" }}
                        className="d-flex align-items-center"
                      >
                        <Avatar
                          className="mr-2"
                          size="xl"
                          round="circle"
                          name={formatName(user.firstName, user.lastName)}
                        />
                        <div className={`d-flex flex-column ${existingAccessMap[userId] === 'REMOVE' && "text-danger"}`}>
                          <Link to={`${user.role === "STAFF" ? "/staff/" : "/client/"}${userId}`} >
                            <span className={`font-weight-semi-bold ${existingAccessMap[userId] === 'REMOVE' && 'text-danger'}`}>
                              {formatName(user.firstName, user.lastName)}
                            </span>
                          </Link>
                          <span className="fs--1"> {user.email} </span>
                          {permission.folderName &&
                            <em className="fs--1">(Inherited from: {permission.folderName})</em>
                          }
                        </div>
                        <ShareDropdown
                          className="ml-auto"
                          style={{ position: "absolulte", overflow: "visible" }}
                          userId={userId}
                          selected={existingAccessMap[userId] || permission.group}
                          inheritGroup={permission.inheritGroup}
                          canRemove={!permission.folderId}
                          canAdmin={activeTab === 'STAFF'}
                          disabled={!canEdit}
                          isOpen={activeDropdown === userId}
                          toggle={() => setActiveDropdown(activeDropdown === userId ? -1 : userId)}
                          onClick={onUpdateExistingAccess}
                        />
                      </ListGroupItem>
                    )
                  })
                  }
                </ListGroup>
              }
            </div>
            <InfoLabel className="mt-3" text="Share settings are applied to all sub-folders and items" />
            <div className="d-flex flex-row justify-content-end">
              {canEdit ?
                <>
                  <Button outline color="primary" className="mr-2" onClick={() => onReset()}>
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={isSubmitDisabled()}>
                    Save
                  </Button>
                </> :
                <Button outline color="primary" onClick={() => onReset()}>
                  Close
                </Button>
              }
            </div>
          </ModalBody>
        }
      </Form >
    </Modal>
  );
}

ShareSettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  file: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  parent: PropTypes.object,
};

export default ShareSettingsModal;
