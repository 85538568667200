import React, { useState, useRef, Fragment } from 'react'
import folderAddOutlined from "@iconify/icons-ant-design/folder-add-outlined";
import cloudDownloadOutlined from "@iconify/icons-ant-design/cloud-download-outlined";
import ellipsisOutlined from "@iconify/icons-ant-design/ellipsis-outlined";
import userOutlined from "@iconify/icons-ant-design/user-outlined";
import eyeOutlined from "@iconify/icons-ant-design/eye-outlined";
import { Item } from "./DocumentHelpers";
import { ButtonGroup } from 'reactstrap';
import ButtonIcon from "../../components/common/ButtonIcon";
import outlineDriveFolderUpload from '@iconify-icons/ic/outline-drive-folder-upload';
import outlineUploadFile from '@iconify-icons/ic/outline-upload-file';

import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Button,
  Form,
  Row
} from "reactstrap";

import { uploadFiles, downloadFiles } from './DocumentHelpers';
import FormGroupInput from '../../components/common/FormGroupInput';

/*
  File contains differenct actions that can take place on the documents page
*/

const NewFolderButton = ({ create, isClientInRootFolder }) => {
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");

  const createFolder = (e) => {
    e.preventDefault();
    create(name);
    setOpenModal(!openModal);
    setName("");
  };

  return (
    <>
      <Item
        itemName='New Folder'
        disabled={isClientInRootFolder()}
        icon={folderAddOutlined}
        onClick={() => (isClientInRootFolder() ? null : setOpenModal(!openModal))}
      />
      <Modal isOpen={openModal} toggle={() => setOpenModal(!openModal)}>
        <Form>
          <ModalHeader>New Folder</ModalHeader>
          <ModalBody>
            <FormGroupInput
              id="new-folder"
              value={name}
              onChange={({ target }) => setName(target.value)}
              type='text'
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color='primary' onClick={createFolder}>Add Folder</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const UploadButton = ({ isClientInRootFolder, folderId, reloadViewRef}) => {
  const refFileInput = useRef(null);
  const refFolderInput = useRef(null);
  const toastId = useRef(null);

  const handleChangeFile = async (e) => {
    for (let file of e.target.files){
      /* If files uploaded from folder upload button */
      if (file.webkitRelativePath) {
        file.path = '/' + file.webkitRelativePath;
      } else {
        file.path = file.name;
      }
    }
    await uploadFiles(e.target.files, folderId, toastId);
    reloadViewRef.current();
  };
  
  return (
    <>
      {/* File upload */}
      <input
        ref={refFileInput}
        style={{ display: "none" }}
        type='file'
        onChange={handleChangeFile}
        onClick={e => (e.target.value = null)}
        multiple
      />
      
      {/* Folder upload */}
      <input
        ref={refFolderInput}
        style={{ display: "none" }}
        type='file'
        onChange={handleChangeFile}
        onClick={e => (e.target.value = null)}
        webkitdirectory=""
        directory=""
      />

      <Fragment>
        <ButtonGroup className='mr-1'>
          <ButtonIcon
            style={{ borderRadius: '3.125rem 0 0 3.125rem' }}
            color='falcon-primary'
            transform='shrink-3'
            size='sm'
            onClick={() => refFileInput.current.click()}
            disabled={isClientInRootFolder()}
            icon={outlineUploadFile}
          >
            Upload
          </ButtonIcon>
          <ButtonIcon
            style={{ borderRadius: '0 3.125rem 3.125rem 0' }}
            color='falcon-primary'
            transform='shrink-3'
            size='sm'
            onClick={() => refFolderInput.current.click()}
            disabled={isClientInRootFolder()}
            icon={outlineDriveFolderUpload}
          />
        </ButtonGroup>
      </Fragment> 
    </>
  )
}

const DownloadButton = ({ isDisabledInfo, selected, files}) => {
  const refFileInput = useRef(null);
  const toastId = useRef(null);

  var filesToDownload = [];
  selected.forEach((id) => {
    var fileToDownload = files.find((one) => one.id === id);
    filesToDownload.push(fileToDownload);
  });

  return (
    <Item
        itemName='Download'
        disabled={isDisabledInfo.isDownloadDisabled}
        icon={cloudDownloadOutlined}
        onClick={() => downloadFiles(filesToDownload, toastId)}
    />
  )
}


// To preview pdf, png, jpg files. Code can be extended to other files.
const FilePreviewerButton = ({ aFile, isPreviewDisabled, setFileToPreview }) => {
  return (
    <>
      <Item
        itemName='Preview'
        disabled={isPreviewDisabled}
        icon={eyeOutlined}
        onClick={() => setFileToPreview(aFile)}
      />
    </>
  );
};

const MoreButton = (
  {
    isDisabledInfo,
    selectMovingFiles, 
    setOpenRenameModal, 
    duplicateFile,
    deleteFile,
  }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle tag='span' data-toggle='dropdown' aria-expanded={isOpen}>
          <Item
            itemName='More'
            noline={true}
            disabled={false}
            icon={ellipsisOutlined}
            onClick={() => setIsOpen(!isOpen)}
          />
        </DropdownToggle>
        <DropdownMenu className='top-1-negative'>
          <DropdownItem disabled={isDisabledInfo.isMoveDisabled} onClick={() => selectMovingFiles()}>
            Move
          </DropdownItem>
          <DropdownItem disabled={isDisabledInfo.isRenameDisabled} onClick={() => setOpenRenameModal(true)}>
            Rename
          </DropdownItem>
          <DropdownItem disabled={isDisabledInfo.isDuplicateDisabled} onClick={duplicateFile}>
            Duplicate
          </DropdownItem>
          <DropdownItem
            disabled={isDisabledInfo.isDeleteDisabled}
            onClick={deleteFile}
            className={!isDisabledInfo.isDeleteDisabled ? "text-danger" : ""}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

// todo move this into a seperate component or move helper functions into their own function
const ActionButtons = (
    {
      create, 
      isClientInRootFolder, 
      file, 
      setFile,
      folderId, 
      reloadViewRef,
      isDisabledInfo,
      selectMovingFiles,
      setOpenRenameModal,
      duplicateFile,
      deleteFile,
      setOpenShareModal,
      setFileToPreview,
      selected,
      files
    }
  ) => {
  return <>
    <Row noGutters className='px-1 py-3'>
      <NewFolderButton
        create={create}
        isClientInRootFolder={isClientInRootFolder}
      /> 
      <UploadButton
        isClientInRootFolder={isClientInRootFolder}
        reloadViewRef={reloadViewRef}
        folderId={folderId}
      />
      <FilePreviewerButton
        aFile={file}
        isPreviewDisabled={isDisabledInfo.isPreviewDisabled}
        setFileToPreview={setFileToPreview}
      />
      <DownloadButton
        isDisabledInfo={isDisabledInfo}
        selected={selected}
        files={files}
      />
      <Item
        itemName='Share'
        disabled={isDisabledInfo.isShareDisabled}
        icon={userOutlined}
        onClick={() => {
          if (selected.length === 1) {
            const selectedId = selected[0];
            const file = files.find((one) => one.id == selectedId);
            setFile(file);
          }
          setOpenShareModal(true);
        }}
      />
      <MoreButton 
        isDisabledInfo={isDisabledInfo}
        selectMovingFiles={selectMovingFiles}
        setOpenRenameModal={setOpenRenameModal} 
        duplicateFile={duplicateFile}
        deleteFile={deleteFile}
      />
    </Row>
  </>
}

export default ActionButtons;
