import { createSelector } from "reselect";
import { AppState } from "../../main/storeTypes";
import { View } from "./LayoutReducer";

const getLayoutSlice = (state: AppState) => state.layout;

export const getView = createSelector(
  [getLayoutSlice],
  (app: ReturnType<typeof getLayoutSlice>) => {
    return app.views[0];
  }
);

export const getViews = createSelector(
  [getLayoutSlice],
  (app: ReturnType<typeof getLayoutSlice>) => {
    return app.views;
  }
);

export const getViewStates = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return {
      Menu: views.includes(View.Menu),
      ConversationMembers: views.includes(View.ConversationMembers),
      JoinConversation: views.includes(View.JoinConversation),
      CurrentConversationHeader: views.includes(View.CurrentConversationHeader),
      CurrentConversation: views.includes(View.CurrentConversation),
      AddMembers: views.includes(View.AddMembers),
      Compose: views.includes(View.Compose),
      NewChannel: views.includes(View.NewChannel),
      EditChannel: views.includes(View.EditChannel),
      LeaveConfirmation: views.includes(View.LeaveConfirmation),
    };
  }
);

export const getMenuView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.Menu)
  }
)

export const getConversationMembersView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.ConversationMembers)
  }
)

export const getConversationTasksView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.ConversationTasks)
  }
)

export const getJoinConversationView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.JoinConversation)
  }
)

export const getCurrentConversationHeaderView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.CurrentConversationHeader)
  }
)

export const getCurrentConversationView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.CurrentConversation)
  }
)

export const getAddMembersView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.AddMembers)
  }
)

export const getEditTaskView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.EditTask)
  }
)


export const getComposeView= createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.Compose)
  }
)

export const getNewChannelView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.NewChannel)
  }
)

export const getEditChannelView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.EditChannel)
  }
)

export const getLeaveConfirmationView = createSelector(
  [getViews],
  (views: ReturnType<typeof getViews>) => {
    return views.includes(View.LeaveConfirmation)
  }
)