import apiHandler from './apiHandler';

export const refreshPermissions = (uuid, authKey) => {
  return apiHandler.post(`/messages/refreshPermissions`, {uuid, authKey});
};

export const createChannel = (uuid, authKey, channelName, channelDescription="") => {
  return apiHandler.post(`/messages/createChannel`, {uuid, authKey, channelName, channelDescription});
};

export const createChat = (myUuid, otherUuid, authKey) => {
  return apiHandler.post(`/messages/createChat`, {myUuid, otherUuid, authKey});
};

export const createUsers = (uuids) => {
  return apiHandler.post(`/messages/createUsers`, {uuids});
};

export const updateChannel = (channel, newName="", description="") => {
  return apiHandler.put(`/messages/channel`, {channel, newName, description});
}
