import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";

import SelectDocumentsTable from "../../pages/Documents/SelectDocumentsTable";

const DefaultFolderModal = ({ onSubmit, isOpen, toggle }) => {
  const [selected, setSelected] = useState(null);
  const foldersOnly = (file) => file.isFolder;

  const reset = () => {
    setSelected(null);
    toggle();
  }

  const submit = () => {
    // Do something with selected
    onSubmit(selected);
    reset();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>Assign Default Shared Folder</ModalHeader>
      <ModalBody>
        <SelectDocumentsTable
          setSelected={setSelected}
          filterFiles={foldersOnly}
        />
        <div className="d-flex justify-content-end mt-3">
          <Button className="mr-2" outline color="primary" onClick={reset}>Cancel</Button>
          <Button type="submit" color="primary" disabled={!selected} onClick={submit}>Assign Default</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DefaultFolderModal;