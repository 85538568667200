import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import driveApi from '../../api/drive.api';
import FreeSpaceCard from '../../components/clienttable/settings/FreeSpaceCard';
import CompleteSetup from '../../components/clienttable/settings/CompleteSetup';
import ProviderCard from '../../components/clienttable/settings/ProviderCard';
import ConfirmSetupCard from '../../components/clienttable/settings/ConfirmSetupCard';
import SessionService from "../../services/SessionService";
import { Icon } from '@iconify/react';
import databaseOutlined from '@iconify/icons-ant-design/database-outlined';
import { Card } from 'reactstrap';
import { getProvider } from './Providers';
import { get, deleteTempProvider, saveTempProvider } from '../../api/organization.api';
import { toast } from 'react-toastify';
import amplitude from 'amplitude-js';

const SetupDrive = ({ setInChangeConnectionFlow, organization }) => {
  const [freeSpace, setFreeSpace] = useState(0);
  const [email, setEmail] = useState('');
  const temp_provider = getProvider(organization.temp_scope)

  useEffect(() => {
    if (temp_provider) loadInfo();
  }, [temp_provider])

  const refreshOrganizations = async () => {
    const res = await get(organization.id);
    SessionService.setOrganization(res.data);
  }

  const loadInfo = async () => {
    try {
      const result = await driveApi.getTempProviderInfo();
      if (result.data.freeSpace && result.data.email){
        setFreeSpace(result.data.freeSpace);
        setEmail(result.data.email);
      }
    } catch(error){
      toast.error('Error retrieving provider info');
    }
  }

  const onConfirm = async (e) => {
    e.preventDefault()
    // Override current provider information with temporary provider information
    await saveTempProvider();
    await refreshOrganizations();
    const amplitudeInstance = amplitude.getInstance();
    if (amplitudeInstance){
      amplitudeInstance.logEvent("Drive connected")
    }
    setInChangeConnectionFlow(false);
    window.location.reload()
  }

  const onCancel = async (e) => {
    e.preventDefault();
    // Clear temporary provider information
    await deleteTempProvider();
    setInChangeConnectionFlow(false)
    window.location.reload()
  }

  return (
    <>
      <div className="card-deck">
        <ProviderCard
          organization={organization}
        />
        {organization.temp_provider ?
          <FreeSpaceCard
            provider={temp_provider}
            freeSpace={freeSpace}
            email={email}
            isDisconnected={false}
          /> :
          <AwaitingConnectionCard />
        }
      </div>
      <div>
        <div className="card-deck"><ConfirmSetupCard /></div>
        <CompleteSetup organization={organization} cancel={onCancel} confirm={onConfirm} />
      </div>
    </>
  )
}

const AwaitingConnectionCard = () => {
  return (
    <Card className="p-3 m-2 bg-secondary">
      <div className="d-flex flex-center">
        <Icon icon={databaseOutlined} className="text-light fs-9" />
        <h3 className="text-light">Awaiting connection </h3>
      </div>
    </Card>
  )
}

SetupDrive.propTypes = {
  setInChangeConnectionFlow: PropTypes.func,
  organization: PropTypes.object,
};

export default SetupDrive