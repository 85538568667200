import React from "react";
import { ModalHeader, ModalBody, ModalFooter, Modal, Button } from "reactstrap";

const DarkModalDialogBox = ({ children, modalText, isOpen, isOpenFunction }) => {
  const fpStyles = {
    modalDialogStyle: {
      backgroundColor: "#000000",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <>
      <Modal isOpen={isOpen} size='sm' centered={true} toggle={() => isOpenFunction(!isOpen)}>
        <ModalBody style={fpStyles.modalDialogStyle}>
          <p style={{ color: "#ffffff", textAlign: "center" }}>{modalText}</p>

          {children}
        </ModalBody>
      </Modal>
    </>
  );
};

export default DarkModalDialogBox;
