import React, { useState, useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom';
import { verifyEmail } from '../../api/user.api';
import { toast } from 'react-toastify';
import logger from '../../utils/logger';

const VerifyEmailScreen = ({setBanner}) => {
  const {code} = useParams();

  const [loading, setLoading] = useState(true);
  const verify = async() => {
    try {
      await verifyEmail({code})
      toast.success("Verified email successfully")
      setLoading(false)
    } catch (err) {
      logger.warn(err, 'Error in VerifyEmailScreen component for call verifyEmail')
      toast.error("Email was not verified")
    }
  };

  if (loading) {
    verify()
    return <p>Loading</p>
  }

  return <Redirect to={"/"} />

}

export default VerifyEmailScreen;