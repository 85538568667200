import React, {
  useState,
  useEffect
} from "react";
import {
  Button,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import QuantityController from "../../components/clienttable/form/QuantityController";

const PurchasePlanModal = ({
    monthlyPrice,
    annualPrice,
    staffQuantity,
    setStaffQuantity,
    planInterval,
    setPlanInterval,
    isOpen,
    setIsOpen,
    onSubmit,
    onReset
  }) => (
  <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
    <Form onSubmit={onSubmit} onReset={onReset}>
      <ModalHeader>Upgrade to Professional</ModalHeader>
      <ModalBody>
        <FormGroup onChange={(event) => setPlanInterval(event.target.value)}>
          <h5>Select Plan</h5>
          <CustomInput
            type="radio"
            id="plan-monthly"
            name="interval"
            label="Monthly"
            value="monthly"
            defaultChecked
          />
          <CustomInput
            type="radio"
            id="plan-annual"
            name="interval"
            label="Annual (get 1 month free)"
            value="annual"
          />
        </FormGroup>
        <h5>Users</h5>
        <Container>
          <Row className="mb-3">
            <Col xs={8} className="pl-0">Account Owner (required):</Col>
            <Col xs={4}>
              <QuantityController
                value={1}
                disabled={true} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={8} className="pl-0">Additional Staff:</Col>
            <Col xs={4}>
              <QuantityController
                value={staffQuantity}
                setValue={setStaffQuantity}
                minValue={0}
                maxValue={14}
                disabled={false}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={8} className="pl-0"><b>Total Internal Users:</b></Col>
            <Col xs={4}>
              <QuantityController
                value={staffQuantity + 1}
                disabled={true} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={8} className="pl-0"><b>Price Per User ({planInterval})</b></Col>
            <Col xs={4} >
              <div className="d-flex justify-content-center">
                {planInterval == 'annual' ?
                  `$${annualPrice} USD` :
                  `$${monthlyPrice} USD`}
              </div>
            </Col>
          </Row>
        </Container>
        <hr className="border-dashed mb-5" />
        <Container>
          <Row>
            <Col><h4>Total ({planInterval})</h4></Col>
            <Col>
              <Row>
                <div className="ml-auto">
                  {planInterval == 'annual' ?
                    `$${annualPrice} USD x ${staffQuantity + 1} users x 1 year` :
                    `$${monthlyPrice} USD x ${staffQuantity + 1} users x 1 month`
                  }
                </div>
              </Row>
              <Row>
                <h5 className="ml-auto">
                  {planInterval == 'annual' ?
                    `$${annualPrice * (staffQuantity + 1)} USD` :
                    `$${monthlyPrice * (staffQuantity + 1)} USD`
                  }
                </h5>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="ml-auto" >
              + Sales Tax (if applicable) at checkout
            </div>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} type="reset">Cancel</Button>
        <Button color={'primary'} type="submit">Checkout</Button>
      </ModalFooter>
    </Form>
  </Modal>
)

export default PurchasePlanModal;