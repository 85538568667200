import SessionService from './services/SessionService';

export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: 'homeOutlined',
};

export const messagesRoutes = {
  name: 'Messages',
  to: '/messages',
  exact: true,
  icon: 'commentOutlined',
};

export const documentRoutes = {
  name: 'Documents',
  to: '/documents',
  exact: true,
  icon: 'folderOutlined',
};

export const clientRoutes = {
  name: 'Clients',
  to: '/clients',
  exact: true,
  icon: 'contactsOutlined'
};

export const settingsRoutes = {
  name: 'Settings',
  to: '/settings',
  icon: 'settingOutlined'
};

export const profileRoutes = {
  name: 'My Profile',
  to: '/settings/profile',
  icon: 'userOutlined'
};

const getRoutes = () => {
  const user = SessionService.getUser()
  let routes = {}
  switch (user.role) {
    case 'OWNER':
      routes.top = [
        homeRoutes,
        documentRoutes,
        messagesRoutes,
        clientRoutes,
      ]
      routes.bottom = [
        profileRoutes,
        // settingsRoutes
      ]
      break;
    case 'STAFF':
      routes.top = [
        // homeRoutes,
        documentRoutes,
        messagesRoutes,
        clientRoutes,
      ]
      routes.bottom = [
        profileRoutes
      ]
      break;
    case 'CLIENT':
      routes.top = [
        // homeRoutes,
        documentRoutes,
        messagesRoutes,
      ]
      routes.bottom = [
        profileRoutes
      ]
      break;
    default:
      return routes
  }
  return routes
}

export default getRoutes;
