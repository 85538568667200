import React, { useState }from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  CardFooter,
  Collapse,
  Tooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from './FalconCardHeader';
import CustomFields from './CustomFields';
import SessionService from '../../services/SessionService';
import { Icon } from '@iconify/react';
import infoCircleOutlined from '@iconify/icons-ant-design/info-circle-outlined';
import outlineFolderShared from "@iconify/icons-ic/outline-folder-shared";

const ContactInformationCard = ({user, setUser, toggleDefaultFolderModal }) => {

  const [collapsed, setCollapsed] = useState(!SessionService.getCollapsePreference().about);

  const [showDefaultSharedTootlip, setShowDefaultSharedTooltip] = useState(false);

  const onEntered = () => {
    setPreference(true);
  }

  const onExited = () => {
    setPreference(false);
  }

  const setPreference = (pref) => {
    const cur = SessionService.getCollapsePreference();
    cur.about = pref;
    SessionService.setCollapsePreference(cur);
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title="About" light={false} />
      <CardBody className="bg-light">
        <Form>
          <Row>
            <Col md>
              <FormGroup>
                <Label for="exampleName">First Name*</Label>
                <Input 
                  type="text" 
                  name="name" 
                  id="exampleName" 
                  value={user.firstName} 
                  onChange={({ target }) => setUser({...user, "firstName" :target.value})}
                  required
                  minLength="1"
                  maxLength="20"
                />
                <FormText>Make sure this field is not empty.</FormText>
              </FormGroup>
            </Col>
            <Col md>
              <FormGroup>
                <Label for="exampleName">Last Name</Label>
                <Input 
                  type="text" 
                  name="name" 
                  id="exampleName" 
                  value={user.lastName} 
                  onChange={({ target }) => setUser({...user, "lastName" :target.value})}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md>
              <FormGroup>
                  <Label for="exampleName">Organization</Label>
                  <Input type="text" name="name" id="exampleName"  value={user.group} onChange={({ target }) => setUser({...user, "group": target.value})}/>
                </FormGroup>
            </Col>
            <Col md>
            </Col>
          </Row>
        </Form>

        <hr className="border-dashed border-bottom-0" />

        {/* Default shared folder section */}
        <Form>
          <div className="d-flex align-items-center">
            <p className="font-weight-bold mb-0 mr-2">Default shared folder</p>
          </div>
          {
            user.defaultFolderName ? (
              <Link to={`/documents?folderId=${user.defaultFolderId}`}>
                <div className="d-flex align-items-center my-2">
                  <Icon
                    icon={outlineFolderShared}
                    color="#2C7BE5"
                    width="35"
                    className="mr-2"
                  />
                  {user.defaultFolderName}
                </div>
              </Link>
            ) : (
              <div className="d-flex align-items-center my-2">
                <Icon
                  icon={outlineFolderShared}
                  color="#2C7BE5"
                  width="35"
                  className="mr-2"
                />
                {user.defaultFolderName || "No default folder"}
              </div>
            )
          }
          <a className="fs--1" onClick={toggleDefaultFolderModal}>Assign new default shared folder</a>
        </Form>




        <hr className="border-dashed border-bottom-0" />

        {/* Custom fields section */}
        <Form>
            <p><b>Custom Fields</b></p>
            <Collapse isOpen={!collapsed} onEntered={onEntered} onExited={onExited}>
              <CustomFields user={user} setUser={setUser} />
            </Collapse>
        </Form>

      </CardBody>

      {/* Show more/less button */}
      <CardFooter className="bg-light p-0 border-top">
        <Button color="link" block onClick={() => setCollapsed(!collapsed)}>
          Show {collapsed ? 'more' : 'less'}
          <FontAwesomeIcon icon="chevron-up" className="ml-1 fs--2" transform={collapsed ? 'rotate-180' : ''} />
        </Button>
      </CardFooter>

    </Card>
  )
}

ContactInformationCard.protoTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired
}

export default ContactInformationCard;
