import { createContext } from 'react';
import { settings}  from '../config';

const AppContext = createContext(settings);

export const ClientsContext = createContext({ clients: [] });

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});

export default AppContext;
