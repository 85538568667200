import React from 'react';
import PropTypes from 'prop-types'
import { Card, CardBody, Form, Row, Col, FormGroup, Label, Input, CustomInput, FormText, CardFooter, Button } from 'reactstrap';
import useFakeFetch from '../../hooks/useFakeFetch';
import rawTimezones from '../../data/event/timezones';
import { toast } from 'react-toastify';
import FalconCardHeader from './FalconCardHeader';

const OrganizationInformationCard = ({ organization, setOrganization, getUserDetails }) => {
  const { loading: loadingTimezones, data } = useFakeFetch(rawTimezones);
  const timezones = data.map(item => ({ value: item.offset, label: `${item.offset} ${item.name}` }));
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Contact Information" light={false} />
      <CardBody className="bg-light">
        <Row>
          <Col md>
            <FormGroup>
              <Label for="orgName">Organization Name*</Label>
              <Input
                type="text"
                name="name"
                id="orgName"
                value={organization.name}
                onChange={({ target }) => setOrganization({ ...organization, "name": target.value })}
                required
                minLength="1"
              />
              <FormText>Make sure this field is not empty.</FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormGroup>
              <Label for="phone">Phone number</Label>
              <Input type="text" name="phone" id="phone" value={organization.phone} onChange={({ target }) => setOrganization({ ...organization, "phone": target.value })} />
            </FormGroup>
          </Col>
          <Col md>
            <FormGroup>
              <Label for="email">Email Contact</Label>
              <Input type="text" name="email" id="email" value={organization.email} onChange={({ target }) => setOrganization({ ...organization, "email": target.value })} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormGroup>
              <Label for="street">Street</Label>
              <Input type="text" name="street" id="street" value={organization.street} onChange={({ target }) => setOrganization({ ...organization, "street": target.value })} />
            </FormGroup>
          </Col>
          <Col md>
            <FormGroup>
              <Label for="unitNumber">Appartment, suite, etc</Label>
              <Input type="text" name="unitNumber" id="unitNumber" value={organization.unitNumber} onChange={({ target }) => setOrganization({ ...organization, "unitNumber": target.value })} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormGroup>
              <Label for="city">City</Label>
              <Input type="text" name="city" id="city" value={organization.city} onChange={({ target }) => setOrganization({ ...organization, "city": target.value })} />
            </FormGroup>
          </Col>
          <Col md>
            <FormGroup>
              <Label for="state">Province/ State</Label>
              <Input type="text" name="state" id="state" value={organization.state} onChange={({ target }) => setOrganization({ ...organization, "state": target.value })} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input type="text" name="country" id="country" value={organization.country} onChange={({ target }) => setOrganization({ ...organization, "country": target.value })} />
            </FormGroup>
          </Col>
          <Col md>
            <FormGroup>
              <Label for="postalCode">Postal/ ZIP code</Label>
              <Input type="text" name="postalCode" id="postalCode" value={organization.postalCode} onChange={({ target }) => setOrganization({ ...organization, "postalCode": target.value })} />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardFooter justify="between" className="border-top border-200">
        <div>
          <Button color={'secondary'} size="sm" className="px-4 ml-2" onClick={getUserDetails}>
            Cancel
          </Button>
          <Button color={'primary'} type="submit" size="sm" className="px-4 ml-2">
            Save
          </Button>
        </div>
      </CardFooter>
    </Card>
  )
}

OrganizationInformationCard.protoTypes = {
  organization: PropTypes.object.isRequired,
  setOrganization: PropTypes.func.isRequired
}

export default OrganizationInformationCard;
