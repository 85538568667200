import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getConversationMembersView } from "../../../features/layout/Selectors";
import { UsersIndexedById, getUsersById } from "../../../features/users/userModel";
import {
  getUsersByConversationId,
  MembershipHash
} from "../conversationMemberModel";
import { MemberDescription, UserFragment } from "../MemberDescription";
import {
  getCurrentConversationId,
  getCurrentConversation
} from "../../../features/currentConversation/currentConversationModel";

import {
  ScrollView,
  Drawer,
} from "../../../foundations/components/layout";
import { fetchChannelMembers } from "pubnub-redux";
import {
  addMembersViewDisplayed,
  conversationMembersViewHidden,
  leaveConfirmationViewDisplayed,
  currentConversationViewHidden,
  composeViewDisplayed,
} from "../../../features/layout/LayoutActions";
import { getLoggedInUserId } from "../../../features/authentication/authenticationModel";
import {
  usePagination,
  GetNextPage,
  SavePaginationState
} from "../../../foundations/hooks/usePagination";
import { ChannelMembersRetrievedAction } from "pubnub-redux/dist/features/members/ChannelMembersActions";
import { UUIDMembershipObject } from "pubnub";
import { getChannelMembersPaginationStateById } from "../../../features/pagination/Selectors";
import { setChannelMembersPagination } from "../../../features/pagination/PaginationActions";
import { ThunkAction } from "../../../main/storeTypes";
import { setChannelMembers, removeChannelMembers } from "pubnub-redux";
import { createUsers } from "../../../../../api/messages";

import SessionService from "../../../../../services/SessionService";
import { Icon } from "@iconify/react";
import closeOutlined from "@iconify-icons/ant-design/close-outlined";
import usergroupAddOutlined from "@iconify-icons/ant-design/usergroup-add-outlined";
import {
  Button
} from "reactstrap";

/**
 * Adds to a conversation.
 * The membership in the conversation will be stored.
 */
 export const addMembers = (
  userIds: string[],
  conversationId: string
): ThunkAction<Promise<void>> => {
  return (dispatch, getState, context) => {
    return (createUsers(userIds))
    .then(()=> {
      return (dispatch(
      setChannelMembers({
        channel: conversationId,
        uuids: userIds ,
        include: {
          customFields: true,
          totalCount: false
        }
      })
    )) as any
    });
  };
};

/**
 * Removes a member from a conversation.
 * The membership in the conversation will be stored.
 */
export const removeMember = (
  userId: string,
  conversationId: string
): ThunkAction<Promise<void>> => {
  return (dispatch, getState, context) => {
    return (dispatch(
      removeChannelMembers({
        channel: conversationId,
        uuids: [userId]
      })
    ) as any)
  };
};

export const getCurrentConversationMembers = createSelector(
  [
    getUsersById,
    getCurrentConversationId,
    getUsersByConversationId,
  ],
  (
    users: UsersIndexedById,
    conversationId: string,
    conversationMemberships: MembershipHash,
  ): UserFragment[] => {
    return conversationMemberships[conversationId]
      ? conversationMemberships[conversationId].map(user => {
          return {
            ...users[user.id],
          };
        })
      : [];
  }
);

export const refreshMembers = (members: UserFragment[], dispatch, currentConversationId): ThunkAction<Promise<void>>  => {
  return (dispatch, getState, context) => {
    var needsRefresh = false;
    members.forEach(member => {
      if (!('id' in member)) {
        needsRefresh = true;
      }
    })

    if (needsRefresh && currentConversationId !== "") {
      dispatch(fetchChannelMembers({
        channel: currentConversationId,
        include: {
          UUIDFields: true,
          customUUIDFields: true,
          totalCount: true
        },
      }));
    }

    return Promise.all([]).then(() => {
    });
  }
}

const ConversationMembers = () => {
  const myUserId = useSelector(getLoggedInUserId);
  var members: UserFragment[] = useSelector(getCurrentConversationMembers);
  const currentConversationId = useSelector(getCurrentConversationId);
  const dispatch = useDispatch();
  const viewConversationMembers = useSelector(getConversationMembersView);
  const conversation = useSelector(getCurrentConversation);
  const { role: myRole } = SessionService.getUser();

  dispatch(refreshMembers(members, dispatch, currentConversationId))
  useEffect(() => {
    dispatch(refreshMembers(members, dispatch, currentConversationId))
  }, [members])

  const storedPaginationState = useSelector(
    getChannelMembersPaginationStateById
  )[currentConversationId];

  const restorePaginationState = useCallback(() => {
    return storedPaginationState;
  }, [storedPaginationState]);

  const savePaginationState: SavePaginationState<
    string | undefined,
    string
  > = useCallback(
    (channel, pagination, count, pagesRemain) => {
      dispatch(
        setChannelMembersPagination(channel, { pagination, count, pagesRemain })
      );
    },
    [dispatch]
  );

  const getNextPage: GetNextPage<
    UUIDMembershipObject<{}, {}>,
    string | undefined,
    string
  > = useCallback(
    async (next, total, channel) => {
      const pageSize = 100;
      const action = ((await dispatch(
        fetchChannelMembers({
          limit: pageSize,
          channel,
          include: {
            UUIDFields: true,
            customUUIDFields: true,
            totalCount: true
          },
          page: {
            next: next || undefined
          }
        })
      )) as unknown) as ChannelMembersRetrievedAction<{}, {}, unknown>;
      const response = action.payload.response;
      return {
        results: response.data,
        pagination: response.next,
        pagesRemain:
          response.totalCount && total
            ? total + response.data.length < response.totalCount
            : response.data.length === pageSize
      };
    },
    [dispatch]
  );

  const { containerRef, endRef } = usePagination(
    getNextPage,
    currentConversationId,
    savePaginationState,
    restorePaginationState
  );

  const userInMembersList = () => {
    // this might not be efficient if the memberlist is large and components keep re-rendering
    for (var i = 0; i < members.length; i++) {
      if (members[i].id == myUserId) {
        return true
      }
    }
    return false
  }

  // This is so we leave a conversation when removed by someone else.
  if (members.length != 0 && !userInMembersList()) {
    dispatch(composeViewDisplayed())
  }

  return (
    <Drawer
      open={viewConversationMembers}
      edge="right"
      style={{ position: "absolute", right: "0", padding: "0" }}
      className="border-left"
      wide
    >
      <div className="p-3 border-bottom">
        <div className="d-flex flex-row justify-content-between mb-3">
          <h5 className="d-flex align-items-center mb-0">Members</h5>
          <div onClick={() => dispatch(conversationMembersViewHidden())}>
            <Icon icon={closeOutlined} height="25px" width="25px" />
          </div>
        </div>
        {["STAFF", "OWNER"].includes(myRole) &&
          <div
            className="d-flex"
            onClick={async () => {
              await dispatch(conversationMembersViewHidden());
              await dispatch(currentConversationViewHidden());
              await dispatch(addMembersViewDisplayed());
            }}
          >
            <Button color="primary" block>
              Add Members
              <Icon icon={usergroupAddOutlined} className="ml-2" height="25px" width="25px" />
            </Button>
          </div>
        }
      </div>

      <ScrollView ref={containerRef}>
        {members.map(user => (
          <MemberDescription
            user={user}
            key={user.id}
            you={user.id === myUserId}
            canRemove={["STAFF", "OWNER"].includes(myRole)}
            onRemoveMember={() => {
              if (user.id === myUserId)
                dispatch(leaveConfirmationViewDisplayed());
              else
                dispatch(removeMember(user.id, conversation.id));
            }}
          />
        ))}
        <div ref={endRef} />
      </ScrollView>
    </Drawer>
  );
};

export { ConversationMembers };
