import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CustomInput,
} from "reactstrap";
import FalconCardHeader from "./FalconCardHeader";
import SessionService from "../../services/SessionService";
import { edit } from "../../api/organization.api";
import { toast } from "react-toastify";
import { checkBoEmailVerified } from "../../api/user.api";
import { TFA_SETTING } from '../../constants'; 

const TwoFactorAuthenticationCard = ({ organization, setOrganization }) => {

  const [disable2FAToggles, setDisable2FAToggles] = useState(false);
  let user = SessionService.getUser();

  const inputSwitched = async e => {
    const sw = e.target.id;
    const change = e.target.checked;
    let newTfaType;
    
    /* Determine new 2FA type */
    try {
      if (sw === 'internalToggle') {
        if (change){
          if (organization.tfaType == TFA_SETTING.CLIENTS){
            newTfaType = TFA_SETTING.ALL;
          } else if (organization.tfaType == TFA_SETTING.NONE){
            newTfaType = TFA_SETTING.INTERNAL;
          }
        } else {
          if (organization.tfaType == TFA_SETTING.ALL){
            newTfaType = TFA_SETTING.CLIENTS;
          } else if (organization.tfaType == TFA_SETTING.INTERNAL){
            newTfaType = TFA_SETTING.NONE;
          }
        }
      } else if (sw === 'clientToggle') {
        if (change){
          if (organization.tfaType == TFA_SETTING.INTERNAL){
            newTfaType = TFA_SETTING.ALL;
          } else if (organization.tfaType == TFA_SETTING.NONE){
            newTfaType = TFA_SETTING.CLIENTS;
          }
        } else {
          if (organization.tfaType == TFA_SETTING.ALL){
            newTfaType = TFA_SETTING.INTERNAL;
          } else if (organization.tfaType == TFA_SETTING.CLIENTS){
            newTfaType = TFA_SETTING.NONE;
          }
        }
      }

      /* Update preference in backend and local storage */
      await edit(organization.id, {
        ...organization,
        tfaType: newTfaType,
      });

      /* Update state organization variable */
      setOrganization({ ...organization, "tfaType": newTfaType })

      SessionService.setOrganization({
        ...organization, 
        tfaType: newTfaType,
      })

      /* Notify user changes have went through */
      toast.info('Updated 2FA settings');

    } catch (err) {
      toast.error("Could not update 2FA settings.");
    }
  }

  /* If the 'Staff (and Owner)' toggle should be on or not */
  const isInternalToggled = () => {
    return organization.tfaType == 2 || organization.tfaType == 0;
  }

  /* If the 'Clients' toggle should be on or not */
  const isClientsToggled = () => {
    return organization.tfaType == 2 || organization.tfaType == 1;
  }

  useEffect(() => {
    shouldDisplayVerifyAlert();
  }, [])

  const shouldDisplayVerifyAlert = async () => {
    if (user.role == 'OWNER'){
      const res = await checkBoEmailVerified();
      const { isBOemailVerified } = res.data;
      setDisable2FAToggles(!isBOemailVerified);
    }
  }

  return (
    <Card className='mb-3'>
      <FalconCardHeader title='Two-factor Authentication' light={false} />
      <CardBody className='bg-light'>
        <p>
          If 2FA is enabled users will be required to enter a verification code when they login. The code will be sent by email.
        </p>
        
        {/* Internal toggle */}
        <CustomInput
          type='switch'
          id='internalToggle'
          label={(isInternalToggled() ? `Enabled` : `Disabled`) + ` - for Staff (and Owner)`}
          checked={isInternalToggled()}
          onChange={inputSwitched}
          disabled = {disable2FAToggles}>
        </CustomInput>

        {/* Clients toggle */}
        <CustomInput
          type='switch'
          id='clientToggle'
          label={(isClientsToggled() ? `Enabled` : `Disabled`) + ` - for Clients`}
          checked={isClientsToggled()}
          onChange={inputSwitched}
          disabled = {disable2FAToggles}>
        </CustomInput>

      </CardBody>
    </Card>
  );
};

export default TwoFactorAuthenticationCard;
