import React, { useState, useRef, useEffect } from 'react';
import DocumentsTable from './DocumentsTable';
import SessionService from '../../services/SessionService';
import StorageConnectionErrorOwner from '../../components/clienttable/common/StorageConnectionErrorOwner';
import StorageConnectionErrorMessageNonOwner from '../../components/clienttable/common/StorageConnectionErrorMessageNonOwner';
import SetupStorageAlert from '../../components/clienttable/alerts/SetupStorageAlert';
import MoveCard from '../../components/clienttable/MoveCard';
import PageHeader from '../../components/clienttable/PageHeader';
import ShareSettingsModal from '../../components/clienttable/share-settings/ShareSettingsModal';
import documentLabels from "../../data/clienttable/documentLabels";
import { toast } from "react-toastify";
import {
  get
} from "../../api/organization.api";
import driveApi from "../../api/drive.api";
import { StringParam, useQueryParam } from 'use-query-params';
import DocumentBreadcrumbs from "./DocumentBreadcrumbs";

const stickyStyle = {
  position: "-webkit-sticky",
  position: "sticky",
  zIndex: 2,
  top: 65,
}

const Documents = () => {
  // Folder id, either given from the link, or defaults to the root
  const [file, setFile] = useState(null);
  const [folderId, setFolderId] = useQueryParam('folderId', StringParam) || '';
  const [itemPath, setItemPath] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [documentLabel, setDocumentLabel] = useState({});

  const user = SessionService.getUser()
  const organization = SessionService.getOrganization();
  const sortOrder = SessionService.getSortPreference();
  const [selected, setSelected] = useState([]);
  const errorMessage = () => {
    return (user && user.role === 'OWNER') ? <StorageConnectionErrorOwner /> : <StorageConnectionErrorMessageNonOwner />;
  }

  const [from, setFrom] = useState({})
  const [to, setTo] = useState({})
  const [shouldMoveFiles, setShouldMoveFiles] = useState(false)
  const [showMoveCard, setShowMoveCard] = useState(false)
  const showConnectionError = organization && organization.connection_state == "DISCONNECTED"

  const [openShareModal, setOpenShareModal] = useState(false);

  // Up to date version of loadSubFolders with the latest folderId
  const reloadViewRef = useRef(null);

  useEffect(() => {
    loadOrganization();
    loadItemPath();
  }, [])

  useEffect(() => {
    if (folderId) loadSubFolders();
    else loadItemPath();
    reloadViewRef.current = loadSubFolders;
  }, [folderId])

  // Reload item path on back button click
  window.onpopstate = () => {
    loadItemPath();
  }

  useEffect(() => {
    if (showMoveCard)
      setFrom(itemPath[itemPath.length-1]);
  }, [showMoveCard])

  useEffect(() => {
    if (folderId)
      setTo(itemPath[itemPath.length-1]);
  }, [folderId])

  const loadOrganization = async () => {
    const { id } = SessionService.getOrganization();
    const res = await get(id);
    SessionService.setOrganization(res.data);
  }

  const loadItemPath = async () => {
    setLoading(true);
    try {
      const res = await driveApi.getItemPath(folderId);
      if (!folderId && res.data.path.length) setFolderId(res.data.path[0].id);
      setItemPath(res.data.path || []);
    } catch (error) {
      setDocumentLabel(documentLabels.NO_PERMISSIONS);
      toast.dismiss();
    }
    setLoading(false);
  }

  const loadSubFolders = async () => {
    setLoading(true);
    try {
      const res = await driveApi.getSubFolders(folderId);
      setFiles(res.data.folders || []);
    } catch (error) {
      setDocumentLabel(documentLabels.NO_PERMISSIONS);
      toast.dismiss();
    }
    setLoading(false);
  };

  const onBreadcrumbClick = (folder, index) => {
    setItemPath(itemPath.slice(0, index + 1))
    setFolderId(folder.id);
    setSelected([]);
  }

  const onBreadcrumbShareClick = (folder) => {
    setFile(folder);
    setOpenShareModal(true);
  }

  const onMoveClick = () => {
    setShouldMoveFiles(true);
  }

  const onMoveCancel = () => {
    setShowMoveCard(false)
    setShouldMoveFiles(false)
    setSelected([])
  }

  return (
    <>
      <SetupStorageAlert
        hidden={organization.provider || user.role !== 'OWNER'}
      />
      {user.role !== 'OWNER' &&
        <PageHeader
          title={`Welcome, ${user.firstName} ${user.lastName}`}
          className="mb-3"
        />
      }
      {showConnectionError && errorMessage()}
      {showMoveCard &&
        <MoveCard
          from={from}
          to={to}
          onClick={onMoveClick}
          onCancel={onMoveCancel}
          style={stickyStyle}
        />
      }
      <DocumentBreadcrumbs
        itemPath={itemPath}
        onClick={onBreadcrumbClick}
        share
        onShareClick={onBreadcrumbShareClick}
      />
      <ShareSettingsModal
        file={file}
        isOpen={openShareModal}
        onClose={() => {
          setOpenShareModal(false);
          loadSubFolders();
        }}
      />
      <DocumentsTable
        file={file}
        setFile={setFile}
        folderId={folderId}
        setFolderId={setFolderId}
        files={files}
        setFiles={setFiles}
        itemPath={itemPath}
        setItemPath={setItemPath}
        loading={loading}
        reloadViewRef={reloadViewRef}
        isClient={user && user.role === 'CLIENT'}
        from={from}
        to={to}
        documentLabel={documentLabel}
        setDocumentLabel={setDocumentLabel}
        setShowMoveCard={setShowMoveCard}
        showMoveCard={showMoveCard}
        shouldMoveFiles={shouldMoveFiles}
        setShouldMoveFiles={setShouldMoveFiles}
        selected={selected}
        setSelected={setSelected}
        setOpenShareModal={setOpenShareModal}
        sortOrder={sortOrder ? sortOrder.documentSortPreference : null}
      />
    </>
  );
};

export default Documents;
