import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button, Progress } from 'reactstrap';

const MoveCard = ({ from, to, onClick, onCancel, ...rest }) => {
  return (
    <Card {...rest}>
      <CardHeader className="d-inline-flex">
        <h5 className="text-sans-serif w-10 mr-5">Move</h5>
        <Progress animated className="mb-3 w-100" color="info" value={100} />
      </CardHeader>
      <CardBody className="bg-light">
        <p><i>Navigate to desired location</i></p>
        <div className="d-flex flex-row justify-content-end">
          <p className="w-100">Selected item will be moved from {from.name} to <strong>{to ? to.name : from.name}</strong></p>
          <Button outline color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button outline color="primary" className="ml-2" onClick={onClick}>Confirm</Button>
        </div>
      </CardBody>
    </Card>
  );
}

MoveCard.propTypes = {
  from: PropTypes.object,
  to: PropTypes.string,
  setShowMoveCard: PropTypes.func,
  moveFiles: PropTypes.func,
};

export default MoveCard;
