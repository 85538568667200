/**
 * Stores user json object ot local storage
 * @param user
 */
const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
}

/**
 * Stores organization json object ot local storage
 * @param user
 */
const setOrganization = (data) => {
  localStorage.setItem('organization', JSON.stringify(data));
}

/**
 * Stores user tokens ot local storage
 * @param accessToken
 */
const setToken = (accessToken) => {
  localStorage.setItem('access-token', accessToken);
}

/**
 * Stores remember me email
 * @param email
 */
const setEmail = (email) => {
  localStorage.setItem('email', email);
}

const setSetupBitmap = (checklist) => {
  localStorage.setItem('setup-bitmap', checklist);
}

const setSetupHidden = (hidden) => {
  localStorage.setItem('setup-checklist-hidden', hidden);
}

/**
 * Get stored remember me email
 */
const getEmail = () => {
  return localStorage.getItem('email');
}

/**
 * get user from the local storage
 */
const getUser = () => {
  const data = localStorage.getItem('user');
  if (!data) return null;
  try {
    const user = JSON.parse(data);
    return user ? user : null;
  } catch (err) {
    return null
  }
}

/**
 * get organization from the local storage
 */
const getOrganization = () => {
  const data = localStorage.getItem('organization');
  if (!data) return null;
  try {
    const organization = JSON.parse(data);
    return organization ? organization : null;
  } catch (err) {
    return null
  }
}

/**
 * Stores array of channels to local storage
 * @param channels
 */
const setChannels = (channels) => {
  localStorage.setItem('channels', JSON.stringify(channels));
}

/**
 * get messaging channnels from the local storage
 */
const getChannels = () => {
  const data = localStorage.getItem('channels');
  if (!data) return [];
  const channels = JSON.parse(data);
  return channels ? channels : [];
}

/**
 * Stores a timetoken for messaging to local storage
 * @param timetoken
 */
const setMessagingTimetoken = (timetoken) => {
  localStorage.setItem('messaging-timetoken', JSON.stringify(timetoken));
}

/**
 * get messaging timetoken from the local storage. This timetoken is stored every 5 minutes.
 * When 5 mins have elapsed, it is refreshed and all subscriptions are updated.
 */
const getMessagingTimetoken = () => {
  const data = localStorage.getItem('messaging-timetoken');
  if (!data) return -1;
  const timetoken = JSON.parse(data);
  return timetoken ? timetoken : Date.now();
}

/**
 * get access token from the local storage
 */
const getAccessToken = () => {
  return localStorage.getItem('access-token') || null;
}

const getSetupHidden = () => {
  return localStorage.getItem('setup-checklist-hidden') || '0';
}

const getSetupBitmap = () => {
  return localStorage.getItem('setup-bitmap') || '0';
}

/**
 * Destroy user object and tokens from local storagen
 */
const destroy = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('access-token');
  localStorage.removeItem('organization');
  localStorage.removeItem('subdomain');
  localStorage.removeItem('authorization-error');
  localStorage.removeItem('display-provider');
  localStorage.removeItem('channels');
  localStorage.removeItem('messaging-timetoken');
}

/**
 * returns is user is authenticated
 */
const isAuth = () => {
  return getUser() && getAccessToken() && getSubdomain()
}

/**
 * Stored user subdomain
 */
const setSubdomain = (subdomain) => {
  localStorage.setItem('subdomain', subdomain);
}

/**
 * returns logged user subdomain
 */
const getSubdomain = () => {
  return localStorage.getItem('subdomain') || null;
}

/**
 * Stores sort order preferences json object ot local storage
 * @param user
 */
 const setSortPreference = (preferences) => {
  localStorage.setItem('sort-preferences', JSON.stringify(preferences));
}

/**
 * Get sort order preferences from local storage
 */
 const getSortPreference = () => {
  const data = localStorage.getItem('sort-preferences');
  if (!data) return null;
  try {
    const preferences = JSON.parse(data);
    return preferences ? preferences : null;
  } catch (err) {
    return null;
  }
}

/**
 * Stores collapse preferences json object ot local storage
 * @param preferences
 */
 const setCollapsePreference = (preferences) => {
  localStorage.setItem('collapse-preferences', JSON.stringify(preferences));
}

/**
 * Get collapse preferences from local storage
 */
 const getCollapsePreference = () => {
  const data = localStorage.getItem('collapse-preferences');
  const temp = {'about': true, 'contact': true};
  if (!data) return temp;
  try {
    const preferences = JSON.parse(data);
    return preferences ? preferences : temp;
  } catch (err) {
    return temp;
  }
}

export default {
  isAuth,
  setUser,
  getUser,
  setToken,
  destroy,
  getAccessToken,
  getOrganization,
  setOrganization,
  setSubdomain,
  getSubdomain,
  setEmail,
  getEmail,
  setChannels,
  getChannels,
  setMessagingTimetoken,
  getMessagingTimetoken,
  setSetupHidden,
  getSetupHidden,
  setSetupBitmap,
  getSetupBitmap,
  setSortPreference,
  getSortPreference,
  setCollapsePreference,
  getCollapsePreference,
}
