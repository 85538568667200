const { default: Bugsnag } = require("@bugsnag/js");
const { default: SessionService } = require("../services/SessionService");


const _log = (level, error, msg) => {
  const user = SessionService.getUser()
  Bugsnag.notify(new Error(error), function (event) {
    if (user) {
      Bugsnag.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`)
    }
    event.severity = level
    if (msg) {
      event.context = msg
    }
  })
}

module.exports = {
  info: (error, msg) => _log('info', error, msg),
  error: (error, msg) => _log('error', error, msg),
  warn: (error, msg) => _log('warning', error, msg),
};