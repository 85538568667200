import React, { useState, Fragment } from 'react';
import { Card, Button, CardBody, CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';
import driveApi from '../../../api/drive.api';
import ProviderButton from './ProviderButton';
import { getProvider, providers } from '../../../pages/Settings/Providers';
import { useEffect } from 'react';

const ProviderCard = ({organization}) => {
  const [storageSelected, setStorageSelected] = useState({})
  const [accountType, setAccountType] = useState('business');

  useEffect(() => {
    // if provider has been authorized on setup page, get connected temporary provider
    // this is to display the correct radio button after third party auth flow
    if (organization.temp_scope){
      const connectedTempProvider = getProvider(organization.temp_scope)
      setStorageSelected(connectedTempProvider)
    }
  }, [organization])

  const callAuthorize = async () => {   
    if (storageSelected && storageSelected.key) {
      let result;
      if (storageSelected.key == 'onedrive') {
        result = await driveApi.authorize(storageSelected.key, storageSelected.scope, accountType);
      } else {
        result = await driveApi.authorize(storageSelected.key, storageSelected.scope);
      }
      window.location = result.data.url
    }
  }

  const authorizeDrive = (e) => {
    e.preventDefault()
    callAuthorize()
  }

  return (
    <Card className="p-3 m-2">
        <h5>Select a provider and sign-in</h5>
        <CardBody>
          <div className="d-flex flex-row" style={{justifyContent: 'space-evenly'}}>
            {providers.map((provider) => {
              const icon = provider == storageSelected ? provider.selectedIcon : provider.unselectedIcon
              const color = provider == storageSelected ? '#2C7BE5' : '#748194'
              return <ProviderButton
                key={"provider-" + provider.scope}
                textColor={color}
                icon={icon}
                onSelect={() => { setStorageSelected(provider) }}
                provider={provider}
                isSelected={provider == storageSelected}
              />
            })}
          </div>
          {storageSelected.key == 'onedrive' && <div>
            <p style={{size: '16px', lineHeight: '21.79px', fontWeight: '700'}}>What is your account type with Microsoft?</p>
            <Fragment>
              <div style={{paddingBottom: '0.5rem'}}>
                <CustomInput
                  type="radio"
                  id="business"
                  name="ms"
                  checked={accountType=='business'}
                  onClick={() => {setAccountType('business')}}
                > <span style={{fontWeight: '400', size: '15px', lineHeight: '24px'}}>Business (OneDrive for Business)</span> </CustomInput>
              </div>
              <CustomInput
                type="radio"
                id="personal"
                name="ms"
                checked={accountType=='personal'}
                onClick={() => {setAccountType('personal')}}
              > <span style={{fontWeight: '400', size: '15px', lineHeight: '24px'}}>Microsoft / Personal (OneDrive)</span> </CustomInput>
            </Fragment>
            <br></br>
          </div>}
          <p>
            Requested permissions are used to store files into your storage drive
          </p>
          <Button
          color={'primary'}
          size="sm"
          className="px-4 ml-2"
          block
          onClick={authorizeDrive}>
            Authorize {storageSelected.name}
          </Button>
        </CardBody>
      </Card>
  )
}

ProviderCard.propTypes = {
  organization: PropTypes.object,
  providers: PropTypes.array
};

export default ProviderCard;
