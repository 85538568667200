import React, { useState, useReducer, useEffect } from 'react';
import AppContext from '../../../context/Context';
import { toast } from 'react-toastify';

const UserProvider = ({ children }) => {


  const [user, setUser] = useState({})
  const [showBurgerMenu, setShowBurgerMenu] = useState(false)
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(false)
  const [isTopNav, setIsTopNav] = useState(false)

  const value = {
    user,
    setUser,
    showBurgerMenu,
    setShowBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    isTopNav,
    setIsTopNav
  }

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default UserProvider;
