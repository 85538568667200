import React, {useEffect, useState} from 'react';
import { Card, CardHeader, Button, CardBody, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import FreeSpaceCard from '../../components/clienttable/settings/FreeSpaceCard';
import ChangeConnectionModal from '../../components/clienttable/settings/ChangeConnectionModal';
import FalconCardHeader from '../../components/clienttable/FalconCardHeader';
import StorageConnectionErrorOwner from '../../components/clienttable/common/StorageConnectionErrorOwner';
import SessionService from '../../services/SessionService';
import { isEmpty } from 'lodash';
import driveApi from '../../api/drive.api';
import { toast } from 'react-toastify';
import { getProvider } from './Providers';

const ConnectedDrive = ({ organization, setInChangeConnectionFlow }) => {
  const [showChangeConnectionModal, setShowChangeConnectionModal] = useState(false);
  const isDisconnected = organization.connection_state == "DISCONNECTED"
  const provider = getProvider(organization.scope)
  const [freeSpace, setFreeSpace] = useState(0);
  const [email, setEmail] = useState('');

  // const hasError = window.location.search.indexOf('error') !== -1;
  const reconnect = window.location.search
  
  useEffect(() => {
    // Handles reconnecting a drive, either with the same account or a different account
    if (reconnect.includes('successful')) {
      toast.success('Storage successfully reconnected.');
    } else if (reconnect.includes('error-different-account')) {
      toast.error('Reconnect failed. You must connect the same account.')
    } 

    if (!isDisconnected) loadInfo();
  }, [])

  useEffect(() => {
    if (!isDisconnected) loadInfo();
  }, [provider])

  const loadInfo = async () => {
    try {
      const result = await driveApi.getProviderInfo();
      if (result.data.freeSpace && result.data.email){
        setFreeSpace(result.data.freeSpace);
        setEmail(result.data.email);
      }
    } catch(error){
      toast.error('Error retrieving provider info');
    }
  }

  return (
    <div className="d-flex flex-column">
      {isDisconnected ? <StorageConnectionErrorOwner /> : null}
      <div className="card-deck">
        <ConnectedStorageCard
          provider={provider}
          organization={organization}
          setShowChangeConnectionModal={setShowChangeConnectionModal}
        />
        <FreeSpaceCard 
          provider={provider}
          freeSpace={freeSpace}
          email={email}
          isDisconnected={isDisconnected} 
        />
      </div>
      <ChangeConnectionModal
        showChangeConnectionModal={showChangeConnectionModal}
        setShowChangeConnectionModal={setShowChangeConnectionModal}
        setInChangeConnectionFlow={(val) => setInChangeConnectionFlow(val)}
      />
    </div>
  )
}

const ConnectedStorageCard = ({ provider, organization, setShowChangeConnectionModal}) => {
  const onChangeStorage = () => {
    setShowChangeConnectionModal(true)
  }

  const isDisconnected = organization.connection_state == "DISCONNECTED"
  const handleReconnect = async (e) => {
    e.preventDefault();
    callAuthorize();
  }

  const callAuthorize = async () => {
    const organization = SessionService.getOrganization()
    if (isEmpty(organization)) return;
    
    try {
      const result = await driveApi.reconnect(organization.provider, organization.scope)
      if (result.data){
        window.location = result.data.url
      }
    } catch {
      toast.error('Reconnect failed.')
    }
  }

  return (
    <Card className="m-2 text-sans-serif">
      <FalconCardHeader title="Connected Storage Drive" light={false} />
      <CardBody className="d-flex flex-column pt-4 bg-light">
        <div className="d-flex flex-row">
          <div className="flex-fill">
            <img src={provider.selectedIcon} className="provder-img-connected" />
          </div>
        <div className="p-2 pt-0 flex-fill">
          <p>{organization.name} is connected to {provider.name}</p>
          <p className="font-weight-black">Connection status:
            <Badge color={isDisconnected ? 'danger' : 'success'} className="ml-2" >
              {isDisconnected ? 'Disconnected' : 'Active'}
            </Badge>
            </p>
          </div>
        </div>
        {isDisconnected &&
          <Button color={'falcon-primary'} onClick={(e) => handleReconnect(e)} className="mb-2">
              {`Reconnect ${organization.storage_email}`}
          </Button>
        }
        <Button color={'falcon-warning'} onClick={() => onChangeStorage()}>
            {'Change Storage Connection'}
        </Button>
      </CardBody>
    </Card>
  )
}

ConnectedDrive.propTypes = {
  provider: PropTypes.object,
  organization: PropTypes.object,
  setInChangeConnectionFlow: PropTypes.func,
};

export default ConnectedDrive;
