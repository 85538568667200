import React, { useRef } from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadFiles } from "./DocumentHelpers";

const MoreButtonRow = ({
  file,
  setFileSelectedForCurrentDropdown,
  shouldDisplayMoveCard,
  isClientInRootFolder,
  setOpenRenameModal,
  setFileToPreview,
}) => {
  const toastId = useRef(null);

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        color='link'
        size='sm'
        className='text-600 btn-reveal'
        onClick={() => setFileSelectedForCurrentDropdown(file)}
      >
        <FontAwesomeIcon icon='ellipsis-h' className='fs--1' />
      </DropdownToggle>
      <DropdownMenu
        className='document-dropdown'
        modifiers={{
          setMaxHeight: {
            enabled: true,
            fn: (data) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: "auto",
                  maxHeight: "150px",
                },
              };
            },
          },
        }}
      >
        <DropdownItem
          disabled={shouldDisplayMoveCard}
          onClick={() => file.openShareSettingsModal(true)}>
          Share settings
        </DropdownItem>
        <DropdownItem
          disabled={shouldDisplayMoveCard}
          onClick={() => setFileToPreview(file)}>
          Preview
        </DropdownItem>
        <DropdownItem
          disabled={shouldDisplayMoveCard || isClientInRootFolder()}
          onClick={() => file.selectMovingFiles()}>
          Move
        </DropdownItem>
        <DropdownItem
          disabled={shouldDisplayMoveCard || isClientInRootFolder()}
          onClick={() => setOpenRenameModal(true)}>
          Rename
        </DropdownItem>
        <DropdownItem
          disabled={shouldDisplayMoveCard || isClientInRootFolder()}
          onClick={() => file.duplicate()}>
          Duplicate
        </DropdownItem>
        <DropdownItem
          disabled={shouldDisplayMoveCard || file.isFolder}
          onClick={() => downloadFiles([file], toastId)}>
          Download
        </DropdownItem>
        <DropdownItem
          disabled={shouldDisplayMoveCard || isClientInRootFolder()}
          onClick={() => file.delete()}
          className={isClientInRootFolder() ? "" : "text-danger"}>
          Delete
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default MoreButtonRow;
