import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ClientsTable from '../../components/clienttable/tables/ClientsTable';
import ClientLimitAlert from '../../components/clienttable/alerts/ClientLimitAlert';

import { deleteUser, deleteManyUsers, getAllClient, checkBoEmailVerified } from '../../api/user.api';
import AddUserModal from '../../modals/AddUserModal';
import DeleteUserModal from '../../modals/DeleteUserModal';
import clientColumns from '../../data/clienttable/tables/clients';
import SessionService from '../../services/SessionService';
import BOVerificationAlert from '../../components/clienttable/alerts/BOVerificationAlert';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [isAddUserModalOpen, setOpenAddUserModal] = useState(false);
  const [deleteUserInfo, setDeleteUserInfo] = useState({
    showRemoveUserModal: false,
    userIds: null
  })
  const [displayVerifyAlert, setDisplayVerifyAlert] = useState(false);
  const organization = SessionService.getOrganization();
  const user = SessionService.getUser();
  const sortOrder = SessionService.getSortPreference();

  useEffect(() => {
    refreshView()
    shouldDisplayVerifyAlert();
  }, [])

  const shouldDisplayVerifyAlert = async () => {
    if (user.role == 'OWNER'){
      const res = await checkBoEmailVerified();
      const { isBOemailVerified } = res.data;
      setDisplayVerifyAlert(!isBOemailVerified);
    }
  }
  const onNew = async () => {
    if (clients.length < organization.max_clients) setOpenAddUserModal(true)
    else toast.error('Client account limit has been reached')
  }

  const addUserClose = () => {
    setOpenAddUserModal(false)
    refreshView()
  }

  const onDeleteOne = async (ids) => {
    setDeleteUserInfo({showRemoveUserModal:true, userIds: ids})
  }

  const onDeleteConfirm = async () => {
    if (deleteUserInfo.userIds.length > 1) {
      await deleteManyUsers(deleteUserInfo.userIds)
    } else {
      await deleteUser(deleteUserInfo.userIds)
    }
    setDeleteUserInfo({showRemoveUserModal:false, userIds: null})
    toast.success(`Successfully deleted user`);
    refreshView()
  }

  const onDeleteMany = async (ids) => {
    await deleteManyUsers(ids)
    toast.success(`Successfully delete selected users`);
    refreshView()
  }

  const refreshView = async (filters = {}) => {
    const result = await getAllClient({ filters })
    /* Name field for sorting purposes, not used elsewhere */
    result.data.forEach(client => {
      client.name = client.firstName + (client.lastName ? ' ' + client.lastName : '')
    });
    setClients(result.data || [])
  }

  const onRefresh = (filters) => {
    refreshView(filters)
  }

  return (
    <>
      <h3 className="ml-3 mb-3">Browse Clients</h3>
      <ClientLimitAlert
        hidden={organization.plan !== 'FREE'}
        currentClients={clients.length}
        maxClients={organization.max_clients}
        linkHidden={user.role != 'OWNER'} />
      {displayVerifyAlert ? <BOVerificationAlert /> : <></>}
      <ClientsTable
        className="mb-3"
        showHeaders={true}
        showSelectCheckbox
        disableButtons = {displayVerifyAlert}
        showDelete
        showSearch
        onNew={onNew}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
        onRefresh={onRefresh}
        clients={clients}
        columns={clientColumns}
        sortOrder={sortOrder ? sortOrder.clientSortPreference : null} />
      <AddUserModal
        isOpen={isAddUserModalOpen}
        onClose={addUserClose}
        role="CLIENT"/>
      <DeleteUserModal
        isOpen={deleteUserInfo.showRemoveUserModal}
        onDelete={onDeleteConfirm}
        onClose={() => setDeleteUserInfo({showRemoveUserModal:false, userIds:null})} />
    </>
  );
};

export default Clients;
