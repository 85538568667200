import React from "react";
import ButtonIcon from "../../components/common/ButtonIcon";
import userOutlined from '@iconify/icons-ant-design/user-outlined';
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const DocumentBreadcrumbs = ({ itemPath, share, onShareClick, onClick, ...rest }) => (
  <Breadcrumb {...rest}>
    <div className="d-flex align-items-center">
      {itemPath.map((folder, index) =>
        <BreadcrumbItem key={"breadcrumb-" + folder.id} >
          {index < itemPath.length - 1 ?
            <span
              className="text-primary"
              onClick={() => { onClick(itemPath[index], index) }}
              style={{ cursor: "pointer" }}
            >
              {folder.name}
            </span>
            : <>{folder.name}</>}
        </BreadcrumbItem>
      )}
      {itemPath.length > 1 && share &&
        <ButtonIcon
          icon={userOutlined}
          className='rounded-capsule ml-2'
          color='falcon-primary'
          size='sm'
          onClick={() => onShareClick(itemPath[itemPath.length - 1])}
        />
      }
    </div>
  </Breadcrumb>
)

export default DocumentBreadcrumbs;