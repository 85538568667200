import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormGroupInput from "../../../../../components/common/FormGroupInput";
import { getCurrentConversationId,
  getCurrentConversation,
  ConversationFragment,
 } from "../../currentConversation/currentConversationModel";
import { editChannelViewHidden } from "../../layout/LayoutActions";
import { getEditChannelView } from "../../layout/Selectors";
import { updateChannel } from "../../../../../api/messages";
import { toast } from "react-toastify";


const EditChannelModal = () => {
  const viewEditChannel: any = useSelector(getEditChannelView);
  const dispatch = useDispatch();
  const currentConversationId: string = useSelector(getCurrentConversationId);
  const conversation: ConversationFragment = useSelector(
    getCurrentConversation
  );
  const [channelData, setChannalData] = useState({
    name: conversation.name,
    description: conversation.description,
  })

  const onSubmit = async e => {
    e.preventDefault();
    try {
    const resp = await updateChannel(currentConversationId, channelData.name, channelData.description);
    } catch (error) {
      toast.error('Failed to edit channel')
    }

    dispatch(editChannelViewHidden());
  }

  // Reset description when navigating away from the page
  useEffect(() => {
    if (!viewEditChannel)
      setChannalData({
        name: conversation.name,
        description: conversation.description,
      });
  }, [viewEditChannel])

  const nameFormatter = (name: string) => {
    return name.replace(/[\s]/g, '-').replace(/[^A-Za-z0-9-]/g, '');
  }

  return (
    <Modal isOpen={viewEditChannel}>
      <Form onSubmit={onSubmit}>
        <ModalHeader>{"Edit Channel"}</ModalHeader>
        <ModalBody>
          <FormGroupInput
            id="channel-name"
            className="mb-0"
            label="Channel Name"
            maxLength="80"
            value={channelData.name}
            onChange={({ target }) => setChannalData({ ...channelData, name: nameFormatter(target.value) })}
          />
          <FormGroupInput
            id="channel-description"
            label="Channel description"
            type="textarea"
            maxLength="500"
            className="max-vh-25"
            value={channelData.description}
            onChange={({ target }) => setChannalData({ ...channelData, description: target.value })}
          />
          <div className="d-flex justify-content-between">
            {channelData.description.length}/500 characters
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => { dispatch(editChannelViewHidden()) }}>Cancel</Button>
          <Button color="primary" type="submit" disabled={false}>Save</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export { EditChannelModal };
