import React from "react";
import GlobalStyles from "../main/styles/GlobalStyles";
import Normalize from "../main/styles/Normalize";
import { ApplicationRouter } from "./Router";
import { appTheme } from "./Theme";
import { ThemeProvider } from "styled-components";


const Messages = () => {

  return (
    <ThemeProvider theme={appTheme}>
          <Normalize />
          <GlobalStyles />
          <ApplicationRouter />
    </ThemeProvider>
  );
};

export { Messages };
