import React, { Fragment, useState, useEffect,  useRef } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Icon from "@iconify/react";

import FalconCardHeader from "./FalconCardHeader";

import FalconDropzone from "../common/FalconDropzone";
import cloudUploadOutlined from "@iconify/icons-ant-design/cloud-upload-outlined";

import { updateLogo, deleteLogo } from "../../api/organization.api";

import { ProgressToast } from "./ProgressToast";
import SuccessToast from "./SuccessToast";
import { toast } from "react-toastify";

import { logoConfig } from "../../config"
import SessionService from "../../services/SessionService";

const LogoFileUploadCard = ({ organization }) => {
  // List of files submitted through the dropzone
  const logos = useState(null);
  // Current organization logo link pulled from the database or recently submitted
  const [logoOrg, setLogoOrg] = useState(organization.logo);
  // First file submitted displayed in the modal, to be saved
  const [logoModal, setLogoModal] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const toastId = useRef(null);

  const handleSaveLogo = async (file) => {
    var successMsg = "Upload complete"
    var progressMsg = `Uploading ${file.name}`
    ProgressToast({ toastId, message:progressMsg });
    try {
      const res = await updateLogo(organization.id, file.base64);
      SuccessToast({ toastId, message:successMsg });
      setLogoOrg(res.data.logo);
      setOpenModal(false);
      const currentOrganization = await SessionService.getOrganization()
      SessionService.setOrganization({...currentOrganization, logo: res.data.logo})
    } catch (error) {

      // TODO: Why we have a finish toast here? It could've failed no?
      // UploadingToastFinished({ toastId });

      toast.error("Upload failed");
      setOpenModal(false);
    }
  };

  const handleDeleteLogo = async () => {
    try {
      await deleteLogo(organization.id);
      setLogoOrg('');
      const currentOrganization = await SessionService.getOrganization()
      SessionService.setOrganization({...currentOrganization, logo: null})
      toast.info("Successfully deleted logo");
    } catch (error) {
      toast.error("Failed to delete logo");
    }
  }

  const handleChange = async (files) => {
    const file = files[0];
    if (file && isValidLogo(file)) {
      setLogoModal(file);
      setOpenModal(true);
    }
  };

  /**
   * Determines whether a file meets the requirements for a logo
   * @param {File} file
   * @returns String
   */
  const isValidLogo = (file) => {
    if (file.size > logoConfig.size)
      toast.error("File size is too large");
    else if (!(logoConfig.formats.includes(file.type)))
      toast.error("File type must be a jpeg or png");
    else
      return true;
    return false;
  }

  return (
    <Card className='mb-3'>
      <FalconCardHeader title='Logo' light={false} />
      <CardBody className='bg-light'>
        <p>
          Your logo will appear on the top bar, login page and email notifications. If no logo is
          added, your organization name is displayed.
        </p>
        <Row>
          <Col>
            <FalconDropzone
              files={logos}
              onChange={(files) => {
                handleChange(files);
              }}
              multiple={false}
              accept='image/*'
              placeholder={
                <Fragment>
                  <div className='fs-0 mx-auto d-inline-flex align-items-center'>
                    {/* Should increase the size of this... */}
                    <Icon icon={cloudUploadOutlined} className='mr-1' />
                    <div>
                      <p className='fs-0 mb-0 text-700'>Upload your logo</p>
                    </div>
                  </div>
                  <p className='mb-0 w-75 mx-auto text-500'>
                    Max dimensions: 280x90 image. Max size of 10MB. Accepted file types jpeg or png.
                  </p>
                </Fragment>
              }
            />
            <Button block color={'falcon-danger'} className="mr-2" onClick={() => handleDeleteLogo()}>
              Delete Logo
            </Button>
          </Col>
          <Col>
            <img src={logoOrg} height="90px" width="auto" />
          </Col>
        </Row>
      </CardBody>

      <Modal size='lg' isOpen={openModal} toggle={() => setOpenModal(!openModal)}>
        <ModalHeader>Organization logo</ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center my-2">
            {logoModal && <img src={logoModal.base64} height="90px" width="auto" /> }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline color='primary' className='mr-2' onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            disabled={false}
            onClick={() => handleSaveLogo(logoModal)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default LogoFileUploadCard;
