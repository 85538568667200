import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import AppContext from '../../context/Context';
import Logo from './Logo';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import SessionService from '../../services/SessionService';
import { Collapse, Navbar, Nav } from 'reactstrap';

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu, isTopNav } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  const organization = SessionService.getOrganization()

  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit sticky-top"
      expand={isTopNav ? topNavbarBreakpoint : navbarBreakPoint}
    >
      <div
        className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
          'd-lg-none': isTopNav,
          'd-xl-none': !isTopNav
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex align-item-center justify-content-center "
          onClick={() => {
            !isTopNav ? setShowBurgerMenu(!showBurgerMenu) : setNavbarCollapsed(!navbarCollapsed);
          }}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo at="navbar-top" orgName={organization.name} orgLogo={organization.logo}/>
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
