import React, {
  useEffect,
  useState
} from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  Link
} from "react-router-dom";
import billingFAQ from '../../data/clienttable/billingFAQ';
import PurchasePlanCard from "./PurchasePlanCard";
import PlanDetailsCard from "./PlanDetailsCard";
import BillingFAQCard from "./BillingFAQCard";
import PurchasePlanModal from "./PurchasePlanModal";
import SubscriptionsApi from '../../api/subscriptions.api';
import { get } from '../../api/organization.api';
import SessionService from '../../services/SessionService';
import { loadStripe } from "@stripe/stripe-js";
import { toast } from 'react-toastify';

const BillingLayout = () => {
  // Organization states
  const [currentPlan, setCurrentPlan] = useState('');
  const [maxStaff, setMaxStaff] = useState(0);
  const [maxClients, setMaxClients] = useState(0);
  const [showManageSubscriptions, setShowManageSubscriptions] = useState(true);

  // Purchase modal states
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [staffQuantity, setStaffQuantity] = useState(0);
  const [planInterval, setPlanInterval] = useState('monthly');
  const plan = 'professional';

  // Prices
  const monthlyPrice = 14;
  const annualPrice = 154;

  useEffect(() => {
    getOrganizationData();
  }, [])

  const getOrganizationData = async () => {
    let organization = SessionService.getOrganization();
    const res = await get(organization.id);
    organization = res.data;
    // Update session storage for organization
    SessionService.setOrganization(organization);
    // Update plan details information
    setCurrentPlan(organization.plan);
    setMaxStaff(organization.max_staff);
    setMaxClients(organization.max_clients);
    setShowManageSubscriptions(organization.plan !== 'FREE');
  }

  const handleCheckout = async e => {
    e.preventDefault();
    try {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY);
      const res = await SubscriptionsApi.checkoutSession(plan, planInterval, staffQuantity + 1)
      await stripe.redirectToCheckout({
        sessionId: res.data.id
      });
    } catch (err) {
      toast.error('Failed to create checkout session');
    }
  }

  const handleCheckoutReset = async e => {
    e.preventDefault();
    setPurchaseModal(false);
    setStaffQuantity(0);
    setPlanInterval('monthly');
  }

  const handleManageSubscriptions = async e => {
    e.preventDefault();
    try {
      const res = await SubscriptionsApi.portalSession();
      const portalLink = res.data.url;
      window.location.href = portalLink;
    } catch (err) {
      toast.error('Failed to load Stripe portal session');
    }
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to='/'>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Billing</BreadcrumbItem>
      </Breadcrumb>
      <h3>Billing</h3>
      <PurchasePlanCard
        className="mb-3"
        hidden={currentPlan != 'FREE'}
        currentPlan={currentPlan}
        purchaseClick={() => setPurchaseModal(true)}
      />
      <PlanDetailsCard
        className="mb-3"
        currentPlan={currentPlan}
        maxStaff={maxStaff}
        maxClients={maxClients}
        onManageSubscriptions={handleManageSubscriptions}
        showManageSubscriptions={showManageSubscriptions}
      />
      <BillingFAQCard
        data={billingFAQ}
      />
      <PurchasePlanModal
        isOpen={purchaseModal}
        monthlyPrice={monthlyPrice}
        annualPrice={annualPrice}
        staffQuantity={staffQuantity}
        planInterval={planInterval}
        setIsOpen={setPurchaseModal}
        setStaffQuantity={setStaffQuantity}
        setPlanInterval={setPlanInterval}
        onSubmit={handleCheckout}
        onReset={handleCheckoutReset}
      />
    </>
  )
}

export default BillingLayout;