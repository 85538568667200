import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const SuccessToast = ({ toastId, message }) => {
  return toast.update(toastId.current, {type: toast.TYPE.SUCCESS, autoClose: true, render: <div className="d-flex flex-row">
  <p className="m-0"> {message} </p></div> });
}

SuccessToast.propTypes = {
  toastId: PropTypes.string,
  message: PropTypes.string
};

export default SuccessToast;
