import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => {

  return (
    <div style={{height: "70vh", position: "relative"}}>
      <Spinner color="primary" style={{position: "absolute", top: "50%", left: "50%"}}/>
    </div>
  );
}

export default Loading;