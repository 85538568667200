import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';

import Homepage from '../pages/Home/Homepage';
import Dashboard from '../pages/Home/Dashboard';

import Settings from '../pages/Settings/Settings';
import Clients from '../pages/Clients/Clients';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import loadable from '@loadable/component';
import SessionService from '../services/SessionService';
import Documents from '../pages/Documents/Documents';
import { Messages } from '../pages/Messages/main/Messages';
import StorageSettings from '../pages/Settings/StorageSettings';
import MyProfile from '../pages/Profile/MyProfile';
import ManageStaff from '../pages/Settings/ManageStaff';
import OrganizationSettings from '../pages/Settings/OrganizationSettings';
import StaffProfile from '../pages/Settings/StaffProfile';
import ClientProfile from '../pages/Clients/ClientProfile';
import BillingLayout from '../pages/Billing/BillingLayout';
import Kanban from '../pages/kanban/Kanban';
import ProtectedRoute from './ProtectedRoute';
import { useSelector } from 'react-redux';
import { isUserLoggedIn } from '../pages/Messages/features/authentication/authenticationModel';
import { Login } from '../pages/Messages/features/authentication/Login';
import amplitude from 'amplitude-js';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const { isFluid } = false;//useContext(AppContext);
  const user = SessionService.getUser()
  const organization = SessionService.getOrganization()
  const loggedIn = useSelector(isUserLoggedIn);
  const amplitudeInstance = amplitude.getInstance();
  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    if (amplitudeInstance && user && organization) {
      amplitudeInstance.setUserId(user.id)
      amplitudeInstance.setGroup('organization id', user.organizationId);
      amplitudeInstance.setUserProperties({
        'plan': organization.plan,
        'role': user.role,
        'organization name': organization.name,
        'subdomain': organization.subdomain
      })
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (!SessionService.isAuth()) {
    return <Redirect to='/authentication/login' />
  }
  
  if (!loggedIn) {
    return <Login/>
  }

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <NavbarVertical />
      <div className="content">
        <NavbarTop />
        <Switch>
          { user.role != 'OWNER' ?
            <ProtectedRoute path="/" allowClient={true} allowStaff={true} exact component={Homepage}/> :
            <ProtectedRoute path="/" exact component={Dashboard}/>
          }
          <ProtectedRoute path="/settings" exact component={Settings}/>
          <ProtectedRoute path="/clients" allowStaff={true} exact component={Clients}/>
          <ProtectedRoute path="/client/:id" allowStaff={true} exact component={ClientProfile}/>
          <ProtectedRoute path="/staff/:id" exact component={StaffProfile}/>
          <ProtectedRoute path="/settings/documents/setup" exact component={StorageSettings}/>
          <ProtectedRoute path="/settings/profile" allowClient={true} allowStaff={true} exact component={MyProfile}/>
          <ProtectedRoute path="/settings/organization" exact component={OrganizationSettings}/>
          <ProtectedRoute path="/settings/staff" exact component={ManageStaff}/>
          <ProtectedRoute path="/settings/billing" exact component={BillingLayout}/>
          <ProtectedRoute path="/kanban" allowClient={true} allowStaff={true} component={Kanban} />
          <ProtectedRoute path="/documents" allowClient={true} allowStaff={true} exact component={Documents}/>
          <ProtectedRoute path="/messages" allowClient={true} allowStaff={true} exact component={Messages}/>
          <DashboardRoutes />
        </Switch>
      </div>
    </div>
  )
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
