import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../context/Context';
import withRedirect from '../../hoc/withRedirect';
import { Card, CardBody, Row, Col } from 'reactstrap';

import { loginWithToken, sendBoVerifyEmail } from '../../api/user.api';
import SessionService from '../../services/SessionService';
import { useParams } from 'react-router-dom';
import logger from '../../utils/logger';

const LoginUsingToken = ({ setRedirect, setRedirectUrl, hasLabel }) => {

  const { setUser } = useContext(AppContext);
  const { logintoken } = useParams();

  const tryToLogin = async e => {
    SessionService.setToken(logintoken);
    const result = await loginWithToken();
    const { user, organization, subdomain } = result.data;
    SessionService.setUser(user);
    SessionService.setOrganization(organization);
    SessionService.setSubdomain(subdomain);
    /* Send verification email to business owner */
    await sendBoVerifyEmail();
    setUser(user);
    setRedirect(true);
  }

  useEffect(() => {
    try {
      tryToLogin();
    } catch (err) {
      logger.warn(err, 'Error in while trying to login using token')
      setRedirect(true);
    }
  }, [setRedirect]);

  useEffect(() => {
    setRedirectUrl(`/`);
  }, [setRedirectUrl]);

  return (
    <Row noGutters style={{ justifyContent: "center" }}>
      <Col lg={6} className="mr-lg-2 mb-3 mb-lg-0">
        <Card className="text-center h-100">
          <CardBody className="p-5">
            <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">Creating your portal! Sit Tight!</p>
            <hr />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

LoginUsingToken.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  hasLabel: PropTypes.bool
};

export default withRedirect(LoginUsingToken);
