import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import useFakeFetch from '../../hooks/useFakeFetch';
import { Link, useParams } from 'react-router-dom';
import rawTimezones from '../../data/event/timezones';
import SessionService from '../../services/SessionService';
import ContactInformationCard from '../../components/clienttable/ContactInformationCard';
import LoginAndPasswordProfileCard from '../../components/clienttable/LoginAndPasswordProfileCard';
import { getMyProfile, edit } from '../../api/user.api';
import { toast } from 'react-toastify';
import NotificationsCard from '../../components/clienttable/NotificationsCard';
import logger from '../../utils/logger';

const MyProfile = () => {
  const { id } =  useParams();
  const { loading: loadingTimezones, data } = useFakeFetch(rawTimezones);
  const timezones = data.map(item => ({ value: item.offset, label: `${item.offset} ${item.name}` }));
  
  const userData = SessionService.getUser()

  const defaultUserData = {
    id: id,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    phone: "",
    street: "",
    unitNumber: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    lastActivity: null,
    notificationPref: 'CLIENT',
    messagingNotifications: true,
  }

  const [user, setUser] = useState(defaultUserData)

  useEffect(() => {
    getUserDetails()
  }, [])

  const getUserDetails = async () => {
    setUser(defaultUserData)
    const result = await getMyProfile()
    setUser(result.data)
  }
  
  const submitChanges = async e => {
    e.preventDefault();
    try {
      await edit(user)
      toast.success(`Changes saved`)
    } catch (err) {
      logger.warn(err, `Error while making changes to own profile for user-id ${user.id}`)
      toast.error(err);
    }
  }
  return (
    <>
      { userData.role == "OWNER" ?
        <Breadcrumb style={{paddingLeft: "1rem"}}>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Profile</BreadcrumbItem>
        </Breadcrumb> : null
      }
      <h3 style={{paddingLeft: "1rem", marginBottom: "15px"}}>My Profile</h3>
      <ContactInformationCard user={user} setUser={setUser}/>
      <NotificationsCard role={userData.role} user={user} setUser={setUser} />
      <LoginAndPasswordProfileCard user={user} setUser={setUser}/>
      <div style={{marginBottom:"20px"}}>
        <Button color={'primary'} size="sm" className="px-4 ml-2" onClick={getUserDetails}>
          Cancel
        </Button>
        <Button color={'primary'} size="sm" className="px-4 ml-2" onClick={submitChanges}>
          Save
        </Button>
      </div>
    </>
  )
}

export default MyProfile;