import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Logo = ({ at, width, className, orgName, orgLogo, ...rest }) => {
  
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        {
          orgLogo ? 
          <img className="mx-2" src={orgLogo} style={{maxWidth:'9rem', maxHeight:'2.8rem'}}/>
          :
          <span className="d-none d-sm-none d-md-none d-lg-block" style={{whiteSpace: 'nowrap', color: 'black', fontWeight: 600}}>{orgName}</span>
        }
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  orgName: PropTypes.string,
  orgLogo: PropTypes.string,
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
