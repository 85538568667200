import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

const StorageConnectionErrorOwner = () => {
  return (
    <Card>
      <CardHeader title="Storage connection error" className="bg-danger">
        <h5 className="text-light text-sans-serif">Storage connection error</h5>
      </CardHeader>
      <CardBody className="mb-0 bg-light">
        <p className="text-sans-serif mb-0">Authorization has been lost to your storage drive. Users will not be able to access files through your portal. <span className="font-weight-bold">Please recconect your drive by signing into your storage account</span></p>
      </CardBody>
    </Card>
  );
}

export default StorageConnectionErrorOwner;
