import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const CompleteSetup = ({ organization, confirm, cancel }) => {
  return (
    <div>
      <Button
        color={'primary'}
        size="sm"
        className="px-4 m-2"
        onClick={cancel}
        >
        Cancel
      </Button>
      <Button
        color={'primary'}
        size="sm"
        className="px-4 m-2"
        onClick={confirm}
        disabled={!organization.temp_provider}
        >
        Confirm Setup
      </Button>
    </div>
  )
}

CompleteSetup.propTypes = {
  organization: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};

export default CompleteSetup;
