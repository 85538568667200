import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { getMenuView } from "../../../features/layout/Selectors";
import { MyConversations } from "../../joinedConversations/MyConversations/MyConversations";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "../../../foundations/hooks/useMediaQuery";
import {
  Drawer,
  StyledBox,
} from "../../../foundations/components/layout";

import { MenuHeader } from "./MenuHeader";

const Menu = ({chatProfileList}) => {
  const viewMenu = useSelector(getMenuView);
  const theme = useContext(ThemeContext) as any;
  const isMedium = useMediaQuery(theme.mediaQueries.medium);

  const [searchValue, setSearchValue ] = useState('');

  return (
    <Drawer
      open={viewMenu || isMedium}
      style={{padding:"0"}}
      wide
    >
      <StyledBox
        position="absolute"
        right="0"
        padding="6"
        display={["block", "none"]}
      >
      </StyledBox>

      <MenuHeader onSearch={setSearchValue}/>
      <MyConversations chatProfileList={chatProfileList} searchValue={searchValue}/>
    </Drawer>
  );
};

export { Menu };
