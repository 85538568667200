import React from 'react';
import { Link } from 'react-router-dom';

import cloudServerOutlined from '@iconify/icons-ant-design/cloud-server-outlined';
import idCardOutlined from '@iconify/icons-ant-design/idcard-outlined';
import userAddOutlined from '@iconify/icons-ant-design/user-add-outlined';
import folderAddOutlined from '@iconify/icons-ant-design/folder-add-outlined';

export default [
  {
    icon: cloudServerOutlined,
    title: `Setup your storage`,
    description: <>
      Files are stored directly in your choice of cloud storage including Google Drive, One Drive, or Dropbox.
      <br/> Once your drive is connected, you may share files with your clients and organization.
      <Link to={'/settings/documents/setup'} className="fs-0"> Go to storage settings </Link>
    </>,
  },
  { 
    icon: idCardOutlined,
    title: `Update your profile`,
    description: <>
      To update your personal information <Link to={'/settings/profile'} className="fs-0">go to My Profile</Link>.
      <br/>  To update your organization’s information  <Link to={'/settings/organization'} className="fs-0">go to Organization Settings</Link>. 
      <br/> Here, you can add your own logo and enable two-factor authentication for increased security.
    </>,
  },
  {
    icon: userAddOutlined,
    title: `Add staff`,
    description: <>
      Invite staff members (internal users) to your organization.
      <br/> Staff can add client users and view all documents. <Link to={'/settings/staff'} className="fs-0">Go to Manage Staff</Link>
    </>,
  },
  {
    icon: userAddOutlined,
    title: `Add clients`,
    description: <>
      Invite your clients who are sure to be impressed by your new portal! <Link to={'/clients'} className="fs-0">Go to Clients</Link>
      <br/> Once you create a client you can share files and folders on the <Link to={'/documents'} className="fs-0">Documents page</Link>
    </>,
  },
  {
    icon: folderAddOutlined,
    title: `Share folders`,
    description: <>
      Clients cannot upload files without at least one folder shared with them.
      <br/> Add and share a folder on the <Link to={'/documents'} className="fs-0">Browse Documents page.</Link>
    </>,
  }
];
