import { LayoutActions } from "./LayoutActions";
import { LayoutActionType } from "./LayoutActionType";

export enum View {
  Menu,
  CurrentConversationHeader,
  CurrentConversation,
  Compose,
  NewChannel,
  EditChannel,
  ConversationMembers,
  ConversationTasks,
  JoinConversation,
  AddMembers,
  EditTask,
  LeaveConfirmation,
}

interface LayoutState {
  views: View[];
}

const initialState: LayoutState = {
  views: [View.CurrentConversation]
};

const viewDisplayed = (state: LayoutState, view: View): LayoutState => {
  return { ...state, views: [...state.views, view] };
};

const viewHidden = (state: LayoutState, view: View): LayoutState => {
  return { ...state, views: state.views.filter(item => item !== view) };
};

const LayoutStateReducer = (
  state: LayoutState = initialState,
  action: LayoutActions
): LayoutState => {
  switch (action.type) {
    case LayoutActionType.MENU_VIEW_DISPLAYED:
      return viewDisplayed(state, View.Menu);
    case LayoutActionType.MENU_VIEW_HIDDEN:
      return viewHidden(state, View.Menu);
    case LayoutActionType.CURRENT_CONVERSATION_HEADER_VIEW_DISPLAYED:
      return viewDisplayed(state, View.CurrentConversationHeader);
    case LayoutActionType.CURRENT_CONVERSATION_HEADER_VIEW_HIDDEN:
      return viewHidden(state, View.CurrentConversationHeader);
    case LayoutActionType.CURRENT_CONVERSATION_VIEW_DISPLAYED:
      return viewDisplayed(state, View.CurrentConversation);
    case LayoutActionType.CURRENT_CONVERSATION_VIEW_HIDDEN:
      return viewHidden(state, View.CurrentConversation);
    case LayoutActionType.COMPOSE_VIEW_DISPLAYED:
      return viewDisplayed(state, View.Compose);
    case LayoutActionType.COMPOSE_VIEW_HIDDEN:
      return viewHidden(state, View.Compose)
    case LayoutActionType.NEW_CHANNEL_VIEW_DISPLAYED:
      return viewDisplayed(state, View.NewChannel);
    case LayoutActionType.NEW_CHANNEL_VIEW_HIDDEN:
      return viewHidden(state, View.NewChannel);
    case LayoutActionType.EDIT_CHANNEL_VIEW_DISPLAYED:
      return viewDisplayed(state, View.EditChannel);
    case LayoutActionType.EDIT_CHANNEL_VIEW_HIDDEN:
      return viewHidden(state, View.EditChannel);
    case LayoutActionType.CONVERSATION_MEMBERS_VIEW_DISPLAYED:
      return viewDisplayed(state, View.ConversationMembers);
    case LayoutActionType.CONVERSATION_MEMBERS_VIEW_HIDDEN:
      return viewHidden(state, View.ConversationMembers);
    case LayoutActionType.CONVERSATION_TASKS_VIEW_DISPLAYED:
      return viewDisplayed(state, View.ConversationTasks);
    case LayoutActionType.CONVERSATION_TASKS_VIEW_HIDDEN:
      return viewHidden(state, View.ConversationTasks);
    case LayoutActionType.JOIN_CONVERSATION_VIEW_DISPLAYED:
      return viewDisplayed(state, View.JoinConversation);
    case LayoutActionType.JOIN_CONVERSATION_VIEW_HIDDEN:
      return viewHidden(state, View.JoinConversation);
    case LayoutActionType.ADD_MEMBERS_VIEW_DISPLAYED:
      return viewDisplayed(state, View.AddMembers);
    case LayoutActionType.EDIT_TASK_VIEW_DISPLAYED:
      return viewDisplayed(state, View.EditTask);
    case LayoutActionType.ADD_MEMBERS_VIEW_HIDDEN:
      return viewHidden(state, View.AddMembers);
    case LayoutActionType.EDIT_TASK_VIEW_HIDDEN:
      return viewHidden(state, View.EditTask);
    case LayoutActionType.LEAVE_CONFIRMATION_VIEW_DISPLAYED:
      return viewDisplayed(state, View.LeaveConfirmation);
    case LayoutActionType.LEAVE_CONFIRMATION_VIEW_HIDDEN:
      return viewHidden(state, View.LeaveConfirmation);
  }
  return state;
};

export { LayoutStateReducer };
