import { AppState } from "../../main/storeTypes";
import { AppActions } from "../../main/AppActions";
import { createSelector } from "reselect";

import { ConversationsIndexedById, getConversationsById } from "../conversations/conversationModel";

export const FOCUS_ON_CONVERSATION = "FOCUS_ON_CONVERSATION";

export interface focusOnConversationAction {
  type: typeof FOCUS_ON_CONVERSATION;
  payload: string;
}

export interface CurrentConversationState {
  currentConversationId: string;
}

export interface ConversationFragment {
  id: string;
  name: string;
  description: string;
  custom: {
    chat: boolean;
  }
}

const initialState: CurrentConversationState = {
  currentConversationId: "",
};

export const focusOnConversation = (
  target: string
): focusOnConversationAction => {
  return {
    type: FOCUS_ON_CONVERSATION,
    payload: target
  };
};

const currentConversationStateReducer = (
  state: CurrentConversationState = initialState,
  action: AppActions
): CurrentConversationState => {
  switch (action.type) {
    case FOCUS_ON_CONVERSATION:
      return { ...state, currentConversationId: action.payload };
    default:
      return state;
  }
};


const getCurrentConversationSlice = (state: AppState) =>
  state.currentConversation;

export const getCurrentConversationId = createSelector(
  [getCurrentConversationSlice],
  (currentConversation: CurrentConversationState): string => {
    return currentConversation.currentConversationId;
  }
);

export const getCurrentConversation = createSelector(
  [getConversationsById, getCurrentConversationId],
  (
    conversations: ConversationsIndexedById,
    currentConversationId: string
  ): ConversationFragment => {
    return {
      ...conversations[currentConversationId]
    };
  }
);

export { currentConversationStateReducer };