import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import { getLeaveConfirmationView } from "../../layout/Selectors";
import {
  leaveConfirmationViewHidden,
  conversationMembersViewHidden,
  composeViewDisplayed
} from "../../layout/LayoutActions";
import {
  getCurrentConversationId,
  getCurrentConversation,
  ConversationFragment,
} from "../../currentConversation/currentConversationModel";
import { getLoggedInUserId } from "../../../features/authentication/authenticationModel";
import { removeMember } from "../ConversationMembers";

const LeaveConfirmationModal = () => {
  const viewLeaveConfirmation: any = useSelector(getLeaveConfirmationView);
  const dispatch = useDispatch();
  const conversationId: string = useSelector(getCurrentConversationId);
  const conversation: ConversationFragment = useSelector(getCurrentConversation);
  const myUserId = useSelector(getLoggedInUserId);

  return (
    <Modal isOpen={viewLeaveConfirmation}>
      <ModalHeader>
        Are you sure you want to leave?
      </ModalHeader>
      <ModalBody>
        You will be removed from <b>{conversation && conversation.name}</b>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => { dispatch(leaveConfirmationViewHidden()) }}>Cancel</Button>
        <Button
          color="primary"
          onClick={() => {
            dispatch(composeViewDisplayed());
            dispatch(conversationMembersViewHidden());
            dispatch(removeMember(myUserId, conversationId));
            dispatch(leaveConfirmationViewHidden());
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { LeaveConfirmationModal }