import React from "react";
import invariant from "invariant";
import { TextMessageDisplay } from "../../../features/messages/TextMessageDisplay";
import { GiphyMessageDisplay } from "../../../features/messages/GiphyMessageDisplay";
import { MessageType, AppMessage } from "../../../features/messages/messageModel";
import { BaseMessage, TextMessage } from "../../../sharedTypes/messageModel";

type MessageProps = {
  message: AppMessage;
  parentKey: string;
  direction: "left" | "right";
};

/**
 * Display a Message based on its type
 */
export const MessageDisplay = ({ message, parentKey, direction }: MessageProps) : JSX.Element => {
  switch (message.type) {
    case MessageType.Text:
      return <TextMessageDisplay message={message} parentKey={parentKey} direction={direction}/>;

    case MessageType.Giphy:
      return <GiphyMessageDisplay message={message} />;

    // <== Add additional message types here.

    // Don't show anything for an unrecognized message type
    default:
      invariant(
        false,
        `No component available for displaying message of type "${
          (message as BaseMessage).type
        }"`
      );
      return <div></div>
  }
};
