import React from 'react';
import { Input, Button } from 'reactstrap';
import editOutlined from '@iconify/icons-fa-regular/edit';
import { Icon } from "@iconify/react";
import { FlexRow, FlexColumn } from "../../../foundations/components/layout";
import { useDispatch } from 'react-redux';
import {
  composeViewDisplayed,
  currentConversationHeaderViewHidden,
  currentConversationViewHidden,
  conversationMembersViewHidden,
  addMembersViewHidden,
  menuViewHidden,
} from '../../layout/LayoutActions';

const MenuHeader = ({onSearch}) => {

  const dispatch = useDispatch();
  const search = (e) => {
    e.preventDefault();
    onSearch(e.target.value);
  }

  return (
    <FlexColumn px={2} py={2} className="border">
      <FlexRow className="align-items-start" mb={2}>
        <h5 className="mr-auto mb-0">Messages</h5>
        <Button
          color="primary"
          size="md"
          onClick={async () => {
            await dispatch(composeViewDisplayed())
            await dispatch(currentConversationHeaderViewHidden())
            await dispatch(currentConversationViewHidden())
            await dispatch(conversationMembersViewHidden())
            await dispatch(addMembersViewHidden())
            await dispatch(menuViewHidden());
          }}
        >
          Contact list
        </Button>
      </FlexRow>
      <Input className="rounded-pill search-input mr-2" style={{width:"100%"}} type="search" placeholder="Search..." aria-label="Search" onChange={search}/>
    </FlexColumn>
  );
}

export { MenuHeader }