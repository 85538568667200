
/* From constants/organizations.js file */
const TFA_SETTING = {
    ALL: 2,
    CLIENTS: 1,
    INTERNAL: 0, /* Represents staff and owner */
    NONE: -1,
}


export {
    TFA_SETTING,
};