import React from 'react';
import { CardBody, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withCentered from '../../hoc/withCentered';
import { baseURL, prefixURL } from '../../config';

const Domain404 = () => (
  <Card className="text-center">
    <CardBody className="p-5">
      <div className="display-1 text-200 fs-error">404</div>
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
        The domain you're looking for is not found.
      </p>
      <hr />
      <p>
        Make sure the address is correct, or you can register for a new account. If you think this is a mistake,
        <a href="mailto:support@clienttable.com" className="ml-1">contact us</a>.
      </p>
      <a className="btn btn-primary btn-sm mt-3" href="https://www.ctbl.app/authentication/register">        
        <FontAwesomeIcon icon="home" className="mr-2" />Register
      </a>
    </CardBody>
  </Card>
);

export default withCentered(Domain404);
