/**
 * Created by ali on 8/16/20.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

const DeleteUserModal = ({isOpen, onDelete, onClose}) => {

  const onDeleteClick = e => {
    e.preventDefault();
    onDelete();
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={onClose}>
      <ModalHeader>Confirm Delete</ModalHeader>
      <ModalBody>
        <div>
          <Col>
            <Row>
              <p>Are you sure you would like to delete the selected user(s)? <strong>This action cannot be reversed.</strong></p>
            </Row>
            <Row><Button color="falcon-danger" className="mb-2" onClick={onDeleteClick} size="lg" block>Delete</Button></Row>
            <Row><Button color="falcon-primary" onClick={onClose} size="lg" block>Cancel</Button></Row>
          </Col>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  user: PropTypes.object
};

export default DeleteUserModal;



