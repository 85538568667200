import dropboxsvgcolour from '../../images/dropbox-colour.svg';
import dropboxsvggrey from '../../images/dropbox-grey.svg';
import gdrivesvgcolour from '../../images/gdrive-colour.svg';
import gdrivesvggrey from '../../images/gdrive-grey.svg';
import onedrivesvgcolour from '../../images/OneDrive-colour.svg';
import onedrivesvggrey from '../../images/OneDrive-grey.svg';

export const providers = [ // scope is being used for multiple purposes
  {name: "OneDrive", unselectedIcon: onedrivesvggrey, selectedIcon: onedrivesvgcolour, key: 'onedrive', scope: 'onedrive', selectedColor: "#2C7BE5" },
  {name: "Google Drive", unselectedIcon: gdrivesvggrey, selectedIcon: gdrivesvgcolour, key: 'gdrive', scope: 'gdrive' },
  {name: "Dropbox", unselectedIcon: dropboxsvggrey, selectedIcon: dropboxsvgcolour, key: 'dropbox', scope: 'dropbox' },
]

export const getProvider = (scope) => {
  return providers.find(provider => provider.scope == scope) 
}

export const checkIfDriveActive = () => {

}

export const RECONNECT_DIFFERENT_EMAIL = 'error-different-account'

export const RECONNECT_SUCCESSFUL = 'successful'