import apiHandler from './apiHandler';

export const register = (user) => {
  return apiHandler.post('/user/register', user);
}
export const verifyEmail = (code) => {
  return apiHandler.post('/user/verify', code)
}

export const getRegistrationData = (code) => {
  return apiHandler.post('/user/registrationData', code)
}

export const login = (user) => {
    return apiHandler.post('/user/login', user);
}

export const loginWithToken = (token) => {
  return apiHandler.post('/user/logintoken', token);
}

export const registerNewUserAndLogin = (user) => {
    return apiHandler.post('/user/registerNewUserAndLogin', user)
}
export const getAll = (params) => {
  return apiHandler.get('/user', { params });
}

export const get = (id) => { // TODO change to getUser
  return apiHandler.get(`/user/${id}`);
}

export const getMyProfile = () => { // TODO change to getUser
  return apiHandler.get(`/user/myProfile`);
}

export const getAllForRole = (role, params) => {
  return apiHandler.get(`/user/role/${role}`, { params });
}

export const getAllStaff = (params) => {
  return apiHandler.get(`/user/staff`, { params });
}

export const getAllClient = (params) => {
  return apiHandler.get(`/user/clients`, { params });
}

export const invite = (user) => {
  return apiHandler.post('/user/invite', user);
}

export const createUser = (user) => {
  return apiHandler.post('/user', user)
}

export const createStaff = (data) => {
  return apiHandler.post('/user/staff', data);
}

export const createClient = (data) => {
  return apiHandler.post('/user/clients', data);
}

export const edit = (user) => { //TODO edit user
  return apiHandler.post(`/user/${user.id}`, user)
}

export const updateLastNotifiedTime = (id, lastNotifiedTime) => {
  return apiHandler.post(`/user/update/lastNotifiedTime`, {id, lastNotifiedTime})
}

export const updateLastMessageReceivedTime = (id, lastMessageReceivedTime) => {
  return apiHandler.post(`/user/update/lastMessageReceivedTime`, {id, lastMessageReceivedTime})
}

export const sendSubDomains = (email) => {
  return apiHandler.post(`/user/forget/subdomains`, { email })
}

export const deleteUser = (id) => {
  return apiHandler.delete(`/user?id=${id}`);
}

export const deleteManyUsers = (ids) => {
  return apiHandler.delete(`/user?ids=${ids}`);
}

export const forgot = (email) => {
  return apiHandler.post(`/user/forgot/password`, { email });
}

export const resetClientPassword = (email) => {
  return apiHandler.post(`/user/reset/client/password`, { email });
}

// Sends change email request
export const changeEmail = (email) => {
  return apiHandler.post(`/user/change/email`, { email });
}

export const changePassword = (oldPassword, password) => {
  return apiHandler.post(`/user/change/password`, { oldPassword, password });
}

export const updateEmail = (id, password, email) => {
  return apiHandler.post(`/user/update/email`, { id, password, email});
}

export const getInviteLink = (id) => {
  return apiHandler.post(`/user/invite/link`, { id });
}

export const sendInvitationCode = (id) => {
  return apiHandler.post(`/user/invite/send`, { id })
}

export const resendInviteLink = (email) => {
  return apiHandler.post(`/user/invite/resend`, { email });
}

// Takes invitationCode
export const getInvitationData = () => {
  return apiHandler.get(`/user/invitation/data`);
}

// Consumes invitationCode
export const confirmInvitation = (password) => {
  return apiHandler.post(`/user/invitation`, { password });
}

// Takes passwordResetCode
export const getPasswordResetData = () => {
  return apiHandler.get('/user/passwordReset/data');
}

// Consumes passwordResetCode
export const resetPassword = (password) => {
  return apiHandler.post(`/user/reset/password`, { password });
}

export const validatePassword = (password) => {
  return apiHandler.post(`/user/validate/password`, password);
}

export const checkActiveSession = () => {
  return apiHandler.get(`/user/validate/session`);
}

// Consumes tfaCode
export const send2fa = () => {
  return apiHandler.post('/user/send2fa');
}

export const verify2fa = (code) => {
  return apiHandler.post('/user/verify2fa', {code});
}

export const checkActive2FASession = () => {
  return apiHandler.get(`/user/validate/2faSession`);
}

export const checkBoEmailVerified = () => {
  return apiHandler.get(`/user/checkBoEmailVerified`);
}

export const sendBoVerifyEmail = () => {
  return apiHandler.post(`/user/sendBoVerifyEmail`);
}

export const verifyBoEmail = (code) => {
  return apiHandler.post(`/user/verifyBoEmail`);
}

export const updateBoEmail = (email) => {
  return apiHandler.post(`/user/update/boemail`, {email});
}

export const updateCustomFieldsValues = (id, userJSON) => {
  return apiHandler.post(`/user/customFieldsValues`, {id, userJSON});
}

export const findChatProfiles = () => {
  return apiHandler.get(`/user/chat/profiles`);
}
