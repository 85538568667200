import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

const QuantityController = ({ value, setValue, minValue, maxValue, disabled, ...rest }) => {
  const middleStyle = "text-center px-2 input-spin-none " + (disabled ? "mx-auto" : "mx-0");
  return (
    <InputGroup size="sm" {...rest}>
      <InputGroupAddon addonType="prepend" hidden={disabled} className="ml-auto">
        <Button
          color="outline-secondary"
          size="sm"
          className="border-300 px-2"
          onClick={() => setValue(value > minValue ? value - 1 : value)}
        >
          -
      </Button>
      </InputGroupAddon>
      <Input
        className={middleStyle}
        value={value}
        onChange={({ target }) => setValue(parseInt(target.value))}
        type="number"
        min={minValue}
        style={{ maxWidth: '40px' }}
        disabled={true}
      />
      <InputGroupAddon addonType="append" hidden={disabled} className="mr-auto">
        <Button
          color="outline-secondary"
          size="sm"
          className="border-300 px-2"
          onClick={() => setValue(value < maxValue ? value + 1 : value)}
        >
          +
      </Button>
      </InputGroupAddon>
    </InputGroup>
  )
};

QuantityController.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.number
};

QuantityController.defaultProps = { value: 1 };

export default QuantityController;
