import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from "reactstrap";
import { Icon } from '@iconify/react';

import editFilled from '@iconify/icons-ant-design/edit-filled';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import settingFilled from '@iconify/icons-ant-design/setting-filled';
import administratorSolid from '@iconify/icons-clarity/administrator-solid';
import userDeleteOutlined from '@iconify/icons-ant-design/user-delete-outlined';
import rollbackOutlined from '@iconify/icons-ant-design/rollback-outlined';

const shareLabels = {
  VIEW: {
    icon: eyeFilled,
    label: 'View only',
  },
  EDIT: {
    icon: editFilled,
    label: 'Edit',
  },
  DELETE: {
    icon: settingFilled,
    label: 'Edit and delete',
  },
  ADMIN: {
    icon: administratorSolid,
    label: 'Edit, delete, share'
  },
  REMOVE: {
    icon: (group) => group ? rollbackOutlined : userDeleteOutlined,
    label: (group) => group ? `Reset to inherited (${shareLabels[group].label})` : "Remove",
    className: (group) => group ? "text-primary" : "text-danger"
  }
}

const ShareDropdown = ({ userId, selected, inheritGroup, canAdmin, canRemove, disabled, isOpen, toggle, onClick, ...rest }) => {
  const [buttonIcon, setButtonIcon] = useState(shareLabels[selected].icon);
  const [tooltip, setTooltip] = useState(shareLabels[selected].label);
  const [showTooltip, setShowTooltip] = useState(false);


  const ShareDropdownItem = ({ group, icon, label, ...rest }) => (
    <DropdownItem
      key={`dropdown-item-${group}`}
      onClick={() => {
        onClick(userId, group)
        setButtonIcon(icon)
        setTooltip(label)
      }}
      {...rest}
    >
      <Icon icon={icon} width="20" height="20" />
      <span className="mb-0 ml-2">{label}</span>
    </DropdownItem>
  )

  return (
    <ButtonDropdown
      isOpen={isOpen}
      toggle={toggle}
      disabled={disabled}
      {...rest}
    >
      <Tooltip
        placement="right"
        isOpen={showTooltip}
        target={`tooltip-${userId}`}
        toggle={() => setShowTooltip((value) => !value)}
      >
        {tooltip}
      </Tooltip>
      <DropdownToggle id={`tooltip-${userId}`} caret={!disabled} className="btn btn-falcon-default">
        <Icon icon={buttonIcon} className="mr-2" width="20" height="20" />
      </DropdownToggle>
      <DropdownMenu>
        <ShareDropdownItem group="VIEW" {...shareLabels['VIEW']} />
        <ShareDropdownItem group="EDIT" {...shareLabels['EDIT']} />
        <ShareDropdownItem group="DELETE" {...shareLabels['DELETE']} />
        {canAdmin && <ShareDropdownItem group="ADMIN" {...shareLabels['ADMIN']} /> }
        {canRemove && <>
          <DropdownItem divider />
          <ShareDropdownItem
            group="REMOVE"
            icon={shareLabels['REMOVE'].icon(inheritGroup)}
            label={shareLabels['REMOVE'].label(inheritGroup)}
            className={shareLabels['REMOVE'].className(inheritGroup)}
          />
        </>}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default ShareDropdown