import React from "react";
import Accordion from '../../common/accordion/Accordion';

/**
 * Generates an accordion list, given an array of json objects, each containing
 * a title and a description
 */
const AccordionList = ({ data, numbered, ...rest }) => {
  return (
    <>
      {data.map((step, index) =>
        <Accordion
          title={numbered ? `${index+1}. ${step.title}` : `${step.title}`}
          description={step.description}
        />
      )}
    </>
  );
}

export default AccordionList;