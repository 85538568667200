import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Collapse, Nav, Navbar } from 'reactstrap';
import Scrollbar from 'react-scrollbars-custom';
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import AppContext from '../../context/Context';
import Flex from '../clienttable/Flex';
import getRoutes from '../../routes';
import { navbarBreakPoint } from '../../config';
import logoClientTable from '../../assets/img/icons/logo-client-table.svg';
import SessionService from '../../services/SessionService';

const NavbarVertical = () => {
  const navBarRef = useRef(null);
  const organization = SessionService.getOrganization()
  const { user, isRTL, showBurgerMenu, isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed } = useContext(AppContext);

  const [routes, setRoutes] = useState({ top: [], bottom: [] })

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  useEffect(() => {
    setRoutes(getRoutes())
  }, [user]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  return (
    <Navbar expand={navbarBreakPoint} className="navbar-vertical navbar-glass" light>
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" orgName={organization.name} orgLogo={organization.logo} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="bg-200 ctbl-navbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
      >
        <Scrollbar
          style={{ height: 'calc(100vh - 77px)', display: 'block' }}
          rtl={isRTL}
          trackYProps={{
            renderer(props) {
              const { elementRef, ...restProps } = props;
              return <span {...restProps} ref={elementRef} className="TrackY" />;
            }
          }}
        >
          <div>
            <Nav navbar vertical className="md-auto">
              <NavbarVerticalMenu routes={routes.top} isCollapsed={isNavbarVerticalCollapsed} />
            </Nav>
          </div>
          <div>
            <Nav navbar vertical>
              <NavbarVerticalMenu routes={routes.bottom} isCollapsed={isNavbarVerticalCollapsed}/>
            </Nav>

            {isNavbarVerticalCollapsed ? null :
              <>
                {organization.plan === "FREE" &&
                  <Nav navbar vertical id='poweredId' className="mt-5">
                    <div className="d-flex flex-column w-75 pl-2 pr-2 mt-5 align-items-center">
                      <div>
                        <a className="text-dark fs--2 font-weight-semi-bold" href="https://www.clienttable.com/">Powered by</a>
                      </div>
                      <Button
                        color="border-0 rounded-0 text-dark d-flex flex-column align-items-center w-50"
                      >
                        <a href="https://www.clienttable.com/">
                          <img src={logoClientTable} />
                        </a>
                      </Button>
                    </div>
                  </Nav>
                }
                {/* <UncontrolledTooltip
                placement="bottom-start"
                target="poweredId"
                innerClassName="bg-300 text-dark fs--1"
                arrowClassName="text-100"
              >
                <span>Upgrade to Professional to remove branding</span>
              </UncontrolledTooltip> */}
              </>
            }
          </div>
        </Scrollbar>
      </Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
