import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Button, Card, CardBody, Input, InputGroupText, InputGroupAddon, InputGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import FalconCardHeader from '../FalconCardHeader';
import Icon from '@iconify/react';
import editOutlined from '@iconify/icons-ant-design/edit-outlined';
import CodeHighlight from '../../common/CodeHighlight';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ChangeOrganizationModal from './ChangeOrganizationModal';
import { Col, Row } from 'reactstrap';
import copyOutlined from "@iconify-icons/ant-design/copy-outlined";

const WebAdressAndUploadCard = ({ organization }) => {
  const [showChangeOrgModal, setShowChangeOrgModal] = useState(false);

  const onLinkCopy = () => {
    toast.success(`Successfully copied link to your clipboard`);
  }

  const codeSnippetText = `<div> <div class="ct-filedrop"></div> <!-- Start code for filedrop widget --> <script type="text/javascript" src="https://${organization.subdomain}.ctbl.app/${organization.id}/filedrop/filedrop.js" async="" defer=""></script> <!-- End code for filedrop widget --> </div>`
  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Web Address and Upload Widget" light={false} />
        <CardBody className="bg-light">
          <Row>
            <Col>
              <Label>Protal URL</Label>
              <InputGroup>
                <Input type="text" name="readonly" id="readonly" placeholder={organization.subdomain} disabled />
                <InputGroupAddon addonType="append">
                  <InputGroupText>.ctbl.app</InputGroupText>
                </InputGroupAddon>
                <Icon style={{ color: '#2C7BE5', width: "2rem", height: "2rem" }} icon={editOutlined} onClick={() => setShowChangeOrgModal(!showChangeOrgModal)} />
              </InputGroup>
            </Col>
            <Col></Col>
          </Row>
          <p className="mt-3">Your organization id number : {organization.id}</p>

          <h5 className="my-4">File-Upload widget</h5>

          <Label>Embed Code</Label>
          <CodeHighlight code={codeSnippetText} isDark={true}  
            language="html"/>
            <CopyToClipboard text={codeSnippetText} onCopy={onLinkCopy} className="mt-2">
            <Button color="falcon-default" size="sm" >
            <Icon icon={copyOutlined} height="20px" width="20px" /> Copy
            </Button>
          </CopyToClipboard>
        </CardBody>
      </Card>

      <ChangeOrganizationModal showChangeOrgModal={showChangeOrgModal} setShowChangeOrgModal={setShowChangeOrgModal}/>
    </>
  )
}

WebAdressAndUploadCard.protoTypes = {
  organization: PropTypes.object.isRequired,
}

export default WebAdressAndUploadCard;