import apiHandler from "./apiHandler";

export const get = (organizationId) => {
  return apiHandler.get(`/organization/${organizationId}`);
};

export const edit = (organizationId, data) => {
  return apiHandler.put(`/organization/${organizationId}`, data);
};

export const transfer = (organizationId, ownerId) => {
  return apiHandler.post(`/organization/${organizationId}/transfer`, { ownerId });
};

export const changeSubdomain = (organizationId, subdomain) => {
  return apiHandler.post(`/organization/${organizationId}/subdomain`, { subdomain });
};

export const updateSubdomain = (subdomain, password) => {
  return apiHandler.post(`/organization/subdomain`, { subdomain, password });
}

export const deleteSubdomain = (organizationId, password, orgName) => {
  return apiHandler.post(`/organization/${organizationId}/delete/subdomain`, { password, orgName });
};

export const updateLogo = (organizationId, logo) => {
  return apiHandler.post(`/organization/${organizationId}/updateLogo`, { logo });
};

export const deleteLogo = (organizationId) => {
  return apiHandler.delete(`/organization/${organizationId}/logo`);
};

export const getBrandingBySubdomain = (subdomain) => {
  return apiHandler.get(`/organization/branding?subdomain=${subdomain}`);
};

export const getBrandingById = (id) => {
  return apiHandler.get(`/organization/branding?id=${id}`);
};

export const checkIfSubdomainExists = (subdomain) => {
  return apiHandler.get(`/organization/checkIfSubdomainExists/${subdomain}`);
};

// Set organization's temporary provider to the permanent provider
export const saveTempProvider = () => {
  return apiHandler.post(`/organization/provider`);
}

// Delete temporary provider information
export const deleteTempProvider = () => {
  return apiHandler.delete(`/organization/provider/temp`);
}

export const updateCustomFields = (updatedFields, deletedFields, newOrder) => {
  return apiHandler.post(`/organization/customFields`, {updatedFields, deletedFields, newOrder});
}
