import React, { useEffect, useState } from "react";
import {
  BreadcrumbItem,
  Breadcrumb,
  Form,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import SessionService from "../../services/SessionService";
import { edit, get } from "../../api/organization.api";
import OrganizationInformationCard from "../../components/clienttable/OrganizationInformationCard";
import { toast } from "react-toastify";
import WebAdressAndUploadCard from "../../components/clienttable/WebAdressAndUploadCard/WebAdressAndUploadCard.js";
import ShowSetupChecklistCard from "../../components/clienttable/ShowSetupChecklistCard";
import LogoFileUploadCard from "../../components/clienttable/LogoFileUploadCard";
import DeleteOrganizationCard from "../../components/clienttable/DeleteOrganizationCard";
import TwoFactorAuthenticationCard from "../../components/clienttable/TwoFactorAuthenticationCard";
// import FalconEditor from '../../../FalconTemplate/Falcon UI React v2.5/src/components/common/FalconEditor';

const OrganizationSettings = () => {
  const organizationData = SessionService.getOrganization();

  const [organization, setOrganization] = useState({
    id: organizationData.id,
    name: "",
    phone: "",
    email: "",
    street: "",
    unitNumber: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    subdomain: "",
    lastActivity: null,
    logo: organizationData.logo,
    logoId: organizationData.logoId,
    tfaType: null,
  });

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const result = await get(organizationData.id);
    setOrganization(result.data);
  };

  const submitChanges = async (e) => {
    e.preventDefault();
    try {
      const oldOrgData = SessionService.getOrganization();
      await edit(organization.id, {
        ...organization,
        logo: oldOrgData.logo,
        logoId: oldOrgData.logoId
      });
      toast.success(`Successfully edited organization`);
      SessionService.setOrganization({
        ...organization, 
        logo: oldOrgData.logo, 
        logoId: oldOrgData.logoId
      })
      window.location.reload(false);
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <>
      <Breadcrumb style={{ paddingLeft: "1rem" }}>
        <BreadcrumbItem>
          <Link to='/'>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Organization</BreadcrumbItem>
      </Breadcrumb>
      <h3 style={{ paddingLeft: "1rem" }}>Organization</h3>
      <Form onSubmit={submitChanges}>
        <OrganizationInformationCard
          organization={organization}
          setOrganization={setOrganization}
          getUserDetails={getUserDetails}
        />
      </Form>
      <LogoFileUploadCard organization={organization} />
      <TwoFactorAuthenticationCard organization={organization} setOrganization={setOrganization} />
      <WebAdressAndUploadCard organization={organization} />
      <ShowSetupChecklistCard />
      <DeleteOrganizationCard organization={organization} />
    </>
  );
};

export default OrganizationSettings;
