import React from 'react';
import PropTypes from 'prop-types'
import { Card, CardBody, Form, Row, Col, FormGroup, Label, Input, CustomInput, FormText } from 'reactstrap';
import FalconCardHeader from './FalconCardHeader';
import useFakeFetch from '../../hooks/useFakeFetch';
import rawTimezones from '../../data/event/timezones';
import { toast } from 'react-toastify';

const ContactInformationCard = ({user, setUser}) => {
  const { loading: loadingTimezones, data } = useFakeFetch(rawTimezones);
  const timezones = data.map(item => ({ value: item.offset, label: `${item.offset} ${item.name}` }));
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Client Information" light={false} />
      <CardBody className="bg-light">
        <p>Your contact information is displayed to all users who view your profile</p>
        <Form>
          <Row>
            <Col md>
              <FormGroup>
                <Label for="exampleName">First Name*</Label>
                <Input 
                  type="text" 
                  name="name" 
                  id="exampleName" 
                  value={user.firstName} 
                  onChange={({ target }) => setUser({...user, "firstName" :target.value})}
                  required
                  minLength="1"
                  maxLength="20"
                />
                <FormText>Required field</FormText>
              </FormGroup>
            </Col>
            <Col md>
              <FormGroup>
                <Label for="exampleName">Last Name</Label>
                <Input 
                  type="text" 
                  name="name" 
                  id="exampleName" 
                  value={user.lastName} 
                  onChange={({ target }) => setUser({...user, "lastName" :target.value})}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md>
              <FormGroup>
                  <Label for="exampleName">Organization</Label>
                  <Input type="text" name="name" id="exampleName"  value={user.group} onChange={({ target }) => setUser({...user, "group" :target.value})}/>
                </FormGroup>
            </Col>
            <Col md>
              <FormGroup>
                <Label for="exampleName">Phone number</Label>
                <Input type="text" name="name" id="exampleName" value={user.phone} onChange={({ target }) => setUser({...user, "phone" :target.value})}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md>
              <FormGroup>
                <Label for="exampleName">Street</Label>
                <Input type="text" name="name" id="exampleName"  value={user.street} onChange={({ target }) => setUser({...user, "street" :target.value})}/>
              </FormGroup>
            </Col>
            <Col md>
              <FormGroup>
                <Label for="exampleName">Appartment, suite, etc</Label>
                <Input type="text" name="name" id="exampleName"  value={user.unitNumber} onChange={({ target }) => setUser({...user, "unitNumber" :target.value})}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md>
              <FormGroup>
                <Label for="exampleName">City</Label>
                <Input type="text" name="name" id="exampleName"  value={user.city} onChange={({ target }) => setUser({...user, "city" :target.value})}/>
              </FormGroup>
            </Col>
            <Col md>
              <FormGroup>
                <Label for="exampleName">Province/ State</Label>
                <Input type="text" name="name" id="exampleName"  value={user.state} onChange={({ target }) => setUser({...user, "state" :target.value})}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md>
            <FormGroup>
              <Label for="exampleName">Country</Label>
              <Input type="text" name="name" id="exampleName"  value={user.country} onChange={({ target }) => setUser({...user, "country" :target.value})}/>
            </FormGroup>
            </Col>
            <Col md>
            <FormGroup>
              <Label for="exampleName">Postal/ ZIP code</Label>
              <Input type="text" name="name" id="exampleName"  value={user.postalCode} onChange={({ target }) => setUser({...user, "postalCode" :target.value})}/>
            </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}

ContactInformationCard.protoTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired
}

export default ContactInformationCard;