import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Button, CustomInput, Alert } from 'reactstrap';
import FalconCardHeader from './FalconCardHeader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import redoOutlined from '@iconify/icons-ant-design/redo-outlined';
import infoCircleOutlined from '@iconify/icons-ant-design/info-circle-outlined';
import Icon from '@iconify/react';
import { toast } from 'react-toastify';
import { getInviteLink, sendInvitationCode } from '../../api/user.api';
import { NIL } from 'uuid';
import SessionService from '../../services/SessionService';
import { baseURL } from '../../config';

const SetupUserInvitationEmail = ({user}) => {
    const [link, setLink] = useState('');

    const onLinkCopy = () => {
        toast.success(`Successfully copied link to your clipboard`);
    }

    const generateLink = async () => {
        const result = await getInviteLink(user.id)
        const link = result.data.link;
        setLink(link);
    };

    useEffect(() => {
        generateLink()
    },[user])

    const resendActivationEmail = async e => { 
        try {
            await sendInvitationCode(user.id);
            toast.success(`Re-sent the invitation link`);
        } catch (error) {
            toast.error("Error sending the invitation link")
        }
    }

    if (user.setupCompletedAt != null) return null

    return (
        <Col md>
            <Alert color='info'>This user has not completed account setup</Alert>
            <Button color={'primary'} size="sm" onClick={resendActivationEmail}>
                Re-send activation email
            </Button>

            <p className="my-2 fs--1 d-flex flex-row ">
                <strong>Use an invitation link</strong>
            </p>
            <div className="mt-1">
                <CustomInput
                id="activation-link"
                label=""
                value={link}
                type="link"
                disabled={true}
                />
                <CopyToClipboard text={link} onCopy={onLinkCopy} className="ml-2">
                <FontAwesomeIcon icon="copy" className="mr-2" />
                </CopyToClipboard>
            </div>
            
            <p className="mt-2"><Icon style={{color: '#2C7BE5', width:"1.5rem", height:"1.5rem"}} icon={infoCircleOutlined} /> Share the invitation link directly with the user. Anyone with this link will be able to create an account for this user</p>
        </Col>
    )
}

export default SetupUserInvitationEmail;