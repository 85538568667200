import { dateFormatter, avatarFormatter, sendFormatter, composeNameFormatter } from '../../../../utils/formatter';

const composeColumns = [
  {
    dataField: 'avatar',
    text: 'Name',
    headerClasses: 'border-0 align-middle td-small',
    classes: 'border-0 py-2 align-middle',
    formatter: avatarFormatter
  },
  {
    dataField: 'name',
    text: '',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: composeNameFormatter,
  },
  {
    dataField: 'group',
    text: 'Organization',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    style: {paddingTop: '15px'},
    classes: 'd-none d-sm-none d-md-none d-lg-block',
    headerClasses: 'd-none d-sm-none d-md-none d-lg-block'
  },
  {
    dataField: 'email',
    text: 'Email',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'send',
    text: '',
    headerClasses: 'border-0 align-middle td-small',
    classes: 'border-0 py-2 pr-3 align-middle',
    formatter: sendFormatter
  },
];

export default composeColumns;