import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import Icon from '@iconify/react';

const SettingsOption = ({ icon, to, title, description }) => {
  const [textColor, setTextColor] = useState('#344050')

  return (
    <ListGroupItem onMouseOver={() => {setTextColor('#2C7BE5')}} onMouseOut={() => {setTextColor('#344050')}} style={{marginBottom: '0.5rem'}}>
      <ListGroupItemHeading>
        <Link to={to} className="d-flex flex-row align-self-center">
          <div className="setting-icon" style={{ marginRight:"1rem", textAlign:"center"}}>
          <Icon style={{color: textColor, width:"2.5rem", height:"2.5rem"}} icon={icon}/>
          </div>
          <div className="setting-option" >
          <h5 style={{color: textColor}} className="fs-1 mb-0 flex-fill d-flex justify-content-between align-items-start">
            {title}
          </h5>
          <Link to={to} style={{color: textColor}} className="fs-0">
              {description}
            </Link>
          </div>
        </Link>
      </ListGroupItemHeading>
    </ListGroupItem>
  )
};

export default SettingsOption;