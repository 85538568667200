import { ThunkAction } from "../../main/storeTypes";
import { loggingIn, loginSucceeded } from "../authentication/authenticationModel";
import SessionService from '../../../../services/SessionService';
import { updateSubs } from "../../features/joinedConversations/MyConversations"

export const login = (uuid: string): ThunkAction<Promise<void>> => {
  return (dispatch, getState, context) => {
    dispatch(loggingIn());

    // Show the login screen for a minimum amount of time as a splash screen
    //const timer = new Promise(resolve => setTimeout(resolve, 2000));

    // Set the UUID of the current user to ensure that presence works correctly
    context.pubnub.api.setUUID(uuid);
    
    // Set the AuthKey
    const authKey = SessionService.getAccessToken() as string
    context.pubnub.api.setAuthKey(authKey)

    // Update all the subscriptions for the user and return.
    const isLoginSuccessful = (dispatch(updateSubs(uuid)))
    return Promise.all([isLoginSuccessful]).then(() => {
      dispatch(loginSucceeded({ loggedInUserId: uuid }));
    });
  };
};
