import React from "react";
import { Link } from "react-router-dom";

/**
 * Displays the current and maximum number of clients to add, and a link to the billing page
 * @param {int} currentClients Current number of clients
 * @param {int} maxClients Maximum number of clients
 * @param {boolean} linkHidden Whether the link to the billing page should be hidden
 * @returns 
 */
const ClientLimitAlert = ({currentClients, maxClients, linkHidden, ...rest}) => (
  <div className="alert alert-warning" role="alert" {...rest}>
    You have added <b>{currentClients} client(s).</b>
    { currentClients < maxClients ?
      <> You may add <b>{maxClients - currentClients} more</b> on the free tier. </> :
      <> You may not add <b>additional clients</b> on the free tier. </>
    }
    { !linkHidden && <><Link to="/settings/billing">Upgrade now</Link> to unlock unlimited clients. </>}
  </div>
);

export default ClientLimitAlert;