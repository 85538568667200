import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown,
  Badge,
} from 'reactstrap';

import { generateIcon } from "../pages/Documents/DocumentHelpers";

import Avatar from '../components/clienttable/common/Avatar';
import Flex from '../components/clienttable/Flex';
import roundSend from '@iconify/icons-ic/round-send';
import Icon from '@iconify/react';

export const iconFormatter = (dataField, item) => {
  return generateIcon(item);
}

export const fileNameFormatter = (dataField, file, onClick) => (
  <span style={{cursor: "pointer"}} onClick={() => onClick(file)}>{file.name}</span>
);

export const nameFormatter = (dataField, { id, role, firstName, lastName }) => (
  <Link to={role === 'STAFF' ? `/staff/${id}` : `/client/${id}`}>
    <Media tag={Flex} align="center">
      <Media body className="ml-2">
        <h5 className="mb-0 fs--1">{firstName + (lastName ? ' ' + lastName : '')}</h5>
      </Media>
    </Media>
  </Link>
);

export const emailFormatter = email => <a href={`mailto:${email}`}>{email}</a>;
export const phoneFormatter = phone => <a href={`tel:${phone}`}>{phone}</a>;

export const dateFormatter = lastActivity => lastActivity ? moment(lastActivity).format('MMM Do, YYYY') : "No activity";

export const actionFormatter = (dataField, { id }) => (
  // Control your row with this id
  <UncontrolledDropdown>
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right className="border py-2">
      <DropdownItem onClick={() => console.log('Edit: ', id)}>Edit</DropdownItem>
      <DropdownItem onClick={() => console.log('Delete: ', id)} className="text-danger">
        Delete
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export const avatarFormatter = (dataField, { firstName, lastName }) => (<Avatar size="xl" round="circle" name={firstName + (lastName ? ' ' + lastName : '')} />);

export const composeNameFormatter = (dataField, { id, role, firstName, lastName }) => (
  <div className="d-flex flex-row">
    <h5 className="mb-0 mr-2 fs--1">{`${firstName} ${lastName}`}</h5>
    {['OWNER', 'STAFF'].includes(role) &&
      <Badge color="soft-info" pill>
        {role == 'OWNER' ? 'ADMIN' : 'STAFF'}
      </Badge>
    }
  </div>
);

export const sendFormatter = (sendDirectMessage, col) => {
  return (
    <Icon className='text-600 p-2 btn-reveal'
      onClick={sendDirectMessage}
      icon={roundSend}
      color='#2C7BE5'
      height="35px"
      width="35px"
    />)
};