import React from 'react';
import { Link } from 'react-router-dom';
import withBrandedCard from '../../hoc/withBrandedCard';

const VerifyError = () => {

  return (
    <div className="text-center">
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
        Your email verification link is invalid.
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        Return to dashboard
      </Link>
    </div>
  );
}

export default withBrandedCard(VerifyError);
