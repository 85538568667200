import React from 'react';
import { Link } from 'react-router-dom';
import withBrandedCard from '../../hoc/withBrandedCard';

const InviteExpired = () => {

  return (
    <div className="text-center">
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
        Your invitation link has expired
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/authentication/login">
        Login
      </Link>
    </div>
  );
}

export default withBrandedCard(InviteExpired);
