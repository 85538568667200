import React, { useState } from "react";
import { useDispatch } from "../../../main/useDispatch";
import { login } from "../../authentication/loginCommand";
import { isLoggingIn } from "../../authentication/authenticationModel";
import { isUserLoggedIn } from "../../authentication/authenticationModel";
import { useSelector } from "react-redux";
import { refreshPermissions, createUsers } from "../../../../../api/messages";
import SessionService from '../../../../../services/SessionService';

const Login = () => {
  const dispatch = useDispatch();
  const loggingIn = useSelector(isLoggingIn);
  const loggedIn = useSelector(isUserLoggedIn);
  // show the username in the email field
  const [userId, setUserId] = useState("");


  const loginCurrentUser = async () => {
    if (loggingIn || loggedIn || userId !== "") {
      return;
    }
    const localUser = SessionService.getUser();
    const localUserId = localUser.id.toString()
    
    const authKey = SessionService.getAccessToken()
    await createUsers([localUserId])
    await refreshPermissions(localUserId, authKey)

    await dispatch(login(localUserId));
    setUserId(localUserId);
  };

  if (!loggedIn && !loggingIn) {
    loginCurrentUser();
  }
  
  return <div></div>;
};

export { Login };
