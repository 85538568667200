import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, FormText, InputGroupText, InputGroupAddon, Modal, ModalHeader, ModalBody, InputGroup, ModalFooter, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { updateSubdomain } from '../../../api/organization.api';
import { baseURL } from '../../../config';
import SessionService from '../../../services/SessionService';
import PropTypes from 'prop-types';
import logger from '../../../utils/logger';

const ChangeOrganizationModal = ({showChangeOrgModal, setShowChangeOrgModal }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newURL, setNewURL] = useState('');

  const updateOrganization = async e => {
    e.preventDefault();
    try {
      await updateSubdomain(newURL, currentPassword);
      setShowChangeOrgModal(!showChangeOrgModal);
      setCurrentPassword('');
      setNewURL('');
      SessionService.destroy()
      window.location = `http://${newURL}.${baseURL}/settings/organization`
    } catch (err) {
      logger.warn(err, 'Error while updating subdomain')
      toast.error('URL is not available');
    }
  }

  const onCancel = () => {
    setShowChangeOrgModal(!showChangeOrgModal)
    setCurrentPassword('');
    setNewURL('');
  }

  return (
    <Modal isOpen={showChangeOrgModal} toggle={() => setShowChangeOrgModal(!showChangeOrgModal)}>
      <ModalHeader>Change Organization URL</ModalHeader>
      <Form onSubmit={updateOrganization}>
        <ModalBody>
          <FormGroup>
            <Label>Password</Label>
            <Input
              value={currentPassword}
              onChange={({ target }) => setCurrentPassword(target.value)}
              type="password"
            />
          </FormGroup>
          <Label>New URL</Label>
          <FormGroup>
            <InputGroup>
              <Input placeholder="New URL"
                value={newURL}
                onChange={({ target }) => setNewURL(target.value)}
                type="text"
                pattern="[a-z0-9]+"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>.ctbl.app</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <FormText>
            Only lowercase (a-z) and numbers (0-9) are allowed.
            </FormText>
          </FormGroup>
          <FormText>Updating the url will log you out</FormText>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>
            Cancel
              </Button>
          <Button color="primary" disabled={!newURL}>Update URL</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

ChangeOrganizationModal.protoTypes = {
  showChangeOrgModal: PropTypes.bool.isRequired ,
  setShowChangeOrgModal: PropTypes.func.isRequired
}

export default ChangeOrganizationModal;