import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

const StorageConnectionErrorMessageNonOwner = () => {
  return (
    <Card className="p-4 bg-warning text-light">
      <CardHeader title="Storage connection error" className="p-0" />
      <h5 className="text-light text-sans-serif">Unable to access files</h5>
      <CardBody className="pl-0 pb-0 mb-0">
        <p className="text-sans-serif mb-0">Files could not be retrieved at this time. Please contact your administrator.</p>
      </CardBody>
    </Card>
  );
}

export default StorageConnectionErrorMessageNonOwner;
