export const version = '2.5.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const baseURL = process.env.REACT_APP_BASE_URL
export const prefixURL = process.env.REACT_APP_URL_PREFIX

// Only supporting jpeg and png for now, while we figure out why svg isnt working
export const logoConfig = {
    size: 10485760,
    formats: ['image/jpeg', 'image/png']
}

export const settings = {
    isFluid: false,
    isRTL: false,
    isDark: false,
    navbarPosition: 'vertical',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'transparent'
  };