import React, { useState, useEffect } from 'react';
import { BreadcrumbItem, Breadcrumb } from 'reactstrap';
import { toast } from 'react-toastify';

import SessionService from '../../services/SessionService';
import { get } from '../../api/organization.api';
import { Link } from 'react-router-dom';
import ConnectedDrive from './ConnectedDrive';
import SetupDrive from './SetupDrive';

const hasError = window.location.search.indexOf('error') !== -1 && window.location.search.indexOf('access_denied') !== -1;
if (hasError) {
  toast.error('Authorization unsuccessful. Please try again')
}

// get the most up to date organization data
const refreshOrganization = async (setOrganization, setOrgRefreshed) => {
  const organization = SessionService.getOrganization()
  const result = await get(organization.id)
  const org = result.data
  SessionService.setOrganization(org)
  setOrganization(org)
  setOrgRefreshed(true)
}

const StorageSettings = () => {
  const [organization, setOrganization] = useState(SessionService.getOrganization())
  const [orgRefreshed, setOrgRefreshed] = useState(false)
  const [inChangeConnectionFlow, setInChangeConnectionFlow] = useState(false) // todo change to inChangeConnectionFlow

  useEffect(() => {
    refreshOrganization(setOrganization, setOrgRefreshed)
  }, [])

  if (!orgRefreshed) {
    return (
        <></>
    )
  }

  return <>
  <Breadcrumb className="p-1">
    <BreadcrumbItem>
      <Link to="/">Home</Link>
    </BreadcrumbItem>
    <BreadcrumbItem active>Storage</BreadcrumbItem>
  </Breadcrumb>

  <h3 className="p-1">Storage Connection</h3>

  {!inChangeConnectionFlow && !organization.temp_provider && organization.provider?
    <ConnectedDrive
      organization={organization}
      setInChangeConnectionFlow={setInChangeConnectionFlow}
    /> :
    <SetupDrive 
      organization={organization}
      setInChangeConnectionFlow={setInChangeConnectionFlow}
    />
    }
    </>
};

export default StorageSettings;
