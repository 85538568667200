import React, { createRef, useEffect, useState } from "react";
import { getAddMembersView } from "../../layout/Selectors";
import { useSelector, useDispatch } from "react-redux";
import Icon from "@iconify/react";
import { ScrollView } from "../../../foundations/components/layout";
import {
  addMembersViewHidden,
  conversationMembersViewHidden,
  currentConversationViewDisplayed,
} from "../../layout/LayoutActions";
import { Button } from "reactstrap";
import { getCurrentConversationMembers, addMembers, refreshMembers } from "../ConversationMembers";
import { getCurrentConversationId } from "../../currentConversation/currentConversationModel";
import { UserFragment } from "../MemberDescription";
import { Input } from "reactstrap";
import plusOutlined from "@iconify/icons-ant-design/plus-outlined";
import AddMembersColumns from "./AddMembersColumns";
import BootstrapTable from 'react-bootstrap-table-next';

interface FilteredUserProps {
  id: string,
  firstName: string,
  lastName: string,
  role: string,
  group: string,
  email: string,
}

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => { }}
      ref={input => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);

const AddMembers = ({ chatProfileList }) => {
  const [formattedUsers, setFormattedUsers] = useState<any>([]);
  const [filteredUsers, setFilteredUsers] = useState<FilteredUserProps[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  let table: any = createRef();

  const viewAddMembers = useSelector(getAddMembersView);
  const conversationId: string = useSelector(getCurrentConversationId);
  var conversationMembers: UserFragment[] = useSelector(getCurrentConversationMembers);

  // Get and refresh the conversation members list
  useEffect(() => {
    dispatch(refreshMembers(conversationMembers, dispatch, conversationId))
    setFilteredUsers(formattedUsers.filter((user) => {
      return !conversationMembers.map((user) => user.id).includes(user.id);
    }))
  }, [conversationMembers, viewAddMembers, conversationId])

  useEffect(() => {
    // Filter out already added conversation members
    const noConversationMembers = Object.assign({}, chatProfileList);
    conversationMembers.forEach(member => {
      delete noConversationMembers[member.id.toString()];
    })

    setFormattedUsers(Object.keys(noConversationMembers).map(id => {
      let user = noConversationMembers[id];
      user.id = id;
      user.name = `${user.firstName} ${user.lastName}`
      return user;
    }));
    setFilteredUsers(formattedUsers);
  }, [chatProfileList, viewAddMembers, conversationId])

  // Update filtered users if formatted users is updated
  useEffect(() => {
    setFilteredUsers(formattedUsers);
  }, [formattedUsers])

  useEffect(() => {
    if (!viewAddMembers){
      setSelectedRows([]);
      setFilteredUsers(formattedUsers);
    }
  }, [viewAddMembers])

  const onSelectRow = () => {
    setImmediate(() => {
      setSelectedRows(table.current.selectionContext.selected)
    });
  };

  const onSearch = (e) => {
    e.preventDefault();
    const searchValue = e.target.value.toLowerCase()
    searchValue == ''
      ? setFilteredUsers(formattedUsers)
      : setFilteredUsers(formattedUsers.filter(client => {
        const name = client.firstName + ' ' + client.lastName
        return name.toLowerCase().includes(searchValue.toLowerCase())
      }));
  }

  return viewAddMembers ?
    <>
      <div className="d-flex flex-row align-items-center" style={{ width: "100%", padding: "10px" }}>
        <h6 className="mb-0 mr-2">To:</h6>
        <Input className="rounded-pill search-input mr-2"
          type="search" placeholder="Search..."
          aria-label="Search"
          onChange={onSearch}
        />
        <Button
          className="mr-2"
          color="primary"
          size="sm"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            dispatch(addMembers(selectedRows, conversationId));
            dispatch(addMembersViewHidden());
            dispatch(conversationMembersViewHidden());
            dispatch(currentConversationViewDisplayed());
          }}
        >
          Add Members <Icon icon={plusOutlined} />
        </Button>
        <Button
          color="primary"
          size="sm"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            dispatch(addMembersViewHidden());
            dispatch(conversationMembersViewHidden());
            dispatch(currentConversationViewDisplayed());
          }}
        >
          Cancel
        </Button>
      </div>
      <ScrollView>
        <BootstrapTable
          ref={table}
          bootstrap4
          keyField="id"
          data={filteredUsers}
          size="sm"
          columns={AddMembersColumns}
          selectRow={{
            mode: 'checkbox',
            columnClasses: 'py-2 align-middle',
            selectionHeaderRenderer: ({ mode, indeterminate, rowIndex, ...rest }) => <SelectRowInput type="checkbox"
              indeterminate={indeterminate}
              rowIndex={rowIndex}
              {...rest} />,
            selectionRenderer: ({ mode, indeterminate, rowIndex, ...rest }) => <SelectRowInput type="checkbox"
              indeterminate={indeterminate}
              rowIndex={rowIndex}
              {...rest} />,
            headerColumnStyle: { border: 0, width: '7vh' },
            selectColumnStyle: { border: 0, verticalAlign: 'middle', width: '7vh' },
            onSelect: onSelectRow,
            onSelectAll: onSelectRow,
          }}
          // {...rowProps}
          // selectRow={selectRow(onSelectRow)}
          bordered={false}
          classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
          defaultSorted={[{ dataField: 'name', order: 'asc' }]}
        />
      </ScrollView>
    </>
    : null;
};

export { AddMembers };
