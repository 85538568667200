import React from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

const StaffDetailsCard = ({ maxStaff, currentStaff, ...rest }) => {

  const detailsData = [
    { title: "Max Staff Accounts:", value: maxStaff },
    { title: "Current Staff Accounts:", value: currentStaff },
  ]

  return (
    <Card {...rest}>
      <CardBody>
        <p>
          Staff users belong to the organization but do not have access to the settings page.
          Staff can add, view, and edit all clients, and documents.
        </p>
        <Row>
          <Col xs={3}>
            {detailsData.map((detail, index) =>
              <Row className="mb-2">
                <Col xs={10} className="font-weight-semi-bold">{detail.title}</Col>
                <Col xs={2}>{detail.value}</Col>
              </Row>
            )}
          </Col>
          <Col xs={9}>
            <Link to="/settings/billing">
              <Button color="primary">Manage Subscription</Button>
            </Link>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default StaffDetailsCard;