import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import Select from './Select';
import { upperCaseFirstLetter } from '../../../helpers/strings';

const UsersSelect = ({ dataMap, options, ...rest }) => {
  const dataOptions = options.map(option => {
    const { firstName, lastName } = dataMap[option];
    return {
      value: option,
      label: `${firstName && upperCaseFirstLetter(firstName)} ${lastName && upperCaseFirstLetter(lastName)}`
    }
  })
  return <FormGroup>
    {Array.isArray(options) && !!options.length && <Select options={dataOptions} {...rest} />}
</FormGroup>
}

UsersSelect.Prototypes = {
  options: PropTypes.array,
  upperCaseFirstLetter: PropTypes.func
}

export default UsersSelect;
