import React from 'react';
import SettingsOption from '../../components/clienttable/settings/SettingsOption'

import options from '../../data/clienttable/options';
import { ListGroup } from 'reactstrap';
import TrialAlert from "../../components/clienttable/alerts/TrialAlert";

const Settings = () => {
  return (
    <>
      <h3 className="ml-3 my-3">Settings</h3>
      <TrialAlert/>
      <div className="flex-column">
        {options.map((option, index) =>
        <ListGroup>
          <SettingsOption {...option} key={'setting-option-'+index} />
        </ListGroup>
        )}
      </div>
    </>
  );
};

export default Settings;
