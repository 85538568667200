import React from 'react';
import { Card } from 'reactstrap';

const ConfirmSetupCard = () => {
  return (
    <Card className="p-3 m-2">
      <div>
        <p>
          <strong>
            After you confirm your setup a folder will be created in your storage drive for your organization
          </strong>
        </p>
        <p>
          You may change your storage drive again at any time from settings.
        </p>
      </div>
    </Card>
  )
}

export default ConfirmSetupCard;
