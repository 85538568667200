import React, { useState, useEffect, useRef } from "react";
import {
  CardBody,
  Card,
} from "reactstrap";
import DocumentData from "./DocumentData";
import driveApi from "../../api/drive.api";
import documentLabels from "../../data/clienttable/documentLabels";
import { Link } from "react-router-dom";

import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import ActionButtons from "./ActionButtons";
import MoreButtonRow from "./MoreButtonRow";
import { Item, downloadFiles, generateIcon, getFileSize, openPdfFile, getImageUrl, calculateDisabledInfo } from "./DocumentHelpers";
import RenameModal from "../../modals/RenameModal";
import { Modal, ModalHeader } from "reactstrap";
import cloudDownloadOutlined from "@iconify/icons-ant-design/cloud-download-outlined";
import DarkModalDialogBox from "./DarkModalDialogBox";
import { isDriveConnected } from './DocumentHelpers';
import { ProgressToast } from '../../components/clienttable/ProgressToast';
import documentColumns from "../../data/clienttable/tables/documents";
import Lightbox from "react-image-lightbox";
import PrizmDocViewerWrapper from './PrizmDocViewerWrapper';
import logger from "../../utils/logger";

const MIME = {
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const DocumentsTable = ({
  file,
  setFile,
  folderId,
  files,
  reloadViewRef,
  itemPath,
  setItemPath,
  loading,
  isClient,
  from,
  to,
  setShowMoveCard,
  showMoveCard,
  shouldMoveFiles,
  setShouldMoveFiles,
  selected,
  setSelected,
  setOpenShareModal,
  sortOrder,
  documentLabel,
  setDocumentLabel,
}) => {
  // todo clean all these local variables, not well organized
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [movingIds, setMovingIds] = useState([]);
  const [rowClick, setRowClick] = useState(false);
  const [tableData, setTableData] = useState([]);

  // State variables for the preview functionality
  const [fileToPreview, setFileToPreview] = useState(null);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openPreviewTooLargeModal, setOpenPreviewTooLargeModal] = useState(false);
  const [openPreviewUnsupportedModal, setOpenPreviewUnsupportedModal] = useState(false);
  const [filesToDownload, setFilesToDownload] = useState([]);
  const toastId = useRef(null);

  // This value is in KB. File size limit: 50 (Where 50 is in MB). Could be use to manage settings, and testing.
  const maxPreviewFileSize = 50;

  const [openPreviewPdf, setOpenPreviewPdf] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState(null);

  // values indicating whether a button should be enabled or disabled
  const [disableInfo, setDisableInfo] = useState({
    isDownloadDisabled: true,
    isPreviewDisabled: true,
    isShareDisabled: true,
    isMoveDisabled: true,
    isRenameDisabled: true,
    isDuplicateDisabled: true,
    isDeleteDisabled: true,
  })

  const [viewingSessionId, setViewingSessionId] = useState(null);
  const [viewerControl, setViewerControl] = useState(null); /* Used for programmatic access to viewer, not used atm */
  const [prizmViewerOpen, setPrizmViewerOpen] = useState(false);
  const [filePreviewName, setFilePreviewName] = useState('');


  const getViewerId = async (id, updatedTime) => {
    // Tell AccuSoft we want to begin viewing and get viewing session id
    const res = await driveApi.initializeViewSession(id, { updatedTime });

    // Make sure we received an HTTP 200 response.
    if (res.status != 200) {
      throw new Error(`The request to the application server to create a new viewing session responded with: "${res.status} ${res.statusText}"`);
    }

    // Store viewingSessionId so we can instantiate the viewer
    setViewingSessionId(res.data.viewingSessionId);
  }

  // these use effectes handle refreshes, moving and selecting files
  useEffect(() => {
    if (shouldMoveFiles)
      moveFiles();
  }, [shouldMoveFiles]);

  useEffect(() => {
    if (selected.length === 1) {
      const selectedId = selected[0];
      const file = files.find((one) => one.id == selectedId);
      setFile(file);
    }
  }, [selected]);

  useEffect(() => {
    let files = calculateTableData();
    setTableData(files);
  }, [files, file]);

  // Disable action buttons logic
  useEffect(() => {
    const calculatedDisabledInfo = calculateDisabledInfo(files, showMoveCard, selected, itemPath, file, folderId);
    setDisableInfo(calculatedDisabledInfo)
  }, [files, showMoveCard, selected, itemPath, file])

  // When there is a file to preview, preview it.
  useEffect(() => {
    // Logic to preview file goes here
    if (fileToPreview) previewFile(fileToPreview);

    // Clean up everything after we're done
    return () => {
      setPreviewImgUrl(null);
      setOpenPreviewPdf(false);
      setOpenPreviewModal(false);
      setFileToPreview(null);
    };
  }, [fileToPreview]);

  // Controls the labels in the table
  useEffect(() => {
    if (folderId === 'shared')
      setDocumentLabel(documentLabels.NO_SHARED);
    else
      setDocumentLabel(documentLabels.EMPTY);
  }, [folderId])

  const previewFile = async (aFile) => {
    setFilePreviewName(aFile.name);
    setPrizmViewerOpen(false);
    
    //Convert into integer.
    const fileSize = parseInt(getFileSize(aFile.size), 10);
    const maxSize = maxPreviewFileSize * 1024;

    // Must be a file with a size
    if (aFile.isFolder || !aFile.size) {
      toast.error("Cannot preview a folder");
      return;
    }
    if (fileSize >= maxSize) {
      setFilesToDownload([aFile])
      setOpenPreviewTooLargeModal(true);
      return;
    }

    try {
      ProgressToast({ toastId, message: `Loading preview for: ${aFile.name}` });

      if ([MIME.doc, MIME.docx, MIME.ppt, MIME.pptx, MIME.xls, MIME.xlsx].includes(aFile.mimeType)) {
        getViewerId(aFile.id, aFile.updatedTime);
        setPrizmViewerOpen(true);
        toast.dismiss(toastId.current);
        return;
      }
      
      
      if (["image/png", "image/jpeg", "image/gif", "application/pdf"].includes(aFile.mimeType)) {
        const response = await driveApi.download(aFile.id);
        response.blob().then((blob) => {
          //pdf
          if (aFile.mimeType === "application/pdf") {
            openPdfFile(blob);
            setOpenPreviewPdf(!openPreviewPdf);
          }
          //Images
          else if (["image/png", "image/jpeg", "image/gif"].includes(aFile.mimeType)) {
            setPreviewImgUrl(getImageUrl(blob));
            setOpenPreviewModal(!openPreviewModal);
          }
        });
      } else {
        //Unsupported file.
        setFilesToDownload([aFile])
        setOpenPreviewUnsupportedModal(true);
      }
      
      toast.dismiss(toastId.current);

    } catch (err) {
      toast.error('Failed to preview file');
      toast.dismiss(toastId.current);
    }
  };

  const isClientInRootFolder = () => {
    const isRootFolder = itemPath && itemPath.length === 1 && itemPath[0].id === folderId;
    return isClient && isRootFolder;
  };

  const onFileClick = (file) => {
    if (file.isFolder) {
      setSelected([]);
      setItemPath(path => {
        const lastItem = path[path.length-1];
        return lastItem.id === file.id ? path : [...path, { id: file.id, name: file.name }];
      })
    }
    else
      setFileToPreview(file);
  }

  // Format the file name appropriately - for folders, add a level, for files, preview it.
  // todo move this into the DocumentColumns folder, this takes care of the file names
  const fileNameFormatter = (dataField, file) => (
    file.isFolder ?
    <Link
      to={`/documents?folderId=${file.id}`}
      onClick={() => onFileClick(file)}
    >
      {file.name}
    </Link> :
    <span style={{cursor: "pointer"}} onClick={() => onFileClick(file)}>{file.name}</span>
  );

  documentColumns[1].formatter = fileNameFormatter;

  const createFolder = async (name) => {
    var isConnected = await isDriveConnected();
    if (!isConnected) {
      toast.error("Creating folder failed: No drive connected");
      return
    }
    try {
      await driveApi.createFolder(name, folderId);
    } catch (err) {
      logger.error(err, 'There was an error while creating a folder')
    }
    reloadViewRef.current();
  };

  const deleteSelected = async () => {
    Promise.all(selected.map(async (id) => {
      try {
        await driveApi.remove(id);
        return id;
      } catch (error) {
        return null;
      }
    })).then((res) => {
      const count = res.filter((id) => id !== null).length;
      if (count) toast.info(`${count} out of ${selected.length} file(s) deleted`);
      setSelected([]);
      reloadViewRef.current();
    });
  };

  const renameFile = async (id, name) => {
    await driveApi.rename(id, name);
    setOpenRenameModal(false);
    reloadViewRef.current();
  };

  const duplicateFile = async () => {
    if (file) {
      try {
        await driveApi.duplicate(file.id, folderId, file.isFolder);
        toast.info(`Duplicated ${file.name}`);
      } catch {} // Toast will show from backend
      reloadViewRef.current();
    }
  };

  const selectMovingFiles = async () => {
    setMovingIds(selected);
    setShowMoveCard(true);
  };

  const moveFiles = async () => {
    if (movingIds && movingIds.length > 0) {
      try {
        const res = await driveApi.move(from.id, to.id, movingIds);
        if (res.data.length < movingIds.length)
          toast.error('No permission to move this file');
        if (res.data.length > 0)
          toast.success(`${res.data.length} out of ${movingIds.length} file(s) moved from ${from.name} to ${to.name}`);
        setMovingIds([])
        setShowMoveCard(false);
        setSelected([])
        reloadViewRef.current();
      } catch (err) {
        console.log(err);
      }
    }
    setShouldMoveFiles(false);
  };

  const setFileSelectedForCurrentDropdown = (file) => {
    setFile(file);
    setSelected([file.id]);
    setRowClick(true);
  };

  const openShareSettingsModal = (val) => {
    setOpenShareModal(val);
  };

  // this calculates the row item in the document (bootstrap table) 
  // this contains all the data we see in a particular row in the document table
  // to understand why this is done this way, look into bootstrap table and the document data component
  const calculateTableData = () => {
    !isEmpty(files) &&
      files.map(async (file) => {
        file.delete = deleteSelected;
        file.duplicate = duplicateFile;
        file.openShareSettingsModal = openShareSettingsModal;
        file.selectMovingFiles = selectMovingFiles;
        file.setSelected = setSelected;
        file.setFile = setFile;
        file.more = (
          // this contains the ui and logic for the ... button in the document table
          <MoreButtonRow
            file={file}
            showMoveCard={showMoveCard}
            isClientInRootFolder={isClientInRootFolder}
            setFileSelectedForCurrentDropdown={setFileSelectedForCurrentDropdown}
            setOpenRenameModal={setOpenRenameModal}
            duplicateFile={duplicateFile}
            setFileToPreview={setFileToPreview}
          />
        );
        return file;
      });
    return files;
  };

  const prevStyles = {
    overlay: {
      zIndex: 1020
    },
  };

  return (
    <>
      <Card className='mb-3'>
        <ActionButtons
          create={createFolder}
          isClientInRootFolder={isClientInRootFolder}
          file={file}
          setFile={setFile}
          reloadViewRef={reloadViewRef}
          folderId={folderId}
          isDisabledInfo={disableInfo}
          selectMovingFiles={selectMovingFiles}
          setOpenRenameModal={(val) => setOpenRenameModal(val)}
          duplicateFile={duplicateFile}
          deleteFile={deleteSelected}
          isClient={isClient}
          setOpenShareModal={setOpenShareModal}
          setFileToPreview={setFileToPreview}
          selected={selected}
          files={files}
        />
        <RenameModal
          isOpen={openRenameModal}
          closeModal={setOpenRenameModal}
          rename={renameFile}
          file={file}
        />
        <CardBody className='p-0 min-vh-50'>
          <DocumentData
            setSelected={setSelected}
            files={tableData}
            columns={documentColumns}
            selected={selected}
            rowClick={rowClick}
            setRowClick={setRowClick}
            showMoveCard={showMoveCard}
            isClientInRootFolder={isClientInRootFolder}
            reloadViewRef={reloadViewRef}
            folderId={folderId}
            documentLabel={documentLabel}
            sortOrder={sortOrder}
            isLoading={loading}
          />
        </CardBody>
      </Card>

      { prizmViewerOpen && viewingSessionId &&
        <Modal centered={true} size='xl' isOpen={prizmViewerOpen} toggle={() => {setPrizmViewerOpen(false); setViewingSessionId(null)}}>
          <ModalHeader toggle={() => {setPrizmViewerOpen(false); setViewingSessionId(null)}} style={{ padding: '0.2rem 1rem' }}>
            <span style={{fontSize: '16px'}}>{filePreviewName}</span>
          </ModalHeader>
          
          <PrizmDocViewerWrapper
            viewingSessionId={viewingSessionId} 
            style={{width: '100%', height: '90vh'}}
            onViewerReady={setViewerControl}
          />
        </Modal>
      }

      {/* Modal used to display images */}
      {previewImgUrl && openPreviewModal && (
        <Lightbox
          mainSrc={previewImgUrl}
          onCloseRequest={() => setOpenPreviewModal(false)}
          reactModalStyle={prevStyles}
        />
      )}

      {/* Modal used to display file size warning! */}
      <DarkModalDialogBox
        modalText='Too large for preview.'
        isOpen={openPreviewTooLargeModal}
        isOpenFunction={setOpenPreviewTooLargeModal}
      >
        <Item
          itemName='Download'
          disabled={false}
          icon={cloudDownloadOutlined}
          onClick={() => {
            downloadFiles(filesToDownload, toastId);
            setOpenPreviewTooLargeModal(false);
          }}
        />
      </DarkModalDialogBox>

      {/* Modal used to display unsupported file warning... */}
      <DarkModalDialogBox
        modalText='Preview not supported for this file type.'
        isOpen={openPreviewUnsupportedModal}
        isOpenFunction={setOpenPreviewUnsupportedModal}
      >
        <Item
          itemName='Download'
          disabled={false}
          icon={cloudDownloadOutlined}
          onClick={() => {
            downloadFiles(filesToDownload, toastId);
            setOpenPreviewTooLargeModal(false);
          }}
        />
      </DarkModalDialogBox>
    </>
  );
};

export default DocumentsTable;
