import React from "react";
import {
  Card,
  CardBody,
  CustomInput,
  Row,
  Col
} from "reactstrap";
import FalconCardHeader from "./FalconCardHeader";

const NotificationsCard = ({role, user, setUser}) => {

    /* If the 'Staff' toggle should be on or not */
    const isStaffToggled = () => {
      return user.notificationPref == 'ALL' || user.notificationPref == 'STAFF';
    }
  
    /* If the 'Clients' toggle should be on or not */
    const isClientsToggled = () => {
      return user.notificationPref == 'ALL' || user.notificationPref == 'CLIENT';
    }
    
    /* If the messaging toggle should be on or not */
    const isMessagingToggled = () => {
      return user.messagingNotifications;
    }

    const docsFooterText = () => {
      let suffix = ``
      if (isStaffToggled() && isClientsToggled()) {
        suffix = `staff and clients`;
      } else if (isStaffToggled()) {
        suffix = `staff`;
      } else if (isClientsToggled()){
        suffix = `clients`;
      } else {
        return null;
      }
      return <em style={{fontSize: '16px', lineHeight: '22px'}}>You will receive an email notification when documents are uploaded by {suffix}</em>;
    }

    const inputSwitched = (e) => {
      const sw = e.target.id;
      const change = e.target.checked;

      let newnotificationPref = "UNDEFINED";
      let messagingNotificationsPref = user.messagingNotifications;

      if (sw === 'staffToggle') {
        if (change){
          if (user.notificationPref == "CLIENT"){
            newnotificationPref = "ALL";
          } else if (user.notificationPref == "NONE"){
            newnotificationPref = "STAFF";
          }
        } else {
          if (user.notificationPref == "ALL"){
            newnotificationPref = "CLIENT";
          } else if (user.notificationPref == "STAFF"){
            newnotificationPref = "NONE";
          }
        }
      } else if (sw === 'clientToggle') {
        if (change){
          if (user.notificationPref == "STAFF"){
            newnotificationPref = "ALL";
          } else if (user.notificationPref == "NONE"){
            newnotificationPref = "CLIENT";
          }
        } else {
          if (user.notificationPref == "ALL"){
            newnotificationPref = "STAFF";
          } else if (user.notificationPref == "CLIENT"){
            newnotificationPref = "NONE";
          }
        }
      } else if (sw === 'messagesToggle') {
        if (change){
          messagingNotificationsPref = !user.messagingNotifications
        } else {
          messagingNotificationsPref = !user.messagingNotifications
        }
      }

      if (newnotificationPref == "UNDEFINED") {
        setUser({...user, "messagingNotifications": messagingNotificationsPref});
      } else {
        setUser({...user, "notificationPref": newnotificationPref, "messagingNotifications": messagingNotificationsPref});
      }

    }

    const NotificationPreferenceRow = ({ title, isLastItem, children, xs=8, sm=8, md=8, lg=8, xl=8}) => (
      <Row>
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <p
            className={('font-weight-semi-bold', {
              'mb-0': isLastItem,
              'mb-1': !isLastItem
            })}
          >
            {title}
          </p>
        </Col>
        <Col>{children}</Col>
      </Row>
    );
    
  return (
    <Card className='mb-3'>
      <FalconCardHeader title='Notifications' light={false} />
      {(role == 'OWNER' || role == 'STAFF') && 
      <span>
      <CardBody className='bg-light'>
        <p className="font-weight-bold mr-2">Document Upload Notifications</p>
        <p>Receive an email notification when documents are uploaded by:</p>

        <Row>
          <Col lg className="col-xxl-5">
            <NotificationPreferenceRow title={<b>Staff:</b>}>
              <td>
                <CustomInput
                  type='switch'
                  id='staffToggle'
                  checked={isStaffToggled()}
                  onChange={inputSwitched}
                  />
              </td>
            </NotificationPreferenceRow>
            <NotificationPreferenceRow title={<b>Clients:</b>}>
              <td>
                <CustomInput
                  type='switch'
                  id='clientToggle'
                  checked={isClientsToggled()}
                  onChange={inputSwitched}
                />
              </td>
            </NotificationPreferenceRow>
          </Col>
        </Row>
        <span>{docsFooterText()}</span>
      </CardBody>
      </span>}

      <CardBody className='bg-light' style={{paddingTop: "0.2rem"}}>
        <p className="font-weight-bold mr-2">Messaging Notifications</p>
        <p></p>

        <Row>
          <Col lg className="col-xxl-5">
            <NotificationPreferenceRow title={<em>Receive an email for missed messages:</em>}>
              <td>
                <CustomInput
                  type='switch'
                  id='messagesToggle'
                  checked={isMessagingToggled()}
                  onChange={inputSwitched}
                />
              </td>
            </NotificationPreferenceRow>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default NotificationsCard;
