import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import VerifyOwnerEmailModal from '../../../modals/VerifyOwnerEmailModal';
import SessionService from "../../../services/SessionService";
import { sendBoVerifyEmail, checkBoEmailVerified, updateBoEmail } from '../../../api/user.api';

/**
 * Displays error message for BO's that haven't verified their email.
 */
const BOVerificationAlert = () => {

    const [email, setEmail] = useState('');
    const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
    
    /* When user clicks 're-send code' */
    const sendBoVerificationEmail = async () => {
        try {
            const res = await checkBoEmailVerified();
            const { isBOemailVerified } = res.data;

            if (isBOemailVerified) {
                toast.info('Email is already verified')
                return;
            } else {
                await sendBoVerifyEmail();
                toast.info('Successfully sent verification email');
            }
        } catch (e) {
            toast.error('Failed to send verification email');
        }  
    }
    
    /* When user submits */
    const updateBOemail = async (e) => {
        try {
            e.preventDefault();
            const new_email = e.target.email.value;

            /* Update email without password */
            await updateBoEmail(new_email);
            
            /* Set new email in state and session service */
            let user = SessionService.getUser();
            SessionService.setUser({...user, email: new_email})
            SessionService.setEmail(new_email)
            setEmail(new_email)
            /* Send new verification email */
            await sendBoVerifyEmail();

            setIsVerifyEmailModalOpen(false)
            toast.info('Re-sent verification email');
        } catch (err) {
            toast.error('Could not update email');
        }        
    }

    useEffect (() => {
        /* Displays email even if user clicked 'don't remember' in login */
        setEmail(SessionService.getUser().email);
    }, [])

    return (
    <>
        <div className="alert alert-warning" role="alert">
            We sent a verification link to <b>{email}</b> (<Link onClick={sendBoVerificationEmail}>re-send email</Link> or <Link onClick={() => setIsVerifyEmailModalOpen(true)}>update email</Link>). Click the link <b>in the email</b> to verify.
        </div>
        
        <VerifyOwnerEmailModal
            isOpen={isVerifyEmailModalOpen}
            onClose={() => setIsVerifyEmailModalOpen(false)}
            updateEmail={updateBOemail}
        />
    </>
    );
}

export default BOVerificationAlert;