import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types'
import { Card, CardBody, Form, Row, Col, FormGroup, Label, Input, CustomInput, FormText, InputGroupText, InputGroupAddon, Modal, ModalHeader, ModalBody, InputGroup, ModalFooter, Button } from 'reactstrap';
import useFakeFetch from '../../hooks/useFakeFetch';
import { toast } from 'react-toastify';
import FalconCardHeader from './FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rocket from '../../assets/img/illustrations/rocket.png';
import { deleteSubdomain } from '../../api/organization.api';
import logger from '../../utils/logger';

const DeleteOrganizationCard = ({organization}) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [orgName, setOrgName] = useState('');
  const [showDeleteOrganizationModal, setShowDeleteOrganizationModal] = useState(false);
  const [returnToRegistrationModal, setReturnToRegistrationModal] = useState(false);

  const deleteOrganization = async e => {
    e.preventDefault();
    try {
      await deleteSubdomain(organization.id, currentPassword, orgName)
      toast.success(`Successfully deleted organization`)
      setCurrentPassword('');
      setOrgName('');
      setReturnToRegistrationModal(!returnToRegistrationModal)
    } catch (err) {
      logger.error(err, 'Error while deleting subdomain')
      toast.error(err);
    }
  }

  const returnToRegistration = () => {
    window.location = `https://www.clienttable.com/`
  }

  const onCancel = () => {
    setShowDeleteOrganizationModal(!showDeleteOrganizationModal)
    setCurrentPassword('');
    setOrgName('');
  }
  return (
      <>
        <Card className="mb-3">
            <FalconCardHeader title="Danger Zone" light={false} />
            <CardBody className="bg-light">
            <Row>
                <Col sm>
                <Button block color={'falcon-danger'} className="mr-2" onClick={() => setShowDeleteOrganizationModal(!showDeleteOrganizationModal)}>
                    Delete Organization
                </Button>
                <p><strong>This action cannot be reversed.</strong> Deleting your organization will remove your portal, users and all associated data.</p>
                <p>Documents stored in your connected drive are unaffected.</p>
                </Col>
                <Col md>
                </Col>
            </Row>
            </CardBody>
        </Card>

        <Modal isOpen={showDeleteOrganizationModal} toggle={() => setShowDeleteOrganizationModal(!showDeleteOrganizationModal)}>
        <ModalHeader>Delete Organization</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleName">Password</Label>
              <Input 
                value={currentPassword}
                onChange={({ target }) => setCurrentPassword(target.value)}
                type="password"
                />
            </FormGroup>
            <FormGroup>
              <Label for="exampleName">Type your organization name: { organization.name }</Label>
              <Input
                value={orgName}
                onChange={({ target }) => setOrgName(target.value)}
                type="text" 
                />
            </FormGroup>
            <p>This action cannot be reversed</p>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button color={'falcon-danger'} className="mr-2" onClick={deleteOrganization}>
            Delete Organization
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={returnToRegistrationModal} backdrop='static'>
        <ModalHeader> We're sad to see you go!</ModalHeader>
        <ModalBody>
          <Fragment>
            <img className="d-block mx-auto mb-4" src={rocket} alt="shield" width={70} />
              <p>
                Your organization has been successfully deleted.
              </p>
            <Button color="primary" size="sm" className="mt-3" onClick={returnToRegistration}>
              <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
                Return to Registration
            </Button>
          </Fragment>
        </ModalBody>
      </Modal>
      </>
  )
}

DeleteOrganizationCard.protoTypes = {
  organization: PropTypes.object.isRequired,
  setOrganization: PropTypes.func.isRequired
}

export default DeleteOrganizationCard;