import React from "react";
import Icon from "@iconify/react";
import wrappedGift from "@iconify-icons/twemoji/wrapped-gift";
import { Link } from "react-router-dom";

const TrialLabel = ({...rest}) => (
  <div className="alert alert-info" role="alert" {...rest}>
    Currently on the Free Plan, <Link to="/settings/billing">upgrade now</Link> to
    unlock staff and client seats
    <Icon icon={wrappedGift} className="ml-2" style={{ width: "1.5rem", height: "1.5rem" }}/>
  </div>
);

export default TrialLabel;