import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, Button} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { get, edit } from '../../api/user.api';
import { toast } from 'react-toastify';
import ContactInformationCard from '../../components/clienttable/ContactInformationCard';
import ManageAccountCard from '../../components/clienttable/ManageAccountCard';
import LoginAndPasswordCard from '../../components/clienttable/LoginAndPasswordCard';
import logger from '../../utils/logger';

const StaffProfile = () => {
  const { id } =  useParams();

  const defaultUserData = {    
    id: id,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    phone: "",
    email: "",
    street: "",
    unitNumber: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    lastActivity: null,
    setupCompletedAt: null,
  }

  const [user, setUser] = useState(defaultUserData)

  useEffect(() => {
    getUserDetails()
  }, [])

  const getUserDetails = async () => {
    setUser(defaultUserData)
    const result = await get(id)
    setUser(result.data)
  }

  const submitChanges = async e => {
    e.preventDefault();
    try {
      await edit(user)
      toast.success(`Successfully edited user`)
    } catch (err) {
      logger.warn(err, `Error while editing user with userId ${user.id}`)
      toast.error(err);
    }
  }

  return (
    <>
      <Breadcrumb style={{paddingLeft: "1rem"}}>
        <BreadcrumbItem>
          <Link to="/settings">Settings</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/settings/staff">Manage Staff</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Staff Profile</BreadcrumbItem>
      </Breadcrumb>
      <h3 style={{paddingLeft: "1rem"}}>{user.firstName} {user.lastName}</h3> 

      <ContactInformationCard user={user} setUser={setUser}/>
      <LoginAndPasswordCard user={user} setUser={setUser}/>
      <ManageAccountCard user={user}/>

      <div style={{marginBottom:"20px"}}>
        <Button color={'primary'} size="sm" className="px-4 ml-2" onClick={getUserDetails}>
          Cancel
        </Button>
        <Button color={'primary'} size="sm" className="px-4 ml-2" onClick={submitChanges}>
          Save
        </Button>
      </div>
    </>
  )
}

export default StaffProfile;