import React, { useContext, useEffect, useState } from 'react';
import KanbanContainer from './KanbanContainer';
import KanbanProvider from './KanbanProvider';

const Kanban = () => {
  return (
    <>
      <KanbanProvider>
        <KanbanContainer />
      </KanbanProvider>
    </>
  );
};

export default Kanban;
