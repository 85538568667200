import React, { Fragment, useEffect, useState } from 'react';
import SessionService from '../../services/SessionService';
import PageHeader from '../../components/clienttable/PageHeader';
import StorageConnectionErrorOwner from '../../components/clienttable/common/StorageConnectionErrorOwner';
import options from '../../data/clienttable/options';
import ListGroup from 'reactstrap/lib/ListGroup';
import SettingsOption from '../../components/clienttable/settings/SettingsOption';
import SetupChecklistCard from '../Settings/SetupChecklistCard';
import TrialAlert from '../../components/clienttable/alerts/TrialAlert';
import BOVerificationAlert from '../../components/clienttable/alerts/BOVerificationAlert';
import { checkBoEmailVerified } from '../../api/user.api';

const Settings = () => {
  return (
    <div className="flex-column">
      {options.map((option, index) =>
        <ListGroup>
          <SettingsOption {...option} key={'setting-option-' + index} />
        </ListGroup>
      )}
    </div>
  )
}

const Dashboard = () => {
  const user = SessionService.getUser()
  const organization = SessionService.getOrganization()
  const shouldDisplayConnectionError = (user && user.role === 'OWNER') && organization.connection_state == "DISCONNECTED";

  const [displayVerifyAlert, setDisplayVerifyAlert] = useState(false);

  const shouldDisplayVerifyAlert = async () => {
    if (user.role === 'OWNER') {
      const res = await checkBoEmailVerified();
      const { isBOemailVerified } = res.data;
      setDisplayVerifyAlert(!isBOemailVerified);
    } else {
      setDisplayVerifyAlert(false);
    }
  }

  useEffect (() => {
    shouldDisplayVerifyAlert();
  }, [])

  return (
    <Fragment>
      {shouldDisplayConnectionError ? <div className="mb-2"><StorageConnectionErrorOwner /></div> : null}
      {/* <Alert color={'warning'}>We sent an email to user@email.com. Please verify your account by clicking the link in your email (re-send)</Alert> */}
      <TrialAlert
        hidden={ organization.plan != 'FREE' || user.role != 'OWNER'}/>
      
      {displayVerifyAlert ? <BOVerificationAlert/> : <></>}
      
      <PageHeader
        title={`Welcome, ${user.firstName} ${user.lastName}`}
        className="mb-3" />
      <Settings />
      <SetupChecklistCard/>
      {/* <ActiveClientsCard clientData={clientData}/>
      <DocumentsCard documents={documents}/> */}
    </Fragment>
  );
};

export default Dashboard;
