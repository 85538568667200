import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import StaffTable from '../../components/clienttable/tables/StaffTable';
import { deleteUser, deleteManyUsers, getAllStaff, checkBoEmailVerified } from '../../api/user.api';
import { toast } from 'react-toastify';
import AddUserModal from '../../modals/AddUserModal';
import DeleteUserModal from '../../modals/DeleteUserModal';
import TrialAlert from "../../components/clienttable/alerts/TrialAlert";
import StaffDetailsCard from "./StaffDetailsCard";
import SubscriptionsApi from "../../api/subscriptions.api";
import staffColumns from "../../data/clienttable/tables/staff";
import SessionService from "../../services/SessionService";
import BOVerificationAlert from '../../components/clienttable/alerts/BOVerificationAlert';

const ManageStaff = () => {
  const [staff, setStaff] = useState([]);
  const [isAddUserModalOpen, setOpenAddUserModal] = useState(false);
  const [deleteUserInfo, setDeleteUserInfo] = useState({
    showRemoveUserModal: false,
    userIds: null
  })
  const [maxStaff, setMaxStaff] = useState(0);
  const [displayVerifyAlert, setDisplayVerifyAlert] = useState(false);
  const organization = SessionService.getOrganization();
  const user = SessionService.getUser();

  useEffect(() => {
    refreshView()
    setMaxStaff(organization.max_staff);
    shouldDisplayVerifyAlert();
  }, [])

  const shouldDisplayVerifyAlert = async () => {
    if (user.role == 'OWNER'){
      const res = await checkBoEmailVerified();
      const { isBOemailVerified } = res.data;
      setDisplayVerifyAlert(!isBOemailVerified);
    }
  }

  const onNew = async () => {
    if (staff.length < maxStaff) setOpenAddUserModal(true)
    else toast.error('Staff account limit has been reached')
  }

  const addUserClose = () => {
    setOpenAddUserModal(false)
    refreshView()
  }

  const onDeleteOne = async (ids) => {
    setDeleteUserInfo({showRemoveUserModal:true, userIds: ids})
  }

  const onDeleteConfirm = async () => {
    if (deleteUserInfo.userIds.length > 1) {
      await deleteManyUsers(deleteUserInfo.userIds)
    } else {
      await deleteUser(deleteUserInfo.userIds)
    }
    setDeleteUserInfo({showRemoveUserModal:false, userIds: null})
    toast.success(`Successfully deleted user`);
    refreshView()
  }

  const onDeleteMany = async (ids) => {
    await deleteManyUsers(ids)
    toast.success(`Successfully delete selected users`);
    refreshView()
  }

  const refreshView = async (filters = {}) => {
    const result = await getAllStaff({ filters })
    setStaff(result.data || [])
  }

  const onRefresh = (filters) => {
    refreshView(filters)
  }

  return (
    <>
      <Breadcrumb style={{paddingLeft: "1rem"}}>
        <BreadcrumbItem>
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Manage Staff</BreadcrumbItem>
      </Breadcrumb>
      <h3 style={{paddingLeft: "1rem"}}>Manage Staff</h3>
      <TrialAlert
        hidden={organization.plan != 'FREE'}/>
      
      {displayVerifyAlert ? <BOVerificationAlert /> : null }
      
      <StaffDetailsCard
        maxStaff={maxStaff}
        currentStaff={staff.length}
        className="mb-3"
      />
      <StaffTable
        showHeaders={true}
        showSelectCheckbox
        disableButtons = {displayVerifyAlert}
        showDelete
        showSearch
        onNew={onNew}
        onDeleteOne={onDeleteOne}
        onDeleteMany={onDeleteMany}
        onRefresh={onRefresh}
        clients={staff}
        columns={staffColumns}
      />
      <AddUserModal isOpen={isAddUserModalOpen} onClose={addUserClose} role="STAFF"/>
      <DeleteUserModal isOpen={deleteUserInfo.showRemoveUserModal} onDelete={onDeleteConfirm} onClose={() => setDeleteUserInfo({showRemoveUserModal:false, userIds:null})}/>
    </>
  )
}

export default ManageStaff;