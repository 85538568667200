//===============================
// Document Page Helpers
//===============================

import React from "react";
import { Icon } from "@iconify/react";

import driveApi from "../../api/drive.api";
import ButtonIcon from "../../components/common/ButtonIcon";
import { isEmpty } from "lodash";
import SessionService from "../../services/SessionService";
import { ProgressToast, ProgressUpdateToast } from "../../components/clienttable/ProgressToast";
import SuccessToast from "../../components/clienttable/SuccessToast";
import { toast } from "react-toastify";
import { get } from '../../api/organization.api';
import iconData from '../../data/clienttable/icons';
import warningFilled from '@iconify/icons-ant-design/warning-filled';
import logger from "../../utils/logger";
import amplitude from 'amplitude-js';

// Max file size for which upload is supported.
const FILE_UPLOAD_LIMIT_MB = 100;

// Time out after which to report to the user that upload failed. Set to 5
// minutes.
const FILE_UPLOAD_TIMEOUT_MS = 300000;

export const getIcon = (data) => {
  return (
    <Icon icon={data.icon} width={data.size} color={data.color} />
  );
};

export const getDefaultIcon = (label) => {
  return (
    <div className='d-flex flex-row justify-content-center align-items-center position-relative'>
      <div className='d-flex flex-column' style={{ fontSize: "22px" }}>
        {getIcon(iconData.fileIcon)}
        <span className='fs--1 semibold align-self-center' style={{ fontSize: "7px", bottom: "3px", position: "absolute" }}>
          {label}
        </span>
      </div>
    </div>
  );
};

export const generateIcon = (item) => {
  // Folder - shared or unshared
  if (item.isFolder) {
    return getIcon(item.isShared ? iconData.sharedFolderIcon : iconData.folderIcon);
    // File has a mimetype
  } else if (iconData.mimeTypeIcons.hasOwnProperty(item.mimeType)) {
    return getIcon(iconData.mimeTypeIcons[item.mimeType]);
    // File doesnt have a mimetype, use default
  } else {
    const regex = /\.([A-Za-z0-9]+$)/
    const match = item.name.match(regex);
    return getDefaultIcon(match ? match[1] : '');
  }
};

export const isDriveConnected = async () => {
  const sessionOrg = SessionService.getOrganization()
  const resp = await get(sessionOrg.id)
  var org = resp.data
  return (org.provider != null || org.temp_provider != null)
}

export const uploadFiles = async (files, folderId, toastId) => {
  // Make sure drive is connected.
  var isConnected = await isDriveConnected();
  if (!isConnected) {
    toast.error("Upload failed: No drive connected");
    return
  }

  // Make sure the files are appropriate for upload.
  for (let file of files) {
    if (!file) {
      toast.error("Upload failed: Unsupported file type")
      return
    }

    const fileSizeInMB = file.size / (1024 * 1024)
    if (fileSizeInMB > FILE_UPLOAD_LIMIT_MB) {
      toast.error("Upload failed: file sizes greater than 100MB are not supported")
      return
    }
    file.parsedPath = file.path.split('/'); /* Array format for easier parsing */
    if (file.parsedPath.length > 1) file.parsedPath.shift();  /* Removes first element (will always be an empty string, so it's not used) */
  }

  ProgressToast({ toastId, message: `Uploading...`});
  const failedUploads = await _uploadFoldersRec({ '': folderId }, files, toastId, folderId);

  if (failedUploads.length) {
    let strOfFailedUploads = () => {
      return (
        <>
          <Icon icon={warningFilled} className="ml-2 mr-2 text-danger" style={{ width: "1.5rem", height: "1.5rem" }} />
          <b>Item(s) failed to upload:</b>
          <ul>
            {failedUploads.map((item, idx) => {
              return (<li key={idx}> {item} </li>);
            })}
          </ul>
        </>
      )
    }

    toast.dismiss(toastId.current)

    toast(strOfFailedUploads, {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: 1,
    });
    return false;
  } else {
    SuccessToast({ toastId, message: `Upload Complete` });
    return true
  }
}

/**
 * Allows folders and files to be uploaded, recursive function
 * @param {object} parentFolderIds Object that maps a folder name to their folder id
 * @param {list} files 
 * @param {string} toastId 
 * @param {function} reloadView 
 * @param {string} fId Folder id of folder that is currently being viewed by the user

   (private)
 * @param {int} depth Number of entries (starting from the left) of the path
 * @param {int} fileNum How many files that have previously been uploaded + 1
 * @param {list} failedIndices List of indices of the files list that previously failed to upload
 * @returns list of names of folders/files (strings) that have failed to upload
 */
const _uploadFoldersRec = async (parentFolderIds, files, toastId, fId, depth = 0, fileNum = 1, failedIndices = []) => {
  let newParentFolderIds = [];
  let didUploadAnItem = false;
  let failedFolders = {};
  let failedItems = [];

  for (var i = 0; i < files.length; i++) {
    const file = files[i];

    if (depth >= file.parsedPath.length) continue;

    const itemName = file.parsedPath[depth];
    const parentFolderId = _getItemParentFolderId(depth, parentFolderIds, file.parsedPath);

    if (_itemFailed(itemName, parentFolderId, failedFolders, failedIndices, i)) continue;

    if (newParentFolderIds[itemName] != null) continue;

    didUploadAnItem = true;

    if (_itemIsFolder(file, depth)) {

      // Create folder in drive
      try {
        const newFolder = await driveApi.createFolder(itemName, parentFolderId);
        newParentFolderIds[itemName] = newFolder.data.folderId;
      } catch (e) {
        logger.error(e, 'There was an error while creating a folder')
        failedFolders[itemName] = parentFolderId;
        failedIndices.push(i);
        failedItems.push(itemName);
      }

    } else {
      // Update toast
      ProgressUpdateToast({ toastId, message: `Uploading ${fileNum} of ${files.length}` });
      fileNum++;

      // Assumed item is file. Then upload file to parent drive
      try {
        await _uploadFile(file, parentFolderId);
      } catch (e) {
        logger.error(e, 'There was an error while uploading a file')
        failedItems.push(itemName);
      }
    }
  }

  if (!didUploadAnItem) return []; /* Max depth reached (Recursion base case) */

  return failedItems.concat(await _uploadFoldersRec(newParentFolderIds, files, toastId, fId, depth + 1, fileNum, failedIndices));
}

const _itemIsFolder = (file, depth) => {
  return file.parsedPath.length - 1 !== depth;
}

const _getItemParentFolderId = (depth, parentFolderIds, path) => {
  if (depth - 1 < 0) {
    return parentFolderIds['']; /* Root folder */
  } else {
    return parentFolderIds[path[depth - 1]];
  }
}

const _uploadFile = async (file, folderId) => {
  const formData = new FormData();
  formData.append("file", file);
  await driveApi.upload(folderId, formData);
  const amplitudeInstance = amplitude.getInstance();
  if (amplitudeInstance){
    amplitudeInstance.logEvent("File uploaded");
  }
}

const _itemFailed = (itemName, parentFolderId, failures, failedIndices, i) => {
  if (failedIndices.includes(i)) {
    return true;
  } else if (failures[itemName] && failures[itemName] == parentFolderId) {
    failedIndices.push(i);
    return true;
  } else {
    return false;
  }
}

export const downloadFiles = async (files, toastId) => {
  // Make sure the files are appropriate for download.
  for (let file of files) {
    if (!file) {
      toast.error("Download failed: Unsupported file type")
      return false
    }
  }

  // Files are valid, we should now download them from the server.
  var successMsg = "Download Complete"
  for (var i = 0; i < files.length; i++) {
    var file = files[i]
    var progressMsg = `Downloading ${file.name}`
    if (files.length > 1) {
      progressMsg = `Downloading ${i + 1} of ${files.length}`
    }

    try {
      // For multiple files, update the same toast with the progress.
      if (i > 0) {
        ProgressUpdateToast({ toastId, message: progressMsg });
      } else {
        ProgressToast({ toastId, message: progressMsg });
      }

      const response = await driveApi.download(file.id);
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      a.click();
    } catch (error) {
      toast.error("Download failed");
      return false
    }
  }

  SuccessToast({ toastId, message: successMsg });
};

//To open pdf files.
export const openPdfFile = (blob) => {
  let pdfBlob = new Blob([blob], { type: "application/pdf" });

  const data = window.URL.createObjectURL(pdfBlob);
  window.open(data);
  //Needed to get the function to work in older versions of Firefox???
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
};

// To open image files
export const getImageUrl = (blob) => {
  const imgUrl = window.URL.createObjectURL(blob);
  return imgUrl;
};

//Take a string that represents the filesize, and returns a number
//representing the file size in KB.
export const getFileSize = (aFileSizeStr) => {
  let fileSizeInKb = 0;
  let sizeNum = 0;
  let sizeUnit = "";
  const size = aFileSizeStr.split(" ");
  sizeNum = size[0];
  sizeUnit = size[1];

  switch (sizeUnit) {
    case "B":
      fileSizeInKb = (sizeNum * 1.0) / 1024;
      break;
    case "KB":
      fileSizeInKb = sizeNum * 1;
      break;
    case "MB":
      fileSizeInKb = sizeNum * 1024;
      break;
    case "GB":
      fileSizeInKb = sizeNum * 1024 * 1024;
      break;
  }

  return fileSizeInKb;
};

// todo get rid of this and make it unified accross all three pages
export const Item = ({ itemName, onClick, disabled, hidden, icon, noline }) => {
  return (
    <div className='mx-1'>
      <ButtonIcon
        className='rounded-capsule mr-1'
        color='falcon-primary'
        transform='shrink-3'
        size='sm'
        onClick={onClick}
        disabled={disabled}
        hidden={hidden}
        icon={icon}
      >
        {itemName}
      </ButtonIcon>
    </div>
  );
};

export const calculateDisabledInfo = (files, displayingMoveCard, selectedItems, levels, file, parentId) => {

  const isFileAndFolderSelected2 = () => {
    const folder = files.find((item) => selectedItems.indexOf(item.id) > -1 && item.isFolder === true);
    const file = files.find((item) => selectedItems.indexOf(item.id) > -1 && item.isFolder !== true);
    return !isEmpty(folder) && !isEmpty(file);
  };

  const singleItemSelected = selectedItems.length == 1
  const noItemsSelected = isEmpty(selectedItems)

  const user = SessionService.getUser()
  const isClient = user && user.role === 'CLIENT';
  const isClientInRootFolder = isClient && levels && levels.length === 1 && levels[0].id === parentId;

  const isFolderSelected = file && file.isFolder

  return {
    isDownloadDisabled: displayingMoveCard || noItemsSelected || isFileAndFolderSelected2() || isFolderSelected,
    isPreviewDisabled: displayingMoveCard || !singleItemSelected || isFolderSelected,
    isShareDisabled: displayingMoveCard || !singleItemSelected,
    isRenameDisabled: displayingMoveCard || !singleItemSelected || isClientInRootFolder,
    isDuplicateDisabled: displayingMoveCard || !singleItemSelected || isClientInRootFolder,

    isDeleteDisabled: displayingMoveCard || noItemsSelected || isClientInRootFolder,
    isMoveDisabled: displayingMoveCard || noItemsSelected || isClientInRootFolder,
  }
}