import React from "react"
import { Redirect, Route } from "react-router-dom"
import PropTypes from 'prop-types';
import SessionService from "../services/SessionService";
import { useEffect } from "react";
import { useState } from "react";
import { checkActiveSession } from "../api/user.api";
import Loading from "../pages/Loading/Loading";
import { ConversationItem } from "../pages/Messages/features/joinedConversations/ConversationItem";
import NotificationService from "../services/NotificationService";

const ProtectedRoute = props => {

  const user = SessionService.getUser(); // todo look into getting this from the server
  const [isLoading, setIsLoading] = useState(true);

  const checkSession = async () => {
    setIsLoading(true);
    await checkActiveSession(); // todo make more effecient, we make a call to server evertime a user switches routes
    setIsLoading(false);
  }

  useEffect(() => {
    checkSession();
    ConversationItem.unreadCount = 0;
    NotificationService.notifyTitle(0);
  }, [props.component])


  if (isLoading) return <Loading />;
  if (!user) {
    return <Redirect to= "/login"/>
  }
  // allow owners and staff always
  if (user.role == "OWNER" ||
      (user.role == "STAFF" && props.allowStaff) ||
      (user.role == "CLIENT" && props.allowClient)) {
    return <Route {...props} />
  }

  return <Redirect to="/404" />
}

// ProtectedRoute.PropTypes = {
  // todo add PropTypes
// };

export default ProtectedRoute;