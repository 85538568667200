import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getCurrentConversationId } from "../currentConversationModel";
import { getConversationMembersView } from "../../../features/layout/Selectors";
import { FlexRow } from "../../../foundations/components/layout";
import {
  conversationMembersViewDisplayed,
  conversationMembersViewHidden
} from "../../../features/layout/LayoutActions";
import {
  getMembersCountByConversationId,
  ConversationMembersCount
} from "../../../features/conversationMembers/conversationMemberCountModel";

import teamOutlined from "@iconify/icons-ant-design/team-outlined";
import { Icon } from "@iconify/react";
import { Button } from "reactstrap";

export interface ConversationOccupancyFragment {
  joinedCount: number;
}

export const getCurrentConversationOccupancy = createSelector(
  [
    getCurrentConversationId,
    getMembersCountByConversationId
  ],
  (
    currentConversationId: string,
    conversationMembersCount: ConversationMembersCount
  ): ConversationOccupancyFragment => {
    const count = conversationMembersCount[currentConversationId];
    return {
      joinedCount: count || 0,
    };
  }
);

const ConversationOccupancy = () => {
  const {
    joinedCount,
  }: ConversationOccupancyFragment = useSelector(
    getCurrentConversationOccupancy
  );
  const viewConversationMembers = useSelector(getConversationMembersView);
  const dispatch = useDispatch();

  return (
    <FlexRow
      alignSelf="flex-end"
      flexDirection={["column-reverse", "row"]}
      onClick={async () => {
        if (viewConversationMembers) {
          await dispatch(conversationMembersViewHidden());
        } else {
          await dispatch(conversationMembersViewDisplayed());
        }
      }}
    >
      <Button outline color="primary" size="sm" className="p-0">
        <Icon style={{height: "25px", width: "25px"}}icon={teamOutlined} />
      </Button>
    </FlexRow>
  );
};

export { ConversationOccupancy };
