import React, { FunctionComponent } from "react";
import Styled, { css } from "styled-components/macro";
import emojiRegex from "emoji-regex";

export enum TextMessageSizes {
  BIG = "BIG"
}

interface TextWrapperProps {
  /** Specify a Message size */
  size?: TextMessageSizes | false;
}

interface TextMessageProps extends TextWrapperProps {
  /** Display a text copy */
  text: string;
  direction: "left" | "right";
}

const BigMessage = css`
  font-size: ${p => p.theme.fontSizes.large};
`;

const Wrapper = Styled.div<TextWrapperProps>`
  border-radius: 4px;
  color: ${p => p.theme.colors.messageText};
  line-height: 1.5;
  text-align: left;
  white-space: pre-wrap;
  width: fit-content;
  word-break: break-word;

  ${props => props.size === TextMessageSizes.BIG && BigMessage}
`;

// Check if there are only 3 or less emoji in the message
const isEmphasized = (msg: string): boolean => {
  const trimmed = msg.trim();
  if (trimmed.length <= 15) {
    const emoji = msg.match(emojiRegex());
    return emoji ? emoji.length <= 3 && emoji.join("") === trimmed : false;
  } else {
    return false;
  }
};

export const TextMessage: FunctionComponent<TextMessageProps> = ({
  text,
  size,
  direction,
  ...rest
}) => {
  return (
    <Wrapper
      size={size || (isEmphasized(text) ? TextMessageSizes.BIG : undefined)}
      className={`p-2 ${direction == "left" ? "bg-200 text-black" : "bg-primary text-light"}`}
      {...rest}
    >
      {text}
    </Wrapper>
  );
};
