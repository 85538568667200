import React, { ReactNode, useState } from "react";
import useHover from "../../../foundations/hooks/useHover";
import { FlexColumn, FlexRow } from "../../../foundations/components/layout";
import { MessageDisplay } from "../../../features/messages/MessageDisplay";
import { AppMessage } from "../../../features/messages/messageModel";
import moment from "moment";
import { Avatar, AvatarVariants } from "../../../foundations/components/chat";

// TODO: Explain message fragment
export interface MessageFragment {
  sender: {
    id: string;
    name: string;
  };
  timetoken: string;
  message: AppMessage;
}

interface MessageProps {
  messageFragment: MessageFragment;
  avatar: ReactNode;
  readerId: number;
  displayAvatar: boolean;
  displayFooter: boolean;
}

/**
 * Display a message as it appears in a list
 */
const MessageListItem = ({ messageFragment, avatar, readerId, displayAvatar, displayFooter }: MessageProps) => {
  const sender = messageFragment.sender;
  const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
  const [clickedToShowFooter, setClickedToShowFooter] = useState(false);

  // Am I the sender?
  const isSender = sender.id === readerId.toString();

  return (
    <FlexRow
      paddingTop={displayAvatar ? "0.5rem" : "0.1rem"}
      paddingBottom={(clickedToShowFooter || displayFooter) ? "0.5rem" : "0.1rem" }
      px={[1, 6]}
      alignItems={!isSender ? "flex-start" : "flex-end"}
      bg={isHovering ? "backgrounds.contentHover" : ""}
      onClick={() => {
        setClickedToShowFooter(!clickedToShowFooter)
      }}
      {...hoverProps}
    >
      {!isSender && displayAvatar && <div className="mr-2">{avatar}</div>}
      {!isSender && !displayAvatar &&
        <div className="mr-2">
          <Avatar
            variant={AvatarVariants.ROUND}
            bg={isHovering ? "backgrounds.contentHover" : "#fff"}
          >
          </Avatar>
        </div>
      }
      <FlexColumn
        flexGrow={1}
        alignItems={!isSender ? "flex-start" : "flex-end"}
      >
        <MessageDisplay
          message={messageFragment.message}
          parentKey={messageFragment.timetoken}
          key={messageFragment.timetoken}
          direction={!isSender ? "left" : "right"}
        ></MessageDisplay>
        { (clickedToShowFooter || displayFooter) &&
          <span className="text-secondary">
            {!isSender && `${sender.name} `}{moment(new Date(parseInt(messageFragment.timetoken)/10000)).format("h:mm A")}
          </span>
        }

      </FlexColumn>
    </FlexRow>
  );
};

export { MessageListItem };
