import checkOutlined from '@iconify/icons-ant-design/check-outlined';
export default [
  {
    icon: checkOutlined,
    title: "Unlimited clients"
  },
  {
    icon: checkOutlined,
    title: "Remove ClientTable branding"
  },
  {
    icon: checkOutlined,
    title: "Add staff accounts"
  }
];