import { nameFormatter, dateFormatter } from '../../../utils/formatter';
const staffColumns = [
  {
    dataField: 'name',
    text: 'Name',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    formatter: nameFormatter
  },
  {
    dataField: 'email',
    text: 'Email',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
  },
  {
    dataField: 'lastActivity',
    text: 'Last Activity',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: dateFormatter
  },
];

export default staffColumns;