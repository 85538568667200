import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { fetchUserData, fetchMemberships, setMemberships, Message } from "pubnub-redux";
import { ChannelMembershipObject } from "pubnub";
import { MembershipsRetrievedAction } from "pubnub-redux/dist/features/membership/MembershipActions";
import { ThunkAction } from "../../../main/storeTypes";
import { refreshPermissions } from "../../../../../api/messages";
import SessionService from '../../../../../services/SessionService';

// Pagination
import { getMembershipsPaginationStateById } from "../../pagination/Selectors";
import { setMembershipsPagination } from "../../pagination/PaginationActions";
import {
  usePagination,
  GetNextPage,
  SavePaginationState
} from "../../../foundations/hooks/usePagination";

// Models
import { MembershipHash, MembershipCustom } from "../joinedConversationModel";
import { getConversationsByUserId } from "../joinedConversationModel";
import { getLoggedInUserId } from "../../authentication/authenticationModel";
import {
  ConversationsIndexedById,
  getConversationsById
} from "../../conversations/conversationModel";
import {
  focusOnConversation,
  getCurrentConversationId,
} from "../../currentConversation/currentConversationModel";

import { getLatestMessagesById, LatestMessageEnvelope } from "../../messages/lastMessageModel"

// UI
import {
  ScrollView,
} from "../../../foundations/components/layout"
import {
  composeViewHidden,
  menuViewHidden,
  conversationMembersViewHidden,
  conversationTasksViewHidden,
  addMembersViewHidden,
  editTaskViewHidden,
  currentConversationHeaderViewDisplayed,
  currentConversationViewDisplayed,
  composeViewDisplayed,
  menuViewDisplayed,
  currentConversationHeaderViewHidden,
  currentConversationViewHidden,
} from "../../layout/LayoutActions";
import { getComposeView } from "../../layout/Selectors";
import { ConversationItem } from "../ConversationItem";
import { latestMessage } from "../../../features/messages/lastMessageModel"

const MESSAGING_REFRESH_INTERVAL_SECONDS = 5 * 60;

export interface ConversationFragment {
  id: string;
  name: string;
  custom: {
    owner: string;
    chat: boolean;
  };
}

// directChatMember fetches the profile of the other member given the current member id.
export function directChatMember(userId, chatName, chatProfileList) {
  const chatIds = chatName.split('-');
  const chatMember = chatIds[0].toString() === userId.toString() ?
    chatProfileList[chatIds[1]] :
    chatProfileList[chatIds[0]];

  return chatMember;
}

// directMessageName displays the name of the other chat member given the current member id.
export function directMessageName(userId, chatName, chatProfileList) {
  const chatMember = directChatMember(userId, chatName, chatProfileList)

  return chatMember ? `${chatMember.firstName} ${chatMember.lastName}` : '';
}

export const getJoinedConversations = createSelector(
  [getConversationsById, getLoggedInUserId, getConversationsByUserId],
  (
    conversations: ConversationsIndexedById,
    userId: string,
    userConversations: MembershipHash
  ): ConversationFragment[] => {
    return userConversations[userId]
      ? userConversations[userId].map(conversation => {
        const res = {
          ...conversations[conversation.id],
          id: conversation.id,
          name: conversations[conversation.id] ? conversations[conversation.id].name : "default"
        };

        if (conversations[conversation.id]) {
          if (!("chat" in conversations[conversation.id].custom)) {
            res.custom.chat = false
          }
        }

        return res;
      })
      : [];
  }
);

// Selector for current memberships.
export const getCurrentMemberships = createSelector(
  [getLoggedInUserId, getConversationsByUserId],
  (
    userId: string,
    userConversations: MembershipHash
  ): MembershipCustom[] => {
    return userConversations[userId]
      ? userConversations[userId].map(conversation => {
        const res = {
          ...conversation
        };
        if (conversation.custom === null) {
          res.custom = {
            lastReadMessageTimetoken: -1
          }
        }

        return res;
      })
      : [];
  }
);

type MembershipMap = { [id: string]: { lastReadMessageTimetoken: number } }

// Selector for current memberships in a form that can be indexed by ID.
export const getCurrentMembershipMap = createSelector(
  [getCurrentMemberships],
  (membershipList: MembershipCustom[]): MembershipMap => {
    var res = {}
    for (const membership of membershipList) {
      if (membership) {
        res[membership.id] = membership.custom
        if (!membership.custom) {
          res[membership.id] = {
            lastReadMessageTimetoken: -1
          }
        }
      }
    }

    return res
  }
);

export const getJoinedChats = createSelector(
  [getJoinedConversations],
  (
    conversations: ConversationFragment[],
  ): ConversationFragment[] => {
    return conversations ? conversations.filter(conversation => {
      if (conversation.custom != null) {
        return conversation.custom.chat
      }

      return false
    }) : []
  }
);

export const getJoinedChannels = createSelector(
  [getJoinedConversations],
  (
    conversations: ConversationFragment[],
  ): ConversationFragment[] => {
    return conversations ? conversations.filter(conversation => {
      if (conversation.custom != null) {
        return !conversation.custom.chat
      }

      return false
    }) : []
  }
);


const parseEnvelope = (channel: string, messages: LatestMessageEnvelope[]): Message => {
  if ((!messages) || (messages.length == 0)) {
    return {
      channel,
      message: {}
    }
  }

  return {
    channel,
    message: messages[0]
  }
}

// calculateLastReadMessageTimetoken calculates the time token by looking at the past messages in the conversation.
export const calculateLastReadMessageTimetoken = (channel: string, messages: LatestMessageEnvelope[]): number => {
  var envelope = parseEnvelope(channel.toString(), messages)
  var timetoken = ("timetoken" in envelope.message) ? envelope.message["timetoken"] : -1;

  return timetoken
}

// getLastMessageAuthor gets the last sender by looking at the past messages in the conversation.
export const getLastMessageAuthor = (channel: string, messages: LatestMessageEnvelope[]): string => {
  var envelope = parseEnvelope(channel.toString(), messages)
  var publisher = ("publisher" in envelope.message) ? envelope.message["publisher"] : "";

  return publisher
}

// getLastReadMessageTimetoken fetches the last read timetoken based on what is stored in redux.
export const getLastReadMessageTimetoken = (membershipMap: MembershipMap, channel: string): number => {
  if (membershipMap[channel.toString()]) {
    return membershipMap[channel.toString()].lastReadMessageTimetoken
  }

  return -1
}

// updateSubs updates the permissions and subscriptions of a user. Triggered if the conversation list has been updated (for example: new convo, removed convo)
export const updateSubs = (uuid: string): ThunkAction<Promise<void>> => {
  return (dispatch, getState, context) => {
    const isUpdated = (dispatch(fetchUserData({ uuid })) as any)
      .then(() => {
        // Unsubscribe from all channels first.
        context.pubnub.api.unsubscribeAll();
      })
      .then(() => {
        // Subscribe to the user's channel to receive events involving this user
        context.pubnub.api.subscribe({
          channels: [uuid],
          withPresence: true
        });
      })
      .then(() => {
        const authKey = SessionService.getAccessToken()
        return refreshPermissions(uuid, authKey)
      })
      .then(() => {
        return dispatch(
          // Load the conversations that this user has joined
          fetchMemberships({
            uuid: uuid,
            include: {
              channelFields: true,
              customChannelFields: true,
              customFields: true,
              totalCount: false
            }
          })
        );
      })
      .then(() => {
        // Subscribe to messages on the user's joined conversations
        const conversationChannels = getConversationsByUserId(getState())[
          uuid
        ].map(membership => membership.id);

        context.pubnub.api.subscribe({
          channels: conversationChannels,
          withPresence: true
        });
        return conversationChannels

      })
      .then((channels) => {
        if (channels == null || channels.length === 0) {
          return null
        }
        return context.pubnub.api.fetchMessages({channels: channels, includeMeta: true})
      })
      .then((resp) => {
        if (resp == null) {
          return
        }
        const channels = getConversationsByUserId(getState())[
          uuid
        ].map(membership => membership.id);

        for (const channel of channels) {
          if (!(channel in resp.channels)) {
            continue
          }

          // There are messages for this channel. Update the last read messages for the channels.
          dispatch(latestMessage({
            messages: resp.channels[channel],
            channel: channel,
          }))
        }

        return
      });

    return Promise.all([isUpdated]).then(() => {
    });
  };
};

// markConversationAsRead marks the conversation as read on PubNub.
export const markConversationAsRead = async (currentUserId, currentConversationId, latestMessages, membershipMap, dispatch) => {
  var timetoken = calculateLastReadMessageTimetoken(currentConversationId, latestMessages[currentConversationId.toString()]);
  
  // Before focussing on a new channel, update the last read timestamp for this channel membership so we can figure out the unread indicator.
  var lastReadTimetoken = getLastReadMessageTimetoken(membershipMap, currentConversationId)
  if (lastReadTimetoken !== timetoken) {
    await dispatch(setMemberships({
      uuid: currentUserId,
      channels: [ {
        id: currentConversationId,
        custom: {
          lastReadMessageTimetoken: timetoken
        }
      }],
      include: { // Have to include the custom fields so the reduc state is updated with the custom data.
        customFields: true,
        channelFields: true,
        customChannelFields: true,
        totalCount: true
    }
    }));
  }
}

const MyConversations = ({ searchValue, chatProfileList }) => {
  const currentUserId = useSelector(getLoggedInUserId);
  const currentConversationId: string = useSelector(getCurrentConversationId);
  const conversations: ConversationFragment[] = useSelector(
    getJoinedConversations
  );
  const viewCompose = useSelector(getComposeView);
  const latestMessages = useSelector(getLatestMessagesById);
  const membershipMap = useSelector(getCurrentMembershipMap);
  const dispatch = useDispatch();

  const storedPaginationState = useSelector(getMembershipsPaginationStateById)[
    currentUserId
  ];

  const restorePaginationState = useCallback(() => {
    return storedPaginationState;
  }, [storedPaginationState]);

  const savePaginationState: SavePaginationState<
    string | undefined,
    string
  > = useCallback(
    (channel, pagination, count, pagesRemain) => {
      dispatch(
        setMembershipsPagination(channel, { pagination, count, pagesRemain })
      );
    },
    [dispatch]
  );

  const getNextPage: GetNextPage<
    ChannelMembershipObject<{}, {}>,
    string | undefined,
    string
  > = useCallback(
    async (next, total, uuid) => {
      const pageSize = 20;
      const action = ((await dispatch(
        fetchMemberships({
          uuid,
          limit: pageSize,
          include: {
            channelFields: true,
            customChannelFields: true,
            customFields: true,
            totalCount: true
          },
          page: {
            next: next || undefined
          }
        })
      )) as unknown) as MembershipsRetrievedAction<{}, {}, unknown>;
      const response = action.payload.response;
      return {
        results: response.data,
        pagination: response.next,
        pagesRemain:
          response.data.length > 0 && response.totalCount && total
            ? total + response.data.length < response.totalCount
            : response.data.length === pageSize
      };
    },
    [dispatch]
  );

  useEffect(() => {
    var newConversations = conversations.map(conv => conv.id)
    var oldConversations = SessionService.getChannels();

    // This is so we leave a conversation when removed by someone else. This needs to happen frist so they can't re enter the channel
    // cause permissions will be revoked.
    if (newConversations.length < oldConversations.length) {
      if (window.innerWidth < 960) {
        dispatch(composeViewHidden());
        dispatch(currentConversationHeaderViewHidden());
        dispatch(currentConversationViewHidden());
        dispatch(menuViewDisplayed());
      }
      else {
        dispatch(composeViewDisplayed());
      }
    }

    if (newConversations.sort().toString() !== oldConversations.sort().toString()) {
      dispatch(updateSubs(currentUserId));
      SessionService.setChannels(newConversations);
    }

  }, [conversations])

  useEffect(() => {
    // Figure out whether to refresh or not.
    var currTimetoken = Date.now()
    var lastRefreshTimetoken = SessionService.getMessagingTimetoken();

    if ((lastRefreshTimetoken === -1) || (currTimetoken - lastRefreshTimetoken) > (MESSAGING_REFRESH_INTERVAL_SECONDS * 1000)) {
      dispatch(updateSubs(currentUserId));
      SessionService.setMessagingTimetoken(currTimetoken);
    }
  }, [currentConversationId])

  const { containerRef, endRef } = usePagination(
    getNextPage,
    currentUserId,
    savePaginationState,
    restorePaginationState
  );

  return (
    <>
      {/*
      <FlexRow justifyContent="space-between" mx={6} marginBottom={1}>
        <Heading variant={HeadingVariants.INVERSE}>Conversations</Heading>
        <Icon
          icon={Icons.Add}
          color={"onPrimary"}
          onClick={() => { dispatch(joinConversationViewDisplayed()) }}
          title="Join conversation"
          clickable
        />
      </FlexRow>
      */}

      <ScrollView ref={containerRef}>
        {conversations
          .sort((conversationA, conversationB) => {
            var envelopeA = parseEnvelope(conversationA.id, latestMessages[conversationA.id])
            var envelopeB = parseEnvelope(conversationB.id, latestMessages[conversationB.id])
            var timetokenA = ("timetoken" in envelopeA.message) ? envelopeA.message["timetoken"] : -1;
            var timetokenB = ("timetoken" in envelopeB.message) ? envelopeB.message["timetoken"] : -1;
            if (timetokenA === timetokenB) {
              return 0;
            } else if (timetokenA < timetokenB) {
              return 1;
            } else {
              return -1;
            }
          })
          .map(conversation => (
            conversation && conversation.custom &&
            (conversation.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              (conversation.custom.chat
                && directMessageName(currentUserId, conversation.name, chatProfileList).toLowerCase().includes(searchValue.toLowerCase())))
            &&
            <ConversationItem
              name={conversation.name}
              chatProfileList={chatProfileList}
              selected={conversation.id === currentConversationId}
              key={conversation.id}
              isChat={conversation.custom.chat}
              onClick={async () => {
                // Before focussing on a new channel, update the last read timestamp for this channel membership so we can figure out the unread indicator.
                if (currentConversationId !== "" && !viewCompose) {
                  // Update the current conversation as read.
                  await markConversationAsRead(currentUserId, currentConversationId, latestMessages, membershipMap, dispatch)
                }
                
                // Update the new conversation as read.
                await markConversationAsRead(currentUserId, conversation.id, latestMessages, membershipMap, dispatch)

                await dispatch(focusOnConversation(conversation.id.toString()));
                await dispatch(menuViewHidden());
                await dispatch(conversationMembersViewHidden());
                await dispatch(conversationTasksViewHidden());
                await dispatch(currentConversationViewDisplayed());
                await dispatch(currentConversationHeaderViewDisplayed());
                await dispatch(composeViewHidden());
                await dispatch(addMembersViewHidden());
                await dispatch(editTaskViewHidden());
              }}
              latestMessage={parseEnvelope(conversation.id, latestMessages[conversation.id])}
              lastReadMessageTimetoken={getLastReadMessageTimetoken(membershipMap, conversation.id)}
            ></ConversationItem>)
          )}
        <div ref={endRef} />
      </ScrollView>
    </>
  );
};

export { MyConversations };
