import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Label } from 'reactstrap';

const ProviderButton = ({ icon, onSelect, textColor, provider, isSelected}) => {
  return <div className="m-2 d-flex flex-column text-center">
    <ProviderImage onSelect={onSelect} icon={icon} isSelected={isSelected} />
    <ProviderSelect provider={provider} isSelected={isSelected} onSelect={onSelect} textColor={textColor}/>
  </div>
}

const ProviderImage = ({ onSelect, icon, isSelected }) => {
  return <div
    onClick={onSelect}
    className={`btn btn-falcon-primary p-2 m-1 provider-border ${isSelected ? "selected" : ""}`}>
    <img src={icon} className="provider-img"/>
  </div>
}

const ProviderSelect = ({ provider, isSelected, onSelect, textColor}) => {
  return <Col md={9} className="mb-3 mb-md-0 p-1">
  <div className="custom-control custom-radio radio-select">
    <Input
      className="custom-control-input"
      id={"provider-" + provider.name}
      type="radio"
      value={provider}
      checked={isSelected}
      onChange={onSelect}
    />
    <Label
      className="custom-control-label"
      htmlFor={"provider-" + provider.name}
      style={{color:textColor}}
    >
      {provider.name}
    </Label>
  </div>
</Col>

}

ProviderButton.propTypes = {
  icon: PropTypes.string,
  onSelect: PropTypes.func,
  textColor: PropTypes.string,
  provider: PropTypes.object,
  setProvider: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default ProviderButton;
