import React, {useState, useEffect} from 'react';
import infoCircleOutlined from '@iconify/icons-ant-design/info-circle-outlined';
import Flex from '../Flex';
import { Icon } from '@iconify/react';

const InfoLabel = ({ text, bold=false, ...rest}) => {
  return (
    <Flex inline={true} align="center" {...rest}>
      <Icon icon={infoCircleOutlined} color="#2C7BE5" width="26" />
      {bold ?
      <strong className="mb-0 ml-2">{text}</strong> :
      <p className="mb-0 ml-2">{text}</p>
      }
    </Flex>
  );
};

export default InfoLabel;