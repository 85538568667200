import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import Label from 'reactstrap/es/Label';
import { validatePassword } from '../../../api/user.api';

function ChangeConnectionModal ({showChangeConnectionModal, setShowChangeConnectionModal, setInChangeConnectionFlow}) {
  const [password, setPassword] = useState('');
  
  const onConfirm = (e) => {
    e.preventDefault();
    setShowChangeConnectionModal(!showChangeConnectionModal)
    handleSubmit();
  }

  const handleSubmit = async e => {
    try {
      await validatePassword({ password })
      setInChangeConnectionFlow(true)
    } catch (error) {
      setInChangeConnectionFlow(false)
    }
  }

  return (
    <Modal isOpen={showChangeConnectionModal} toggle={() => setShowChangeConnectionModal(!showChangeConnectionModal)}>
        <ModalHeader className="border-0 text-sans-serif">Change Storage Connection</ModalHeader>
        <ModalBody>
          <Form className="pb-2" onSubmit={(e) => onConfirm(e)}>
          <FormGroup>
            <Label className="text-sans-serif">Confirm Password</Label>
            <Input
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              type="password"
            />
          </FormGroup>
          <div className="text-sans-serif">
            <p className="fs-0 ligh-gray">Your current files will no longer be available in yor portal if you change your drive.</p>
            <p className="fs-0 text-warning">Users will lose access to all files. This cannot be reversed, even if you re-connect the current drive account.</p>
            <Button
              color={'falcon-warning'}
              block
              disabled={!password}
              type="submit"
            >
              Proceed to change
            </Button>
          </div>
        </Form>
        <Button color={'falcon-default'} block onClick={() => { setShowChangeConnectionModal(!showChangeConnectionModal); setPassword('') }}>
            Cancel
          </Button>
        </ModalBody>
      </Modal>
  );
}

ChangeConnectionModal.propTypes = {
  showChangeConnectionModal: PropTypes.bool,
  setShowChangeConnectionModal: PropTypes.func,
  setInChangeConnectionFlow: PropTypes.func
};

export default ChangeConnectionModal;
