export enum LayoutActionType {
  MENU_VIEW_DISPLAYED = "pubnub/MENU_VIEW_DISPLAYED",
  MENU_VIEW_HIDDEN = "pubnub/MENU_VIEW_HIDDEN",
  COMPOSE_VIEW_DISPLAYED = "pubnub/COMPOSE_VIEW_DISPLAYED",
  COMPOSE_VIEW_HIDDEN = "pubnub/COMPOSE_VIEW_HIDDEN",
  NEW_CHANNEL_VIEW_DISPLAYED = "pubnub/NEW_CHANNEL_VIEW_DISPLAYED",
  NEW_CHANNEL_VIEW_HIDDEN = "pubnub/NEW_CHANNEL_VIEW_HIDDEN",
  EDIT_CHANNEL_VIEW_DISPLAYED = "pubnub/EDIT_CHANNEL_VIEW_DISPLAYED",
  EDIT_CHANNEL_VIEW_HIDDEN = "pubnub/EDIT_CHANNEL_VIEW_HIDDEN",
  CURRENT_CONVERSATION_HEADER_VIEW_DISPLAYED = "pubnub/CURRENT_CONVERSATION_HEADER_VIEW_DISPLAYED",
  CURRENT_CONVERSATION_HEADER_VIEW_HIDDEN = "pubnub/CURRENT_CONVERSATION_HEADER_VIEW_HIDDEN",
  CURRENT_CONVERSATION_VIEW_DISPLAYED = "pubnub/CURRENT_CONVERSATION_VIEW_DISPLAYED",
  CURRENT_CONVERSATION_VIEW_HIDDEN = "pubnub/CURRENT_CONVERSATION_VIEW_HIDDEN",
  CONVERSATION_MEMBERS_VIEW_DISPLAYED = "pubnub/CONVERSATION_MEMBERS_VIEW_DISPLAYED",
  CONVERSATION_MEMBERS_VIEW_HIDDEN = "pubnub/CONVERSATION_MEMBERS_VIEW_HIDDEN",
  CONVERSATION_TASKS_VIEW_DISPLAYED = "pubnub/CONVERSATION_TASKS_VIEW_DISPLAYED",
  CONVERSATION_TASKS_VIEW_HIDDEN = "pubnub/CONVERSATION_TASKS_VIEW_HIDDEN",
  JOIN_CONVERSATION_VIEW_DISPLAYED = "pubnub/JOIN_CONVERSATION_VIEW_DISPLAYED",
  JOIN_CONVERSATION_VIEW_HIDDEN = "pubnub/JOIN_CONVERSATION_VIEW_HIDDEN",
  ADD_MEMBERS_VIEW_DISPLAYED = "pubnub/ADD_MEMBERS_VIEW_DISPLAYED",
  ADD_MEMBERS_VIEW_HIDDEN = "pubnub/ADD_MEMBERS_VIEW_HIDDEN",
  EDIT_TASK_VIEW_DISPLAYED = "pubnub/EDIT_TASK_VIEW_DISPLAYED",
  EDIT_TASK_VIEW_HIDDEN = "pubnub/EDIT_TASK_VIEW_HIDDEN",
  LEAVE_CONFIRMATION_VIEW_DISPLAYED = "pubnub/LEAVE_CONFIRMATION_VIEW_DISPLAYED",
  LEAVE_CONFIRMATION_VIEW_HIDDEN = "pubnub/LEAVE_CONFIRMATION_VIEW_HIDDEN",
}
