import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import { getSubDomain } from '../utils/util';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import UserProvider from '../components/clienttable/providers/UserProvider';

import loadable from '@loadable/component';
import LoginUsingToken from '../components/auth/LoginUsingToken';
import VerifyEmailScreen from '../components/auth/VerifyEmailScreen';
import Filedrop from '../pages/Filedrop/Filedrop';
const Landing = loadable(() => import('../components/landing/Landing'));
const AuthRoutes = loadable(() => import('../components/auth/AuthRoutes'));
const LocatorRoutes = loadable(() => import('../components/clienttable/locator/LocatorRoutes'));
const Registration = loadable(() => import('../components/auth/Registration'));
const RegistrationConfirmation = loadable(() => import('../components/auth/RegistrationConfirmation'));

const Layout = () => {
  useEffect(() => {
    Landing.preload();
    AuthRoutes.preload();
    Registration.preload();
    LocatorRoutes.preload();
  }, []);

  const subdomain = getSubDomain()

  return (
    <div>
      <UserProvider>
        <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_CENTER} />
        <Router fallback={<span />}>
          <QueryParamProvider ReactRouterRoute={Route}>
            {subdomain ?
              <Switch>
                <Route path="/logintoken/:logintoken" exact component={LoginUsingToken} />
                <Route path="/verify/:code" exact component={VerifyEmailScreen} />
                <Route path="/landing" exact component={Landing} />
                <Route path="/errors" component={ErrorLayout} />
                <Route path="/authentication" component={AuthRoutes} />
                <Route path="/filedrop" component={Filedrop} />
                <Route component={DashboardLayout} />
              </Switch>
              :
              <Switch>
                <Route path="/locate" component={LocatorRoutes} />
                <Route path={`/authentication/register`} exact component={Registration} />
                <Redirect to="/authentication/register" />
              </Switch>
            }
          </QueryParamProvider>
        </Router>
      </UserProvider>
    </div>
  )

};

export default Layout;
