import React from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import FalconCardHeader from '../../components/clienttable/FalconCardHeader';

const PlanDetailsCard = ({ currentPlan, maxStaff, maxClients, onManageSubscriptions, showManageSubscriptions, ...rest }) => {
  const detailsData = [
    { title: "Tier:", value: `${currentPlan.charAt(0).toUpperCase()}${currentPlan.slice(1).toLowerCase()}` },
    { title: "Max Staff Users:", value: <>{maxStaff} <b>(and 1 Owner Account)</b></>},
    { title: "Max Client Users:", value: maxClients < 10000 ? maxClients : 'Unlimited'},
  ]

  return (
    <Card {...rest}>
      <FalconCardHeader title="Plan Details" light={false} />
      <CardBody className="bg-light">
        <Container>
          {detailsData.map((detail, index) =>
            <Row className="mb-2">
              <Col className="font-weight-semi-bold pl-0" xs={4}>{detail.title}</Col>
              <Col xs={8}>{detail.value}</Col>
            </Row>
          )}
        </Container>
        {showManageSubscriptions && <>
          <Button className="mt-3 mb-2" onClick={onManageSubscriptions} color={'primary'}>Manage Subscription</Button> <br />
          <em>Add additional staff seats, view payment history, and manage your subscription.</em><br/>
          <em>Learn more on our <a href="https://www.clienttable.com/pricing" target="_blank">pricing page</a>.</em>
        </>}
      </CardBody>
    </Card>
  );
}


export default PlanDetailsCard;