import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Media } from 'reactstrap';
import Avatar from '../clienttable/common/Avatar';
import Flex from '../clienttable/Flex';
import SessionService from '../../services/SessionService';
import { upperCaseFirstLetter } from '../../helpers/strings';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const client = SessionService.getUser()
  const getClientFullName = (firstName, lastName) => {
    firstName = firstName ? upperCaseFirstLetter(firstName) : ''
    lastName = lastName ? upperCaseFirstLetter(lastName) : ''

    return `${firstName} ${lastName}` 
  }
  const clientFullName = getClientFullName(client.firstName, client.lastName)

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Media tag={Flex} align="center">
          <Avatar size="xl" round="circle" name={clientFullName} width="35" height="35"/>
          {(window.innerWidth > 420) && <Media body className="ml-2">
            <h5 style={{marginRight:"auto"}} className="mb-0 fs--1">{clientFullName}</h5>
          </Media>
          }
        </Media>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem tag={Link} to="/authentication/logout">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
