import React, { createRef, useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { Icon } from '@iconify/react';
import sendOutlined from '@iconify-icons/ant-design/send-outlined';
import plusOutlined from '@iconify-icons/ant-design/plus-outlined';
import { useDispatch } from "react-redux";
import composeColumns from "./ComposeColumns";
import SessionService from "../../../../services/SessionService";
import { createChat } from "../../../../api/messages";
import { fetchMemberships } from "pubnub-redux";
import {
  composeViewHidden,
  currentConversationHeaderViewDisplayed,
  currentConversationViewDisplayed,
  newChannelViewDisplayed,
  menuViewDisplayed
} from "../layout/LayoutActions";
import {
  focusOnConversation,
  getCurrentConversationId,
} from "../currentConversation/currentConversationModel";
import { NewChannelModal } from "./NewChannelModal";
import { useSelector } from "react-redux";
import { getComposeView } from "../layout/Selectors";
import { StyledBox } from "../../foundations/components/layout";
import { Icons, Icon as PNIcon } from "../../foundations/components/presentation";

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => { }}
      ref={input => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);

const Compose = ({ chatProfileList }) => {
  const [formattedUsers, setFormattedUsers] = useState<any>([]);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const dispatch = useDispatch();
  let table: any = createRef();

  const viewCompose = useSelector(getComposeView);
  const currentConversationId = useSelector(getCurrentConversationId);
  const { id: myUserId, role: myRole } = SessionService.getUser();
  const myAccessToken = SessionService.getAccessToken();

  // Format chatProfileList to match the table column requirements
  useEffect(() => {
    const noSelfList = Object.assign({}, chatProfileList);
    delete noSelfList[myUserId.toString()];
    setFormattedUsers(Object.keys(noSelfList).map(id => {
      let user = noSelfList[id];
      user.id = id;
      user.name = `${user.firstName} ${user.lastName}`
      user.send = () => { createChatCallback(id) }
      return user;
    }));
    setFilteredUsers(formattedUsers);
  }, [chatProfileList])

  // Update filtered users if formatted users gets updated
  useEffect(() => {
    setFilteredUsers(formattedUsers);
  }, [formattedUsers])

  // Reset selected rows and filtered users when navigating away
  useEffect(() => {
    if (!viewCompose) {
      setSelectedRows([]);
      setFilteredUsers(formattedUsers);
    }
  }, [viewCompose])

  const onSelectRow = () => {
    setImmediate(() => {
      setSelectedRows(table.current.selectionContext.selected)
    });
  };

  // filters the client list to only include the clients that match the search
  const onSearch = (e) => {
    e.preventDefault();
    const searchValue = e.target.value.toLowerCase()
    searchValue == ''
      ? setFilteredUsers(formattedUsers)
      : setFilteredUsers(formattedUsers.filter(client => {
        return client.name.toLowerCase().includes(searchValue.toLowerCase())
      }));
  }

  const selectRow = onSelect => ({
    mode: 'checkbox',
    columnClasses: 'py-2 align-middle',
    clickToSelect: false,
    selectionHeaderRenderer: ({ mode, indeterminate, rowIndex, ...rest }) =>
      <SelectRowInput
        type="checkbox"
        indeterminate={indeterminate}
        rowIndex={rowIndex}
        {...rest}
      />,
    selectionRenderer: ({ mode, indeterminate, rowIndex, ...rest }) =>
      <SelectRowInput
        type="checkbox"
        indeterminate={indeterminate}
        rowIndex={rowIndex}
        {...rest}
      />,
    headerColumnStyle: { border: 0, width: '7vh' },
    selectColumnStyle: { border: 0, verticalAlign: 'middle', width: '7vh' },
    onSelect: onSelect,
    onSelectAll: onSelect,
  })

  // this function creates direct messages between the client making the call and the user passed to the function
  const createChatCallback = async (userId) => {
    try {
      const resp = await createChat(myUserId, userId, myAccessToken)
      dispatch(
        // Load the conversations that this user has joined
        fetchMemberships({
          uuid: myUserId,
          include: {
            channelFields: true,
            customChannelFields: true,
            customFields: true,
            totalCount: false
          }
        })
      );
      const conversationId = resp.data.channel;
      await dispatch(focusOnConversation(conversationId))
      await dispatch(composeViewHidden())
      await dispatch(currentConversationHeaderViewDisplayed())
      await dispatch(currentConversationViewDisplayed())
    } catch (error) {
      console.error(error)
    }
  }
  
  return viewCompose ?
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-row align-items-center" style={{ width: "100%", padding: "10px" }}>
        <StyledBox display={["block", "none"]} color="active" marginRight="7">
          <PNIcon
            icon={Icons.Back}
            onClick={() => {
              dispatch(menuViewDisplayed());
              dispatch(composeViewHidden());
            }}
            title="Back"
            color="#0275d8" // Primary means purple to PubNub so have to use the hex value.
            clickable
          />
        </StyledBox>
        <h6 className="mb-0 mr-2">To:</h6>
        <Input className="rounded-pill search-input mr-2"
          type="search" placeholder="Search..."
          aria-label="Search"
          onChange={onSearch}
        />
        {['OWNER', 'STAFF'].includes(myRole) &&
          <>
            <Button
              className="d-flex flex-row align-items-center ml-2"
              color="primary"
              size="sm"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => { dispatch(newChannelViewDisplayed()) }}
            >
              <span className="d-none d-md-block mr-2">New Channel</span>
              <Icon width="20px" height="20px" icon={plusOutlined} style={{ color: '#ffffff' }} />
            </Button>
            <Button
              className="d-flex flex-row align-items-center ml-2"
              color="primary"
              style={{ whiteSpace: "nowrap" }}
              disabled={selectedRows.length != 1}
              size="sm"
              onClick={() => { selectedRows.length == 1 && createChatCallback(selectedRows[0]) }}
            >
              <span className="d-none d-md-block mr-2">Direct Message</span>
              <Icon width="20px" height="20px" icon={sendOutlined} style={{ color: '#ffffff' }} />
            </Button>
          </>
        }
      </div>
      <div className="table-responsive">
        <BootstrapTable
          ref={table}
          bootstrap4
          keyField="id"
          data={filteredUsers}
          size="sm"
          columns={composeColumns}
          selectRow={['OWNER', 'STAFF'].includes(myRole) ? selectRow(onSelectRow) : undefined}
          bordered={false}
          classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
          defaultSorted={[{ dataField: 'name', order: 'asc' }]}
        />
      </div>
      <NewChannelModal selectedMembers={selectedRows} />
    </div>
    : null;
};

export { Compose };
