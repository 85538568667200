import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../components/errors/Error404';
import Error500 from '../components/errors/Error500';
import Domain404 from '../components/errors/Domain404';
import TokenError from '../components/errors/TokenError';
import InviteExpired from '../components/errors/InviteExpired';
import VerifyError from '../components/errors/VerifyError';

const ErrorLayout = ({ match: { url } }) => (
  <Switch>
    <Route path={`${url}/404`} component={Error404} />
    <Route path={`${url}/500`} component={Error500} />
    <Route path={`${url}/domain/404`} component={Domain404} />
    <Route path={`${url}/token-error`} component={TokenError} />
    <Route path={`${url}/invite-expiry`} component={InviteExpired} />
    <Route path={`${url}/verify-error`} component={VerifyError} />
    {/*Redirect*/}
    <Redirect to={`${url}/404`} />
  </Switch>
);

ErrorLayout.propTypes = { match: PropTypes.object };

export default ErrorLayout;
