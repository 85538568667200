import React from 'react';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    Button,
    Form,
} from "reactstrap";
import FormGroupInput from '../components/common/FormGroupInput';

const VerifyOwnerEmailModal = ({isOpen, onClose, updateEmail}) => {
    
    return (
    <Modal isOpen={isOpen} toggle={onClose}>
        <Form onSubmit={updateEmail}>
            <ModalHeader>Update Account Email</ModalHeader>
            <ModalBody>
                <p>Update your account email & resend the verification link</p>
                <FormGroupInput
                id="email"
                label="New Email"
                type="email"
                placeholder="email@email.com"
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose}>Close</Button>
                <Button color="primary" type="submit" >Update & Re-send</Button>
            </ModalFooter>
        </Form>
    </Modal>
);
}

export default VerifyOwnerEmailModal;