import apiHandler from './apiHandler';

export const getTasks = (channelId, authKey) => {
  return apiHandler.get(`/channel-task-list/${channelId}`);
};

export const setTasks = (channelId, tasks) => {
  return apiHandler.put(`/channel-task-list/`, {channelId, tasks});
};

export const deleteTasks = (channelId) => {
  return apiHandler.delete(`/channel-task-list/`, {channelId});
};
