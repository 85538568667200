import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Card, CardBody, Form, Row, Col, FormGroup, Label, Input, Button, CardFooter, Collapse } from 'reactstrap';
import FalconCardHeader from './FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SessionService from '../../services/SessionService';

const ClientContactInformationCard = ({user, setUser}) => {
     
    const [collapsed, setCollapsed] = useState(!SessionService.getCollapsePreference().contact);

    const onEntered = () => {
      setPreference(true);
    }
  
    const onExited = () => {
      setPreference(false);
    }

    const setPreference = (pref) => {
        const cur = SessionService.getCollapsePreference();
        cur.contact = pref;
        SessionService.setCollapsePreference(cur);
    }

    return (
        <Card className="mb-3">
        <FalconCardHeader title="Contact Information" light={false} />
        <CardBody className="bg-light">
            <p>Your contact information is displayed to all users who view your profile</p>
            <Collapse isOpen={!collapsed} onEntered={onEntered} onExited={onExited}>
            <Form>
            <Row>
                <Col md>
                <FormGroup>
                    <Label for="exampleName">Phone number</Label>
                    <Input type="text" name="name" id="exampleName" value={user.phone} onChange={({ target }) => setUser({...user, "phone" :target.value})}/>
                </FormGroup>
                </Col>
                <Col md />
            </Row>
            <Row>
                <Col md>
                <FormGroup>
                    <Label for="exampleName">Street</Label>
                    <Input type="text" name="name" id="exampleName" value={user.street} onChange={({ target }) => setUser({...user, "street" :target.value})}/>
                </FormGroup>
                </Col>
                <Col md>
                <FormGroup>
                    <Label for="exampleName">Appartment, suite, etc</Label>
                    <Input type="text" name="name" id="exampleName" value={user.unitNumber} onChange={({ target }) => setUser({...user, "unitNumber" :target.value})}/>
                </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                <FormGroup>
                    <Label for="exampleName">City</Label>
                    <Input type="text" name="name" id="exampleName" value={user.city} onChange={({ target }) => setUser({...user, "city" :target.value})}/>
                </FormGroup>
                </Col>
                <Col md>
                <FormGroup>
                    <Label for="exampleName">Province/ State</Label>
                    <Input type="text" name="name" id="exampleName" value={user.state} onChange={({ target }) => setUser({...user, "state" :target.value})}/>
                </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                <FormGroup>
                <Label for="exampleName">Country</Label>
                <Input type="text" name="name" id="exampleName" value={user.country} onChange={({ target }) => setUser({...user, "country" :target.value})}/>
                </FormGroup>
                </Col>
                <Col md>
                <FormGroup>
                <Label for="exampleName">Postal/ ZIP code</Label>
                <Input type="text" name="name" id="exampleName" value={user.postalCode} onChange={({ target }) => setUser({...user, "postalCode" :target.value})}/>
                </FormGroup>
                </Col>
            </Row>
            </Form>
            </Collapse>
        </CardBody>
            <CardFooter className="bg-light p-0 border-top">
                <Button color="link" block onClick={() => setCollapsed(!collapsed)}>
                    Show {collapsed ? 'more' : 'less'}
                    <FontAwesomeIcon icon="chevron-up" className="ml-1 fs--2" transform={collapsed ? 'rotate-180' : ''} />
                </Button>
            </CardFooter>
        </Card>
    )
}

ClientContactInformationCard.protoTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired
}

export default ClientContactInformationCard;