import React from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';

/*
  Displays Drive name and space left on drive, using the provider key and provider name
  If drive is disconnected displays a disconnected error card, todo pull this logic out
*/

const FreeSpaceCard = ({ provider, freeSpace, email, isDisconnected }) => {
  return (
    <Card className={`p-4 m-2 ${isDisconnected ? "bg-secondary" : "bg-primary"}`}>
      <div className="d-flex flex-column text-sans-serif">
        <h3 className="text-light fs-4 pb-4">{provider.name ? provider.name : ''} {isDisconnected ? "(disconnected)" : ''}</h3>
        <div className="text-light">
        <p className="fs-2 font-weight-normal m-0">{isDisconnected ? "Please reconnect" : `${freeSpace} GB available`}</p>
        <p className="fs-2">{email}</p>
        </div>
      </div>
    </Card>
  )
}

FreeSpaceCard.propTypes = {
  provider: PropTypes.object,
  freeSpace: PropTypes.number,
  email: PropTypes.string,
  isDisconnected: PropTypes.bool,
};

export default FreeSpaceCard;
