import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import Icon from '@iconify/react';

const ButtonIcon = ({ icon, iconAlign, iconClassName, iconStyle, transform, children, ...rest }) => {
  let additionalStyle = {
    width: "1.5rem",
    height: "1.5rem"
  }
  
  return (
  <Button {...rest}>
    {iconAlign === 'right' && children}
    <Icon icon={icon}
      className={classNames(iconClassName, {
        'mr-1': children && iconAlign === 'left',
        'ml-1': children && iconAlign === 'right'
      })}
      style={{...iconStyle, ...additionalStyle}}
      transform={transform}
    />
    {iconAlign === 'left' && children}
  </Button>
)};

ButtonIcon.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.any,
  iconStyle: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string
};

ButtonIcon.defaultProps = { iconAlign: 'left' };

export default ButtonIcon;
