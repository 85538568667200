import React from "react";
import { MessageList } from "../MessageList";
import { MessageInput } from "../MessageInput";
import { TypingIndicatorDisplay } from "../../../features/typingIndicator/TypingIndicatorDisplay";
import { getCurrentConversationView } from "../../layout/Selectors";
import { useSelector } from "react-redux";

const CurrentConversation = ({chatProfileList}) => {
  const viewCurrentConversation = useSelector(getCurrentConversationView);
  return viewCurrentConversation
    ? <>
        <MessageList chatProfileList={chatProfileList} />
        <TypingIndicatorDisplay chatProfileList={chatProfileList} />
        <MessageInput />
      </>
    : null;
};

export { CurrentConversation };
