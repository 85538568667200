import React, { useState, useEffect } from 'react';
import { CardBody, Card, CardHeader } from 'reactstrap';
import { getSubDomain } from '../utils/util';
import { getBrandingBySubdomain } from '../api/organization.api';
import withCentered from './withCentered';

const withBrandedCard = OriginalComponent => {
  const UpdatedComponent = props => {
    const subdomain = getSubDomain();
    const [name, setName] = useState(subdomain);
    const [logo, setLogo] = useState(null);

    const getBranding = async () => {
      try {
        const res = await getBrandingBySubdomain(subdomain);
        setName(res.data.name);
        setLogo(res.data.logo);
      }
      catch (error) {
        window.location.href = '/errors/domain/404'
      }
    }

    useEffect(() => {
      getBranding();
    }, []);

    const logoStyling = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "18rem",
      maxHeight: "6rem",
    }

    return (
      <Card>
        { logo
          ? <CardHeader className="p-3 logo" >
            <img style={logoStyling} src={logo} />
          </CardHeader>
          : <CardHeader className="text-center p-3 logo border-soft-primary border-3x" >
            <h3 className="m-0 font-weight-semi-bold">{name}</h3>
          </CardHeader>
        }
        <CardBody className="p-4">
          <OriginalComponent {...props} />
        </CardBody>
      </Card>
    );
  }
  return withCentered(UpdatedComponent);
};

export default withBrandedCard;
