import userOutlined from '@iconify/icons-ant-design/user-outlined';
import teamOutlined from '@iconify/icons-ant-design/team-outlined';
import cloudServerOutlined from '@iconify/icons-ant-design/cloud-server-outlined';
import creditCardOutlined from '@iconify/icons-ant-design/credit-card-outlined';
import building from '@iconify/icons-fa-regular/building';

export default [
  {
    icon: userOutlined,
    title: `Profile`,
    description: 'Manage your login, password and update your contact information',
    to: '/settings/profile'
  },
  {
    icon: building,
    title: `Organization`,
    description: 'Update your organization information, change your URL, delete your account',
    to: '/settings/organization'
  },
  {
    icon: teamOutlined,
    title: `Manage Staff`,
    description: 'Add, delete and manage your staff accounts',
    to: '/settings/staff'
  },
  {
    icon: cloudServerOutlined,
    title: `Document Settings`,
    description: 'Manage your storage connection and view storage space',
    to: '/settings/documents/setup'
  },
  {
    icon: creditCardOutlined,
    title: `Billing`,
    description: `Upgrade to the Professional Plan, manage your billing, add staff seats`,
    to: '/settings/billing'
  },
];
