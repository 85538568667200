import React from 'react';
import { Col, Row } from 'reactstrap';
import Section from '../components/common/Section';

const withCentered = OriginalComponent => {
  const UpdatedComponent = props => {
    return (
      <Section className="py-0">
        <Row className="flex-center min-vh-100 py-6">
          <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
            <OriginalComponent {...props} />
          </Col>
        </Row>
      </Section>
    );
  }
  return UpdatedComponent;
};

export default withCentered;
