import React, { createRef, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIcon from '../../common/ButtonIcon';

import { getPaginationArray } from '../../../helpers/utils';
import SessionService from '../../../services/SessionService';

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => {}}
      ref={input => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);

const selectRow = onSelect => ({
  mode: 'checkbox',
  columnClasses: 'py-2 align-middle',
  clickToSelect: false,
  selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
  selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
  headerColumnStyle: { border: 0, width: '7vh' },
  selectColumnStyle: { border: 0, verticalAlign: 'middle', width: '7vh' },
  onSelect: onSelect,
  onSelectAll: onSelect
});

const SEARCH_TRIGGER_TIME = 1000

const ClientsTable = ({
  showHeaders,
  columns,
  clients,
  showSelectCheckbox,
  disableButtons,
  showDelete,
  showSearch,
  newLabel,
  role,
  title,
  onNew,
  onDeleteOne,
  onDeleteMany,
  onRefresh,
  sortOrder,
  ...rest}) => {
  let timer, typeStartTime, searchValue

  let table = createRef();
  // State
  const [bulkAction, setBulkAction] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredClients, setFilteredUsers] = useState(clients);
  const [sortPreference, setSortPreference] = useState(sortOrder);
  const [options, setOptions] = useState({ 
    custom: true,
    sizePerPage: 20,
    totalSize: clients.length
  });

  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  useEffect(() => {
    setSortPreference(SessionService.getSortPreference());
  }, []);

  useEffect(() => {
    setFilteredUsers(clients)
    setOptions({ ...options, totalSize: clients.length })
  }, [clients]);

  const onSearch = (e) => {
    e.preventDefault();
    const searchValue = e.target.value.toLowerCase()
    searchValue == ''
      ? setFilteredUsers(clients)
      : setFilteredUsers(clients.filter(client => {
        return client.name.toLowerCase().includes(searchValue.toLowerCase())
      }));
  }

  const onSelectRow = () => {
    setImmediate(() => {
      console.log(table.current.selectionContext.selected.length)
      setSelectedRows(table.current.selectionContext.selected)
    });
  };

  const onNewClick = async (e) => {
    e.preventDefault()
    if (disableButtons){
      toast.error('You must verify your email before adding users');
      return;
    }
    if (onNew) onNew()
  };

  const deleteOneUser = async (e) => {
    e.preventDefault()
    if (onDeleteOne) await onDeleteOne(table.current.selectionContext.selected[0])
  };

  const executeBulkAction = async (e) => {
    e.preventDefault()
    if (bulkAction === 'Delete') {
      if (onDeleteMany) await onDeleteMany(table.current.selectionContext.selected)
      setIsSelected(false)
    }
  };

  const rowProps = showSelectCheckbox ? {selectRow: selectRow(onSelectRow)} : {}

  const onDelete = async (e) => {
    e.preventDefault()
    if (disableButtons){
      toast.error('You must verify your email before adding users');
      return;
    }
    onDeleteOne(selectedRows)
    setSelectedRows([])
  }
  
  return (
    <>
    <Card {...rest}>
      <Row noGutters className="px-1 py-3">
        <div className="mr-auto d-flex flex-row align-items-center">
          <div className="ml-auto d-flex flex-row align-items-center">
          <Input className="rounded-pill search-input ml-3 mr-3"
            type="search" placeholder="Search..."
            aria-label="Search"
            onChange={onSearch}
            style={{ width: "300px" }} // Increase the length of the input component
          />
          </div>
          <div className="ml-auto d-flex flex-row align-items-center">
            <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm" onClick={onNewClick}>
              {newLabel}
            </ButtonIcon>
            <ButtonIcon icon="trash" transform="shrink-3 down-2" color="falcon-default" size="sm" className="mx-2"
              disabled={selectedRows.length === 0} onClick={onDelete}>
              Delete
            </ButtonIcon>
          </div>
        </div>
      </Row>
      <CardBody className="p-0">
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            const lastIndex = paginationProps.page * paginationProps.sizePerPage;
            return (
              <Fragment>
                <div className="table-responsive">
                  <BootstrapTable
                    ref={table}
                    bootstrap4
                    keyField="id"
                    data={filteredClients}
                    size="sm"
                    columns={columns}
                    // {...rowProps}
                    selectRow={selectRow(onSelectRow)}
                    bordered={false}
                    classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                    rowClasses="btn-reveal-trigger border-top border-200"
                    headerClasses="bg-200 text-900 border-y border-200"
                    sort={sortPreference ? sortPreference : {dataField: 'name', order:'asc'}}
                    {...paginationTableProps}
                  />
                </div>
                <Row noGutters className="px-1 py-3 flex-center">
                  <Col xs="auto">
                    <Button
                      color="falcon-default"
                      size="sm"
                      onClick={handlePrevPage(paginationProps)}
                      disabled={paginationProps.page === 1}
                    >
                      <FontAwesomeIcon icon="chevron-left" />
                    </Button>
                    {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(pageNo => (
                      <Button
                        color={paginationProps.page === pageNo ? 'falcon-primary' : 'falcon-default'}
                        size="sm"
                        className="ml-2"
                        onClick={() => paginationProps.onPageChange(pageNo)}
                        key={pageNo}
                      >
                        {pageNo}
                      </Button>
                    ))}
                    <Button
                      color="falcon-default"
                      size="sm"
                      className="ml-2"
                      onClick={handleNextPage(paginationProps)}
                      disabled={lastIndex >= paginationProps.totalSize}
                    >
                      <FontAwesomeIcon icon="chevron-right" />
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  </>
  );
};

ClientsTable.propTypes = {
  showHeaders: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  showSelectCheckbox: PropTypes.bool,
  disableButtons: PropTypes.bool,
  showDelete: PropTypes.bool,
  showSearch: PropTypes.bool,
  newLabel: PropTypes.string,
  role: PropTypes.string,
  onNew: PropTypes.func,
  onDeleteOne: PropTypes.func,
  onDeleteMany: PropTypes.func,
  onRefresh: PropTypes.func,
  title: PropTypes.string
};

ClientsTable.defaultProps = {
  showHeaders: false,
  disableButtons: false,
  newLabel: 'New',
  showDelete: false,
  showSearch: false,
  showSelectCheckbox: false,
  role: 'CLIENT',
  title: 'Clients',
  clients: []
};

export default ClientsTable;