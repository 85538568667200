import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

const ToastComponent = (message) => (
  <div className="d-flex flex-row">
    <p className="max-w-75 m-0">{message}</p>
    <Spinner color="primary" size="sm" className="ml-4" />
  </div>
);

export const ProgressToast = ({ toastId, message }) => {
  toastId.current = toast(
    ToastComponent(message),
    { autoClose: false, position: toast.POSITION.BOTTOM_CENTER, draggable: false }
  );
}

ProgressToast.propTypes = {
  toastId: PropTypes.string,
  message: PropTypes.string
};

export const ProgressUpdateToast = ({ toastId, message }) => {
  return toast.update(
    toastId.current,
    { render: ToastComponent(message), autoClose: false, position: toast.POSITION.BOTTOM_CENTER, draggable: false }
  );
}

ProgressUpdateToast.propTypes = {
  toastId: PropTypes.string,
  message: PropTypes.string
};

