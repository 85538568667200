import React from 'react';
import {
  Card,
  CardBody,
  Button,
} from "reactstrap"
import FalconCardHeader from './FalconCardHeader';
import SessionService from '../../services/SessionService';
import { toast } from 'react-toastify';
import logger from '../../utils/logger';

const ShowSetupChecklistCard = () => {
  const onClick = () => {
    SessionService.setSetupHidden(0);
    toast.info('Setup steps now shown on home page');
  }
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Show Account Setup Checklist" light={false} />
      <CardBody>
        <Button
          color="falcon-default"
          onClick={onClick}
        >
          Show Account Setup Steps
        </Button>
      </CardBody>
    </Card>
  )
}

export default ShowSetupChecklistCard;