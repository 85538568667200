import apiHandler from './apiHandler';

// Takes a mapping from userId to permission group i.e. 'VIEW', 'EDIT", etc.
const processPermissionsToItem = (itemId, dataMap, notifyEmail) => {
  return apiHandler.put(`/file-access`, { itemId, dataMap, notifyEmail });
}

const deletePermissionsToItem = (itemId, userIds) => {
  return apiHandler.delete(`/file-access?itemId=${itemId}&userIds=${userIds}`);
}

const getPermissions = (itemId, inherited) => {
  return apiHandler.get(`/file-access/${itemId}${inherited && "?inherited=true"}`)
}

export default {
  processPermissionsToItem,
  deletePermissionsToItem,
  getPermissions,
}