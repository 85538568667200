import { nameFormatter, dateFormatter, avatarFormatter } from '../../../utils/formatter';
import { setSortPreference } from '../../../utils/util';
const clientColumns = [
  {
    dataField: 'avatar',
    text: '',
    headerClasses: 'border-0 align-middle td-small',
    classes: 'border-0 py-2 align-middle',
    formatter: avatarFormatter
  },
  {
    dataField: 'name',
    text: 'Name',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: nameFormatter,
    onSort: (field, order) => {setSortPreference('clientSortPreference', field, order)}
  },
  {
    dataField: 'group',
    text: 'Organization',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    onSort: (field, order) => {setSortPreference('clientSortPreference', field, order)},
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      if (order === 'desc') return a > b ? -1 : a < b ? 1 : 0; /* Default descending */
      else if (a == '' || b == '') {
        return a == '' ? 1 : b == '' ? -1 : 0; /* Prioritizes non blank entries */
      } else {
        return a < b ? -1 : a > b ? 1 : 0; /* Default ascending */
      }
    }
  },
  {
    dataField: 'email',
    text: 'Email',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    onSort: (field, order) => {setSortPreference('clientSortPreference', field, order)}
  },
  {
    dataField: 'lastActivity',
    text: 'Last Activity',
    headerClasses: 'border-0 align-middle',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: dateFormatter,
    onSort: (field, order) => {setSortPreference('clientSortPreference', field, order)}
  },
];

export default clientColumns;