import { AppState } from "../../main/storeTypes";
import { createSelector } from "reselect";
import { createMembershipReducer } from "pubnub-redux";

// MembershipCustom encodes the type of the membership that we care about. It has an id field and a custom object
// that encodes the lastReadMessageTimetoken.
export type MembershipCustom = {
  id: string,
  custom: object | null
}

// MembershipHash is a hash map to quickly retireve membership by ID.
export type MembershipHash = { [id: string]: MembershipCustom[] };

export interface MemberConversations {
  [userId: string]: string[];
}

const getByUserIdSlice = (state: AppState) => state.joinedConversations;

export const getConversationsByUserId = createSelector(
  [getByUserIdSlice],
  (conversations): MembershipHash => {
    return conversations.byId;
  }
);

const JoinedConversationsStateReducer = createMembershipReducer();
export { JoinedConversationsStateReducer };
