import { fetchMemberships, setMemberships } from "pubnub-redux";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { createChannel } from "../../../../api/messages";
import FormGroupInput from "../../../../components/common/FormGroupInput";
import SessionService from "../../../../services/SessionService";
import { addMembers } from "../conversationMembers/ConversationMembers";
import { focusOnConversation } from "../currentConversation/currentConversationModel";
import {
  currentConversationHeaderViewDisplayed,
  currentConversationViewDisplayed,
} from "../layout/LayoutActions";
import { toast } from "react-toastify";
import { composeViewHidden, newChannelViewHidden } from "../layout/LayoutActions";
import { getNewChannelView } from "../layout/Selectors";
import { ThunkAction } from "../../main/storeTypes";

export const subscribe = (
  conversationId: string,
): ThunkAction<Promise<void>> => {
    return (dispatch, getState, context) => {
      context.pubnub.api.subscribe({
        channels: [conversationId],
        withPresence: true
      });
      return Promise.resolve()
      };
};


const NewChannelModal = ({ selectedMembers }) => {
  const viewNewChannel: any = useSelector(getNewChannelView);
  const dispatch = useDispatch();
  const [channelData, setChannalData] = useState({
    name: '',
    description: '',
  })

  const onSubmit = async e => {
    // onSumbit create Channel with input name, description and members
    // then close the modal and focus on channel created
    e.preventDefault();

    const authKey = SessionService.getAccessToken() as string
    const localUser = SessionService.getUser();
    try {
      const resp = await createChannel(localUser.id, authKey, channelData.name, channelData.description)
      const conversationId = resp.data.channel;

      selectedMembers = selectedMembers.map(id => id.toString())
      dispatch(addMembers(selectedMembers, conversationId)) // this should be addMembers
      dispatch(setMemberships({
        uuid: localUser.id,
        channels: [{ id: conversationId }]
      }))
      dispatch(subscribe(conversationId))
      dispatch(
        fetchMemberships({
          uuid: localUser.id,
          include: {
            channelFields: true,
            customChannelFields: true,
            customFields: true,
            totalCount: false
          }
        })
      )
      dispatch(focusOnConversation(conversationId))
    } catch (error) {
      toast.error('Failed to create channel');
    }
    dispatch(composeViewHidden())
    dispatch(currentConversationHeaderViewDisplayed())
    dispatch(currentConversationViewDisplayed())
    dispatch(newChannelViewHidden())
  }

  const nameFormatter = (name: string) => {
    return name.replace(/[\s]/g, '-').replace(/[^A-Za-z0-9-]/g, '');
  }

  return (
    <Modal isOpen={viewNewChannel}>
      <Form onSubmit={onSubmit}>
        <ModalHeader>{"New Channel"}</ModalHeader>
        <ModalBody>
          <FormGroupInput
            id="channel-name"
            className="mb-0"
            label="Channel Name"
            maxLength="80"
            value={channelData.name}
            onChange={({ target }) => setChannalData({ ...channelData, name: nameFormatter(target.value) })}
          />
          <FormGroupInput
            id="channel-description"
            label="Channel description (optional)"
            type="textarea"
            maxLength="500"
            className="max-vh-25"
            value={channelData.description}
            onChange={({ target }) => setChannalData({ ...channelData, description: target.value })}
          />
          <div className="d-flex justify-content-end">
            {channelData.description.length}/500 characters
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => { dispatch(newChannelViewHidden()) }}>Cancel</Button>
          <Button color="primary" type="submit" disabled={false}>Create</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export { NewChannelModal };
