import { LayoutActionType } from "./LayoutActionType";

export interface menuViewHiddenAction {
  type: typeof LayoutActionType.MENU_VIEW_HIDDEN;
}

export interface composeViewHiddenAction {
  type: typeof LayoutActionType.COMPOSE_VIEW_HIDDEN;
}

export interface newChannelViewHiddenAction {
  type: typeof LayoutActionType.NEW_CHANNEL_VIEW_HIDDEN;
}

export interface editChannelViewHiddenAction {
  type: typeof LayoutActionType.EDIT_CHANNEL_VIEW_HIDDEN;
}

export interface currentConversationHeaderViewHiddenAction {
  type: typeof LayoutActionType.CURRENT_CONVERSATION_HEADER_VIEW_HIDDEN;
}

export interface currentConversationViewHiddenAction {
  type: typeof LayoutActionType.CURRENT_CONVERSATION_VIEW_HIDDEN;
}

export interface conversationMembersViewHiddenAction {
  type: typeof LayoutActionType.CONVERSATION_MEMBERS_VIEW_HIDDEN;
}

export interface conversationTasksViewHiddenAction {
  type: typeof LayoutActionType.CONVERSATION_TASKS_VIEW_HIDDEN;
}

export interface joinConversationViewHiddenAction {
  type: typeof LayoutActionType.JOIN_CONVERSATION_VIEW_HIDDEN;
}

export interface addMembersViewHiddenAction {
  type: typeof LayoutActionType.ADD_MEMBERS_VIEW_HIDDEN;
}

export interface editTaskViewHiddenAction {
  type: typeof LayoutActionType.EDIT_TASK_VIEW_HIDDEN;
}


export interface leaveConfirmationViewHiddenAction {
  type: typeof LayoutActionType.LEAVE_CONFIRMATION_VIEW_HIDDEN;
}

export interface menuViewDisplayedAction {
  type: typeof LayoutActionType.MENU_VIEW_DISPLAYED;
}

export interface composeViewDisplayedAction {
  type: typeof LayoutActionType.COMPOSE_VIEW_DISPLAYED;
}

export interface newChannelViewDisplayedAction {
  type: typeof LayoutActionType.NEW_CHANNEL_VIEW_DISPLAYED;
}

export interface editChannelViewDisplayedAction {
  type: typeof LayoutActionType.EDIT_CHANNEL_VIEW_DISPLAYED;
}

export interface currentConversationHeaderViewDisplayedAction {
  type: typeof LayoutActionType.CURRENT_CONVERSATION_HEADER_VIEW_DISPLAYED;
}

export interface currentConversationViewDisplayedAction {
  type: typeof LayoutActionType.CURRENT_CONVERSATION_VIEW_DISPLAYED;
}

export interface conversationMembersViewDisplayedAction {
  type: typeof LayoutActionType.CONVERSATION_MEMBERS_VIEW_DISPLAYED;
}

export interface conversationTasksViewDisplayedAction {
  type: typeof LayoutActionType.CONVERSATION_TASKS_VIEW_DISPLAYED;
}

export interface joinConversationViewDisplayedAction {
  type: typeof LayoutActionType.JOIN_CONVERSATION_VIEW_DISPLAYED;
}

export interface addMembersViewDisplayedAction {
  type: typeof LayoutActionType.ADD_MEMBERS_VIEW_DISPLAYED;
}

export interface editTaskViewDisplayedAction {
  type: typeof LayoutActionType.EDIT_TASK_VIEW_DISPLAYED;
}


export interface leaveConfirmationViewDisplayedAction {
  type: typeof LayoutActionType.LEAVE_CONFIRMATION_VIEW_DISPLAYED;
}

export type LayoutActions =
  | menuViewHiddenAction
  | composeViewHiddenAction
  | newChannelViewHiddenAction
  | editChannelViewHiddenAction
  | currentConversationHeaderViewHiddenAction
  | currentConversationViewHiddenAction
  | conversationMembersViewHiddenAction
  | conversationTasksViewHiddenAction
  | joinConversationViewHiddenAction
  | addMembersViewHiddenAction
  | editTaskViewHiddenAction
  | leaveConfirmationViewHiddenAction
  | menuViewDisplayedAction
  | currentConversationHeaderViewDisplayedAction
  | currentConversationViewDisplayedAction
  | composeViewDisplayedAction
  | newChannelViewDisplayedAction
  | editChannelViewDisplayedAction
  | conversationMembersViewDisplayedAction
  | conversationTasksViewDisplayedAction
  | joinConversationViewDisplayedAction
  | addMembersViewDisplayedAction
  | editTaskViewDisplayedAction
  | leaveConfirmationViewDisplayedAction;

export const menuViewDisplayed = (): menuViewDisplayedAction => {
  return {
    type: LayoutActionType.MENU_VIEW_DISPLAYED
  };
};

export const joinConversationViewDisplayed = (): joinConversationViewDisplayedAction => {
  return {
    type: LayoutActionType.JOIN_CONVERSATION_VIEW_DISPLAYED
  };
};

export const addMembersViewDisplayed = (): addMembersViewDisplayedAction => {
  return  {
    type: LayoutActionType.ADD_MEMBERS_VIEW_DISPLAYED
  };
};

export const editTaskViewDisplayed = (): editTaskViewDisplayedAction => {
  return  {
    type: LayoutActionType.EDIT_TASK_VIEW_DISPLAYED
  };
};

export const composeViewDisplayed = (): composeViewDisplayedAction => {
  return {
    type: LayoutActionType.COMPOSE_VIEW_DISPLAYED
  };
};

export const newChannelViewDisplayed = (): newChannelViewDisplayedAction => {
  return {
    type: LayoutActionType.NEW_CHANNEL_VIEW_DISPLAYED
  };
};

export const editChannelViewDisplayed = (): editChannelViewDisplayedAction => {
  return {
    type: LayoutActionType.EDIT_CHANNEL_VIEW_DISPLAYED
  }
}

export const currentConversationHeaderViewDisplayed = (): currentConversationHeaderViewDisplayedAction => {
  return {
    type: LayoutActionType.CURRENT_CONVERSATION_HEADER_VIEW_DISPLAYED
  };
};

export const currentConversationViewDisplayed = (): currentConversationViewDisplayedAction => {
  return {
    type: LayoutActionType.CURRENT_CONVERSATION_VIEW_DISPLAYED
  };
};

export const conversationMembersViewDisplayed = (): conversationMembersViewDisplayedAction => {
  return {
    type: LayoutActionType.CONVERSATION_MEMBERS_VIEW_DISPLAYED
  };
};

export const conversationTasksViewDisplayed = (): conversationTasksViewDisplayedAction => {
  return {
    type: LayoutActionType.CONVERSATION_TASKS_VIEW_DISPLAYED
  };
};


export const leaveConfirmationViewDisplayed = (): leaveConfirmationViewDisplayedAction => {
  return {
    type: LayoutActionType.LEAVE_CONFIRMATION_VIEW_DISPLAYED
  }
}

export const menuViewHidden = (): menuViewHiddenAction => {
  return {
    type: LayoutActionType.MENU_VIEW_HIDDEN
  };
};

export const joinConversationViewHidden = (): joinConversationViewHiddenAction => {
  return {
    type: LayoutActionType.JOIN_CONVERSATION_VIEW_HIDDEN
  };
};

export const addMembersViewHidden = (): addMembersViewHiddenAction => {
  return  {
    type: LayoutActionType.ADD_MEMBERS_VIEW_HIDDEN
  };
};

export const editTaskViewHidden = (): editTaskViewHiddenAction => {
  return  {
    type: LayoutActionType.EDIT_TASK_VIEW_HIDDEN
  };
};

export const composeViewHidden = (): composeViewHiddenAction => {
  return {
    type: LayoutActionType.COMPOSE_VIEW_HIDDEN
  };
};

export const newChannelViewHidden = (): newChannelViewHiddenAction => {
  return {
    type: LayoutActionType.NEW_CHANNEL_VIEW_HIDDEN
  };
};

export const editChannelViewHidden = (): editChannelViewHiddenAction => {
  return {
    type: LayoutActionType.EDIT_CHANNEL_VIEW_HIDDEN
  };
}

export const currentConversationHeaderViewHidden = (): currentConversationHeaderViewHiddenAction => {
  return {
    type: LayoutActionType.CURRENT_CONVERSATION_HEADER_VIEW_HIDDEN
  };
};

export const currentConversationViewHidden = (): currentConversationViewHiddenAction => {
  return {
    type: LayoutActionType.CURRENT_CONVERSATION_VIEW_HIDDEN
  };
};

export const conversationMembersViewHidden = (): conversationMembersViewHiddenAction => {
  return {
    type: LayoutActionType.CONVERSATION_MEMBERS_VIEW_HIDDEN
  };
};

export const conversationTasksViewHidden = (): conversationTasksViewHiddenAction => {
  return {
    type: LayoutActionType.CONVERSATION_TASKS_VIEW_HIDDEN
  };
};

export const leaveConfirmationViewHidden = (): leaveConfirmationViewHiddenAction => {
  return {
    type: LayoutActionType.LEAVE_CONFIRMATION_VIEW_HIDDEN
  };
};