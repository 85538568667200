
/**
 * Created by laslo on 5/18/20.
 */
import SessionService from "../services/SessionService";
const { parseDomain } = require( "parse-domain");

export const getSubDomain = () => {
  const input = window.location.href
  let domain
  if (input.startsWith("https")) domain = input.substr(8)
  else if (input.startsWith("http")) domain = input.substr(7)
  const dotsIndx = domain.indexOf(":")
  if (dotsIndx > -1) domain = domain.substr(0, dotsIndx)
  const result = parseDomain(domain);
  const { subDomains } = result
  let subdomain
  if (subDomains && subDomains.length > 0) {
    subdomain = subDomains[0]
  }
  else if (result && result.type === 'INVALID'){
    const parts = domain.split('.')
    if (parts.length >= 3){
      subdomain = parts[0]
    }
  }
  if (subdomain === 'www') subdomain = null
  return subdomain
}

const getParam = (params, paramName) => {
  return params.substring(params.indexOf(paramName) + paramName.length + 1)
}

export const getQueryParams = (params) => {
  let provider = getParam(params, 'provider')
    provider = provider.substring(0, provider.indexOf('&'))
    let scope = getParam(params, 'scope')
    scope = scope === 'true' ? true : scope
    return {
      provider,
      scope
    }
}

export const setSortPreference = (table, dataField, order) => {
  const curPreferences = SessionService.getSortPreference();
  SessionService.setSortPreference({...curPreferences, [table]: {dataField: dataField, order: order}});
}

export const fullNameInitials = (name) => {
  return name ? name.match(/\b\w/g).join('').substring(0,2) : "";
}

export const firstLastInitials = (firstName, lastName) => {
  return `${firstName[0]}${lastName[0]}`;
}