import axios from 'axios';
import { toast } from 'react-toastify';
import SessionService from '../services/SessionService';
import logger from '../utils/logger';

let API_URL;
if (process.env.NODE_ENV === 'development') {
    API_URL = process.env.REACT_APP_API_BASE_URL + '/api'
} else {
    API_URL = '/api'
}

const apiHandler = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'x-access-token': SessionService.getAccessToken() || ''
    },
    responseType: 'json'
});

apiHandler.interceptors.request.use(config => {
    config.headers['x-access-token'] = SessionService.getAccessToken();
    return config;
});

apiHandler.interceptors.response.use(data => {
    return Promise.resolve(data);
}, err => {
    if (err.response && err.response.data && err.response.data.error) {
        logger.warn(err, 'Error in api-handler')
        const { error } = err.response.data
        if (error.code === 405) {
            SessionService.destroy();
            setTimeout(() => window.location.replace('/'), 2000)
        } else if (error.code === 403) {
            if (error.internalCode === 40302) {
                SessionService.destroy();
                setTimeout(() => window.location.replace('/'), 2000)
            } else if (error.internalCode === 40301){
                //TODO call token refresh with refresh token
                SessionService.destroy();
                setTimeout(() => window.location.replace('/'), 2000)
            }
            toast.error(err.response.data.error.message);
        } else {
            toast.error(err.response.data.error.message);
        }
    }
    return Promise.reject(err);
});

export default apiHandler;
