import React from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { Icon } from '@iconify/react';

const DocumentLabel = ({icon, header, label, ...rest}) => (
  <Col {...rest}>
    <Row className="justify-content-center"><Icon icon={icon} color="#74A8ED" width="150"/></Row>
    <Row className="justify-content-center"><b>{header}</b></Row>
    <Row className="justify-content-center">{label}</Row>
  </Col>
);

export default DocumentLabel;