import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Card, CardBody, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button, FormText, Row, Col } from 'reactstrap';
import FalconCardHeader from './FalconCardHeader';
import { Icon } from '@iconify/react';
import editOutlined from '@iconify/icons-ant-design/edit-outlined';
import { toast } from 'react-toastify';
import { resetClientPassword } from '../../api/user.api';
import { updateEmail } from '../../api/user.api';
import FormGroupInput from '../../components/common/FormGroupInput';
import logger from '../../utils/logger';

const LoginAndPasswordCard = ({ user, setUser }) => {
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');

  const sendResetEmail = async e => {
    e.preventDefault();
    if (user.email) {
      await resetClientPassword(user.email)
      setOpenPasswordModal(!openPasswordModal)
      toast.success(`An email is sent to ${user.email} with password reset link`);
    }
  }

  const onCancel = () => {
    setOpenEmailModal(!openEmailModal)
    setCurrentPassword('');
    setNewEmail('');
    setConfirmEmail('');
  }

  const submitUpdateEmailChange = async e => {
    e.preventDefault();
    if (newEmail !== confirmEmail) return toast.error(`Emails did not match!`);
    try {
      await updateEmail(user.id, currentPassword, newEmail)
      toast.success(`Successfully edited email`)
      setOpenEmailModal(!openEmailModal);
      setUser({ ...user, email: newEmail });
      setCurrentPassword('');
      setNewEmail('');
      setConfirmEmail('');
    } catch (err) {
      logger.warn(err, 'Error while updating email')
      toast.error(err);
    }
  }
  const CustomerDetailRow = ({ title, isLastItem, children }) => (
    <Row>
      <Col xs={5} sm={4}>
        <p
          className={('font-weight-semi-bold', {
            'mb-0': isLastItem,
            'mb-1': !isLastItem
          })}
        >
          {title}
        </p>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Login and Password" />
        <CardBody className="bg-light border-top">
          <CustomerDetailRow title="Login email:">
            <td>{user.email} <Icon style={{ color: '#2C7BE5', width: "2rem", height: "2rem" }} icon={editOutlined} onClick={() => setOpenEmailModal(!openEmailModal)} /></td>
          </CustomerDetailRow>
          <CustomerDetailRow title="Password:">
            <td>*************** <Icon style={{ color: '#2C7BE5', width: "2rem", height: "2rem" }} icon={editOutlined} onClick={() => setOpenPasswordModal(!openPasswordModal)} /></td>
          </CustomerDetailRow>
        </CardBody>
      </Card>

      <Modal isOpen={openEmailModal} toggle={() => setOpenEmailModal(!openEmailModal)}>
        <Form onSubmit={submitUpdateEmailChange}>
          <ModalHeader>Change Email</ModalHeader>
          <ModalBody>
            <FormGroupInput
              label="Current Password"
              value={currentPassword}
              onChange={({ target }) => setCurrentPassword(target.value)}
              type="password"
            />
            <FormGroupInput
              label="New Email"
              value={newEmail}
              onChange={({ target }) => setNewEmail(target.value)}
              type="email"
              required
              minLength="1"
              warning="Make sure this field is not empty."
            />
            <FormGroupInput
              label="Confirm Email"
              value={confirmEmail}
              onChange={({ target }) => setConfirmEmail(target.value)}
              type="email"
              required
              minLength="1"
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onCancel}> Cancel</Button>
            <Button type="submit" color="primary">Update Email</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={openPasswordModal} toggle={() => setOpenPasswordModal(!openPasswordModal)}>
        <ModalHeader>Update Password</ModalHeader>
        <ModalBody>
          <p> User will receive an email to reset their password</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setOpenPasswordModal(!openPasswordModal)}>
            Cancel
          </Button>
          <Button color="primary" onClick={sendResetEmail}> Send Reset Email </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

LoginAndPasswordCard.protoTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired
}

export default LoginAndPasswordCard;