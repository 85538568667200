import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { Provider } from 'react-redux';
import { PubNubProvider } from 'pubnub-react';
import keyConfiguration from "./config/pubnub-keys"
import Pubnub from 'pubnub';
import amplitude from 'amplitude-js';
import { createAppStore } from './pages/Messages/main/store';
import { createChannelDataListener, createMembershipListener, createPubNubListener } from 'pubnub-redux';
import { createTypingIndicatorsListener } from './pages/Messages/features/typingIndicator/typingIndicatorModel';

const App = () => {
  const pubnubConfig = Object.assign(
    {},
    {
      // Ensure that subscriptions will be retained if the network connection is lost
      restore: true,
      heartbeatInterval: 0
    },
    keyConfiguration,
  );
  const pubnub = new Pubnub(pubnubConfig);
  const store = createAppStore({
    pubnub: {
      api: pubnub
    }
  });

  const amplitudeInstance = amplitude.getInstance()
  const leaveApplication = () => {
    // This is required to show the current user leave immediately rather than
    // wating for the timeout period
    pubnub.unsubscribeAll();
  };
  
  useEffect(() => {
    // Start listening for messages and events from PubNub
    pubnub.addListener(createPubNubListener(store.dispatch));
    pubnub.addListener(createTypingIndicatorsListener(store.dispatch));
    pubnub.addListener(createMembershipListener(store.dispatch));
    pubnub.addListener(createChannelDataListener(store.dispatch));
    return leaveApplication;
  }, []);
  
  useEffect(() => {
    amplitudeInstance.init(process.env.REACT_APP_AMPLITUDE_KEY);
  })
  useEffect(() => {
    window.addEventListener("beforeunload", leaveApplication);
  }, []);


  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <PubNubProvider client={pubnub}>
          <Layout />
        </PubNubProvider>
      </Provider>
    </Router>
  );
};

export default App;
