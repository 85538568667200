import React, { useState, useEffect } from "react";
import { Menu } from "../../../features/chat/Menu/Menu";
import { CurrentConversation } from "../../../features/currentConversation/CurrentConversation/CurrentConversation";
import { ConversationMembers } from "../../../features/conversationMembers/ConversationMembers/ConversationMembers";
import { ConversationTasks } from "../../../features/conversationTasks/ConversationTasks";
import { JoinConversationDialog } from "../../../features/joinedConversations/JoinConversationDialog/JoinConversationDialog";
import { FlexRow, FlexColumn } from "../../../foundations/components/layout";
import { AddMembers } from "../../conversationMembers/AddMembers";
import { Header } from "../../currentConversation/Header";

import { Card } from "reactstrap";
import { findChatProfiles } from "../../../../../api/user.api";
import { Compose } from "../../compose/Compose";
import { LeaveConfirmationModal } from "../../conversationMembers/LeaveConfirmation";
import { menuViewDisplayed, composeViewHidden, currentConversationHeaderViewHidden, currentConversationViewHidden } from "../../../features/layout/LayoutActions";
import { EditChannelModal } from "../../currentConversation/EditChannelModal/EditChannelModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getComposeView,
  getConversationMembersView,
  getConversationTasksView,
  getAddMembersView,
  getEditChannelView,
  getJoinConversationView,
  getLeaveConfirmationView,
 } from "../../layout/Selectors";
import {
  composeViewDisplayed,
} from '../../layout/LayoutActions';

const ChatUI = () => {
  const dispatch = useDispatch();
  const [chatProfileList, setChatProfileList] = useState([]);
  const viewCompose = useSelector(getComposeView);
  const viewConversationMembers = useSelector(getConversationMembersView);
  const viewConversationTasks = useSelector(getConversationTasksView);
  const viewAddMembers = useSelector(getAddMembersView);
  const viewEditChannel = useSelector(getEditChannelView);
  const viewLeaveConfirmation = useSelector(getLeaveConfirmationView);
  const viewJoinConversation = useSelector(getJoinConversationView);
  const getChatProfileList = async () => {
    const res = await findChatProfiles();
        
    setChatProfileList(res.data);
  }

  useEffect(() => {
    getChatProfileList();
    if (window.innerWidth < 960) {
      dispatch(composeViewHidden());
      dispatch(currentConversationHeaderViewHidden());
      dispatch(currentConversationViewHidden());
      dispatch(menuViewDisplayed());
    }
    else {
      dispatch(composeViewDisplayed());
    }
  }, []);
  

  return (
    <Card style={{ height: "90vh" }}>
      <FlexRow height="100%">
        <Menu chatProfileList={chatProfileList} />
          {viewCompose &&
            <FlexColumn
              height="100%"
              width="100%"
              bg="backgrounds.content"
              className="border-left justify-content-start"
            >
              <Compose chatProfileList={chatProfileList} />
            </FlexColumn>
          }
          { !viewCompose &&
            <FlexColumn
              height="100%"
              width="100%"
              bg="backgrounds.content"
              className="border-left justify-content-start"
            >
              <Header chatProfileList={chatProfileList} />
              { viewAddMembers &&
                <AddMembers chatProfileList={chatProfileList} />
              }
              <CurrentConversation chatProfileList={chatProfileList} />
            </FlexColumn>
          }
        {!viewCompose && viewConversationMembers &&
          <ConversationMembers />
        }
        {!viewCompose && viewConversationTasks && !viewConversationMembers &&
          <ConversationTasks />
        }
        {viewEditChannel &&
          <EditChannelModal />
        }
        {viewJoinConversation &&
          <JoinConversationDialog />
        }
        {viewLeaveConfirmation &&
          <LeaveConfirmationModal />
        }
      </FlexRow>
    </Card>
  );
};

export { ChatUI };
