import React, {
  useState,
  useEffect
} from "react";
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Container,
} from "reactstrap";
import Icon from "@iconify/react";
import features from "../../data/clienttable/professionalFeatures";

const PurchasePlanCard = ({ currentPlan, purchaseClick, ...rest }) => (
  <Card {...rest}>
    <CardBody>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-center my-3">
          <h4>Your organization is on the <span style={{ fontSize: "33px" }}>{currentPlan} Plan</span>. </h4>
        </div>
        <div className="d-flex justify-content-center mb-4">
          <Button onClick={purchaseClick} size="lg" style={{ background: "#007C80" }}>Upgrade to Professional</Button>
        </div>
        <Container className="col-md-auto" style={{ marginLeft: "auto", marginRight: "auto" }}>
          {features.map((feature, index) =>
            <Row>
              <Col className="col-auto px-0"><Icon color="#00D27A" style={{ width: "1.75rem", height: "1.75rem" }} icon={feature.icon} /></Col>
              <Col className="col-auto"><p>{feature.title}</p></Col>
            </Row>
          )}
        </Container>
        <div className="d-flex justify-content-center">
          <p> Learn more on our <a href="https://www.clienttable.com/pricing" target="_blank">pricing page</a>.</p>
        </div>
      </div>
    </CardBody>
  </Card>
);


export default PurchasePlanCard;