import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable } from 'react-beautiful-dnd';
import {
  editTaskViewHidden,
  editTaskViewDisplayed,
} from "../layout/LayoutActions";
import { getCurrentConversationId,
  getCurrentConversation,
  ConversationFragment,
 } from "../currentConversation/currentConversationModel";
import { toast } from "react-toastify";
import { getTasks, setTasks, deleteTasks } from "../../../../api/tasks.api";
import { Avatar, AvatarVariants, CheckMark } from "../../foundations/components/chat";
import { getGrays } from '../../../../helpers/utils';
import { Icon } from "@iconify/react"
import dragHorizontal from '@iconify/icons-akar-icons/drag-horizontal';

const TaskDropMenu = ({ id, setCurrTaskIndex, index, items, setItems, task, setTask }) => {
  const dispatch = useDispatch();
  
  const onDelete = async e => {
    try {
    var newArray = [...items];
    newArray.splice(index, 1);
    setItems(newArray);
    } catch (error) {
      toast.error('Failed to delete a task')
    }
  }

  return (
    <Dropdown
      onClick={e => e.stopPropagation()}
      align="end"
      className="font-sans-serif"
    >
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
      >
        <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-0" align={'start'}>
        <Dropdown.Item onClick={async () => { 
          await setCurrTaskIndex(index);
          await dispatch (editTaskViewDisplayed());
          }}>Edit</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={async () => {
          await onDelete();
        }} className="text-danger">
          Remove
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const TaskCard = ({
  task: { id, title },
  index,
  setCurrTaskIndex,
  items,
  setItems,
}) => {

  const [hover, setHover] = useState(false);
  const handleChange = async (e) => {
    e.stopPropagation();
    
    try {
    var newArray = [...items];
    var newTask = {
      id: Date.now(), // Overwrite ID so keys of components change.
      title: task.title,
      complete: !task.complete,
    }
    
    // Remove from current array position in Items array.
    newArray.splice(index, 1);

    if (!newTask.complete) {
      newArray = [newTask].concat(newArray)
    } else {
      newArray = newArray.concat(newTask)
    }
    setTask(newTask);
    setItems(newArray);
    } catch (error) {
      toast.error('Failed to toggle task completeness')
    }
  };
  
  const [task, setTask] = useState(
      {
        title: "",
        complete: false,
        id: 1
      }
  );
  
  useEffect(() => {
    if (index >= 0) {
      // It is a valid value

      var newTask = items[index]
      setTask(newTask)
    }
  }, [])

  // styles we need to apply on draggables
  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : '',

    backgroundColor: task.complete ? getGrays(false)[200] : '#fff',
  });

  return (
    <Draggable draggableId={`task${id}`} index={index} position="fixed">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="kanban-item"
        >
          <Card
            style={getItemStyle(snapshot.isDragging, task)}
            className="kanban-item-card hover-actions-trigger"
            onClick={async (e) => {
              await handleChange(e);
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Card.Body>
              <div className="position-relative">
                <TaskDropMenu id={id} setCurrTaskIndex={setCurrTaskIndex} index={index} items={items} setItems={setItems} task={task} setTask={setTask} />
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center">
                 {hover &&
                  <div className="kanban-item-drag-btn d-flex align-items-center mr-2">
                   <Icon style={{width: "1.0rem", height: "1.0rem"}} icon={dragHorizontal} />
                   </div>
                } 
                <div className="kanban-item-drag-btn d-flex align-items-center mr-2">
                  <input type="checkbox" checked={task.complete} id={task.id} />
                </div>
                <p
                  className="mb-0 fw-medium font-sans-serif stretched-link"
                  dangerouslySetInnerHTML={{ __html: task.complete ? title.strike() : title }}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
