import { fileNameFormatter, dateFormatter, iconFormatter } from '../../../utils/formatter';
import { setSortPreference } from '../../../utils/util';
import { getFileSize } from "../../../pages/Documents/DocumentHelpers";

const selectDocumentsColumns = (onFileClick) => [
  {
    dataField: "icon",
    text: "Name",
    headerClasses: "border-0 align-middle td-small",
    classes: "py-2 align-middle",
    formatter: iconFormatter,
    style: {
      textAlign: "center"
    }
  },
  {
    dataField: "name",
    text: "",
    headerClasses: "border-0 align-middle",
    classes: "border-0 py-2 align-middle",
    sort: true,
    formatter: (dataField, file) => fileNameFormatter(dataField, file, onFileClick),
    onSort: (field, order) => {setSortPreference('documentSortPreference', field, order)}
  },
  {
    dataField: "size",
    text: "Size",
    headerClasses: "border-0 align-middle",
    classes: "border-0 py-2 align-middle",
    onSort: (field, order) => {setSortPreference('documentSortPreference', field, order)},
    sortFunc : (a, b, order, dataField, rowA, rowB) => {
      /* Convert string file size to kb before comparison */
      const aKB = getFileSize(a);
      const bKB = getFileSize(b);
      if (order === 'asc') return aKB < bKB ? -1 : aKB > bKB ? 1 : 0;
      else return aKB > bKB ? -1 : aKB < bKB ? 1 : 0;
    }
  },
  {
    dataField: "createdTime",
    text: "Created At",
    headerClasses: "border-0 align-middle",
    classes: "border-0 py-2 align-middle",
    formatter: dateFormatter,
    sort: true,
    onSort: (field, order) => {setSortPreference('documentSortPreference', field, order)}
  },
];

export default selectDocumentsColumns;