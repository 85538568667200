import folderTwotone from "@iconify/icons-ant-design/folder-twotone";
import outlineFolderShared from "@iconify/icons-ic/outline-folder-shared";
import fileTwotone from "@iconify/icons-ant-design/file-twotone";
import fileWordFilled from "@iconify/icons-ant-design/file-word-filled";
import fileExcelFilled from '@iconify/icons-ant-design/file-excel-filled';
import fileImageFilled from '@iconify/icons-ant-design/file-image-filled';
import filePptFilled from '@iconify/icons-ant-design/file-ppt-filled';
import fileTextFilled from '@iconify/icons-ant-design/file-text-filled';
import documentPdf20Filled from '@iconify-icons/fluent/document-pdf-20-filled';
import fileAudio from "@iconify-icons/fa-solid/file-audio";
import fileVideo from "@iconify-icons/fa-solid/file-video";

const folderIcon = {
  icon: folderTwotone,
  color: '#2c7be5',
  size: 24,
}

const sharedFolderIcon = {
  icon: outlineFolderShared,
  color: '#2c7be5',
  size: 24,
}

const fileIcon = {
  icon: fileTwotone,
  color: '',
  size: 24,
}

const imageIcon = {
  icon: fileImageFilled,
  color: '#d0635a',
  size: 24,
}

const videoIcon = {
  icon: fileVideo,
  color: '#5e6e82',
  size: 16,
}

const audioIcon = {
  icon: fileAudio,
  color: '#5e6e82',
  size: 16,
}

const documentIcon = {
  icon: fileWordFilled,
  color: '#4b87e4',
  size: 24,
}

const presentationIcon = {
  icon: filePptFilled,
  color: '#cc4f2e',
  size: 24,
}

const spreadsheetIcon = {
  icon: fileExcelFilled,
  color: '#0f9d58',
  size: 24,
}

const pdfIcon = {
  icon: documentPdf20Filled,
  color: '#e63757',
  size: 24,
}

const textIcon = {
  icon: fileTextFilled,
  color: '',
  size: 24,
}

const mimeTypeIcons = {
  // Word
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": documentIcon,
  "application/msword": documentIcon,

  // Powerpoint
  "application/vnd.ms-powerpoint": presentationIcon,
  "application/vnd.oasis.opendocument.presentation": presentationIcon,

  // Spreadsheet
  "application/vnd.ms-excel": spreadsheetIcon,
  "application/vnd.oasis.opendocument.spreadsheet": spreadsheetIcon,

  // Pdf
  "application/pdf": pdfIcon,

  // Text
  "application/vnd.oasis.opendocument.text": textIcon,
  "application/json": textIcon,
  "application/rtf": textIcon,
  "text/csv": textIcon,
  "text/html": textIcon,
  "text/xml": textIcon,
  "text/plain": textIcon,

  // Images
  "image/tiff": imageIcon,
  "image/svg+xml": imageIcon,
  "image/webp": imageIcon,
  "image/gif": imageIcon,
  "image/png": imageIcon,
  "image/jpeg": imageIcon,
  "image/x-icon": imageIcon,

  // Videos
  "video/mp4": videoIcon,
  "video/x-msvideo": videoIcon,
  "video/x-ms-wmv": videoIcon,
  "video/webm": videoIcon,
  "video/quicktime": videoIcon,
  "video/x-matroska": videoIcon,

  // Audio
  "audio/ogg": audioIcon,
  "audio/mpeg": audioIcon,
  "audio/wav": audioIcon,
}

export default {
  mimeTypeIcons,
  folderIcon,
  sharedFolderIcon,
  fileIcon,
}