import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  appVersion: '0.0.1',
})
const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)
ReactDOM.render(
  <ErrorBoundary>
    <Main>
      <App />
    </Main>
  </ErrorBoundary>,
  document.getElementById('main')
);
