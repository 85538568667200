import React, { useRef, useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import DocumentBreadcrumbs from "../../pages/Documents/DocumentBreadcrumbs";
import selectDocumentsColumns from '../../data/clienttable/tables/selectDocuments';
import driveApi from "../../api/drive.api";
import { Spinner, Col, Row } from "reactstrap";
import DocumentLabel from "./DocumentLabel";
import documentLabels from "../../data/clienttable/documentLabels";

const LoadingSpinner = () => 
  <Col>
    <Row className='justify-content-center'>
      <Spinner color='primary' style={{width: '150'}} className='my-7'/>
    </Row>
  </Col>

const NoFolders = () => <DocumentLabel className="mb-5" {...documentLabels.EMPTY} />

const SelectDocumentsTable = ({
  loadId, // Initial folderId to load
  setSelected, // Set the selected file
  filterFiles // Function to filter out files i.e. only folders, only files, etc.
}) => {

  const table = useRef(null);

  const [loading, setLoading] = useState(true);
  const [folderId, setFolderId] = useState(loadId || '');
  const [itemPath, setItemPath] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    loadItemPath();
  }, []);

  useEffect(() => {
    folderId ? loadItems() : loadItemPath();
  }, [folderId])

  const loadItems = async () => {
    setLoading(true);
    const res = await driveApi.getSubFolders(folderId);
    const folders = filterFiles ? res.data.folders.filter(filterFiles) : res.data.folders;
    setFiles(folders || []);
    setLoading(false);
  };

  const loadItemPath = async () => {
    setLoading(true);
    const res = await driveApi.getItemPath(folderId);
    if (!folderId && res.data.path.length) setFolderId(res.data.path[0].id);
    setItemPath(res.data.path || []);
    setLoading(false);
  }

  const onFolderClick = async (file) => {
    setFolderId(file.id);
    setItemPath(path => {
      const lastItem = path[path.length - 1];
      return lastItem.id === file.id ? path : [...path, { id: file.id, name: file.name }];
    })
    setSelected(null);
  }

  const onBreadcrumbClick = (folder, index) => {
    setItemPath(itemPath.slice(0, index + 1))
    setFolderId(folder.id);
  }

  return (
    <>
      <DocumentBreadcrumbs
        listClassName="p-1"
        itemPath={itemPath}
        onClick={onBreadcrumbClick}
      />
      <div className="min-vh-md-25">
        <BootstrapTable
          ref={table}
          bootstrap4
          keyField="id"
          data={files}
          columns={selectDocumentsColumns(onFolderClick)}
          selectRow={{
            mode: 'radio',
            selectColumnStyle: { border: 0, verticalAlign: 'middle', width: '3vh'},
            onSelect: (row) => {
              setSelected({id: row.id, name: row.name});
            }
          }}
          bordered={false}
          classes={`${files.length ? "table-hover" : ""} table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap`}
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
          sort={{ dataField: 'name', order: 'asc' }}
          noDataIndication={loading ? <LoadingSpinner/> : <NoFolders/> }
        />
      </div>
    </>
  )
}

export default SelectDocumentsTable;