import React, { Fragment, useState, useEffect } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Button,
  Input,
} from "reactstrap";

const RenameModal = ({ rename, file, isOpen, closeModal }) => {
  const [name, setName] = useState(file ? file.name : "");

  const updateName = (e) => {
    e.preventDefault();
    rename(file.id, name, file.isFolder);
  };

  useEffect(() => {
    if (file) setName(file.name || "");
  }, [file && file.name]);

  return (
    <div style={{ borderRight: "1px solid gray", paddingRight: "10px", marginLeft: "10px" }}>
      <Fragment>
        <Modal isOpen={isOpen} toggle={() => closeModal(!isOpen)}>
          <ModalHeader>Rename</ModalHeader>
          <form onSubmit={updateName}>
            <ModalBody>
              <Input
                placeholder='Change name'
                value={name}
                onChange={({ target }) => setName(target.value)}
                type='text'
              />
            </ModalBody>
            <ModalFooter>
              <Button color='primary' type='submit'>
                Rename
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </Fragment>
    </div>
  );
};

export default RenameModal;