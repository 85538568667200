import React from "react";
import { Link } from 'react-router-dom';
import folderOpenTwotone from '@iconify/icons-ant-design/folder-open-twotone';
import warningTwotone from '@iconify/icons-ant-design/warning-twotone';

const documentLabels = {
  EMPTY: {
    icon: folderOpenTwotone,
    header: 'This folder is empty',
    label: 'Drag and drop files to upload',
    tooltip: 'Drag and drop files to upload',
  },
  NO_PERMISSIONS: {
    icon: warningTwotone,
    header: 'You do not have access, contact your administrator',
    label: <Link to='/documents'>Return to documents home</Link>,
  },
  NO_SHARED: {
    icon: folderOpenTwotone,
    header: 'No folder has been shared with you',
    label: 'Contact your admin',
    tooltip: 'Select a shared folder to upload files and more',
  }
}

export default documentLabels;