import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import Flex from '../clienttable/Flex';
import Icon from '@iconify/react';
import homeOutlined from '@iconify/icons-ant-design/home-outlined';
import folderOutlined from '@iconify/icons-ant-design/folder-outlined';
import contactsOutlined from '@iconify/icons-ant-design/contacts-outlined';
import userOutlined from '@iconify/icons-ant-design/user-outlined';
import settingOutlined from '@iconify/icons-ant-design/setting-outlined';
import commentOutlined from '@iconify/icons-ant-design/comment-outlined';
import { FlexRow } from "../../pages/Messages/foundations/components/layout";
import { getLoggedInUserId } from "../../pages/Messages/features/authentication/authenticationModel";
import { getCurrentMembershipMap, getLastReadMessageTimetoken, calculateLastReadMessageTimetoken, getLastMessageAuthor } from "../../pages/Messages/features/joinedConversations/MyConversations";
import { getLatestMessagesById } from "../../pages/Messages/features/messages/lastMessageModel"
import { useSelector } from "react-redux";
import { useState } from "react";
import NotificationService from "../../services/NotificationService";
import { updateLastNotifiedTime } from '../../../src/api/user.api';

const iconMap = {
  'homeOutlined': homeOutlined,
  'folderOutlined': folderOutlined,
  'contactsOutlined': contactsOutlined,
  'userOutlined': userOutlined,
  'settingOutlined': settingOutlined,
  'commentOutlined': commentOutlined
}

const NavbarVerticalMenuItem = ({ route }) => {
  return (
  <FlexRow>
  {route.icon && (
    <span className="nav-link-icon mr-2">
        <Icon style={{width:"1.75rem", height:"1.75rem"}} icon={iconMap[route.icon]}/>
    </span>
  )}
  <Flex justify="between" align="center mb-2" minWidth="80%" style={{fontSize: '1.0rem', minWidth: "80%"}}>
    <span className="nav-link-text ml-2">{route.name}</span>
  </Flex>
  </FlexRow>
)
};

const NavbarVerticalMessagingItem = ({ route, isCollapsed }) => {
  const latestMessages = useSelector(getLatestMessagesById);
  const membershipMap = useSelector(getCurrentMembershipMap);
  const currentUserId = useSelector(getLoggedInUserId);
  const [notifyNumber, setNotifyNumber] = useState(0);

  var shouldNotify = false;
  var notifyString = "";
  var newNotifyNumber = 0;

  for (const conversation in membershipMap) {
    var lastRead = getLastReadMessageTimetoken(membershipMap, conversation);
    var lastMessage = calculateLastReadMessageTimetoken(conversation, latestMessages[conversation.toString()]);
    var lastAuthor = getLastMessageAuthor(conversation, latestMessages[conversation.toString()]);

    if ((lastAuthor !== "") && (lastAuthor !== currentUserId) && (lastMessage > lastRead)) {
      newNotifyNumber += 1;
      shouldNotify = true
      notifyString = newNotifyNumber + ""
    }
  }
  
  // Should we notify?
  if (newNotifyNumber > notifyNumber) {
    NotificationService.notifyPing();
    var currTimetoken = Date.now()
    updateLastNotifiedTime(currentUserId, currTimetoken);
  }

  NotificationService.notifyTitle(newNotifyNumber);

  if (newNotifyNumber !== notifyNumber) {
    setNotifyNumber(newNotifyNumber);
  }

  return (
  <FlexRow>
  {route.icon && (
    <span className="nav-link-icon mr-2">
      {(isCollapsed && shouldNotify) ? 
        <Icon color="red" style={{width:"1.75rem", height:"1.75rem"}} icon={iconMap[route.icon]}/>
        :
        <Icon style={{width:"1.75rem", height:"1.75rem"}} icon={iconMap[route.icon]}/>
      }
    </span>
  )}
  <Flex justify="between" align="center mb-2" minWidth="80%" style={{fontSize: '1.0rem', minWidth: "80%"}}>
    <span className="nav-link-text ml-2">{route.name}</span>
    {shouldNotify &&
    <Badge color={'danger'} pill>
      {notifyString}
    </Badge>
    }
  </Flex>
  </FlexRow>
)
};

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  }).isRequired,
};

NavbarVerticalMessagingItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  }).isRequired,
  isCollapsed: PropTypes.bool.isRequired
};

export { NavbarVerticalMenuItem, NavbarVerticalMessagingItem };
