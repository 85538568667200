import React, { useState, useEffect, Fragment } from "react";
import { ConversationTaskListButton } from "../ConversationTaskListButton";
import { ConversationOccupancy } from "../ConversationOccupancy";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentConversationId,
  getCurrentConversation,
  ConversationFragment,
} from "../currentConversationModel";
import { menuViewDisplayed, editChannelViewDisplayed, currentConversationHeaderViewHidden, currentConversationViewHidden } from "../../../features/layout/LayoutActions";
import { Icons, Icon as PNIcon } from "../../../foundations/components/presentation";
import { Avatar, AvatarVariants } from "../../../foundations/components/chat";
import { fullNameInitials } from "../../../../../utils/util";
import Icon from "@iconify/react";
import { StyledBox } from "../../../foundations/components/layout";
import userDeleteOutlined from "@iconify/icons-ant-design/user-delete-outlined";
import teamOutlined from "@iconify/icons-ant-design/team-outlined";
import editOutlined from "@iconify/icons-ant-design/edit-outlined";
import SessionService from "../../../../../services/SessionService";
import { directChatMember, calculateLastReadMessageTimetoken } from "../../../features/joinedConversations/MyConversations/MyConversations"
import { Button, Tooltip } from "reactstrap";
import moment from "moment";
import { getCurrentConversationHeaderView } from "../../layout/Selectors";
import { getLatestMessagesById } from "../../messages/lastMessageModel"


const Header = ({ chatProfileList }) => {
  const currentConversationId = useSelector(getCurrentConversationId);
  const conversation: ConversationFragment = useSelector(
    getCurrentConversation
  );
  const latestMessages = useSelector(getLatestMessagesById);
  const viewCurrentConversationHeader = useSelector(getCurrentConversationHeaderView);

  const dispatch = useDispatch();

  var lastMessageDate = "No message sent yet";
  var lastMessageTimetoken = calculateLastReadMessageTimetoken(currentConversationId, latestMessages[currentConversationId.toString()]);

  if (lastMessageTimetoken !== -1) {
    var date = new Date(lastMessageTimetoken / 10000);
    lastMessageDate = moment(date).format("MMMM DD, YYYY");
  }

  const { id: myUserId, role: myRole } = SessionService.getUser();

  const [conversationName, setConversationName] = useState('');
  const [isUserRemoved, setIsUserRemoved] = useState(false);
  const [isToolTipOpen, setToolTipOpen] = useState(false);

  useEffect(() => {
    if (currentConversationId !== "" && conversation) {
      if (conversation.custom && conversation.custom.chat && chatProfileList) {
        const chatMember = directChatMember(myUserId, conversation.name, chatProfileList);
        setConversationName(chatMember ? `${chatMember.firstName} ${chatMember.lastName}` : "Removed User");
        setIsUserRemoved(!chatMember);
      }
      else {
        setConversationName(conversation.name);
        setIsUserRemoved(false);
      }
    }
  }, [currentConversationId, chatProfileList, conversation])


  return viewCurrentConversationHeader && currentConversationId && conversation && conversation.custom ?
    <div className={`d-flex flex-row border-bottom p-3 max-vh-25 ${conversation.custom.chat && "align-items-center"}`}>
      <StyledBox display={["block", "none"]} color="active" marginRight="7">
        <PNIcon
          icon={Icons.Back}
          onClick={() => {
            dispatch(menuViewDisplayed());
            dispatch(currentConversationHeaderViewHidden());
            dispatch(currentConversationViewHidden());
          }}
          title="Back"
          clickable
          color="#0275d8" // Primary means purple to PubNub so have to use the hex value.
        />
      </StyledBox>

      <div className="mr-2">
        <Avatar
          variant={AvatarVariants.ROUND}
          className={isUserRemoved ? "bg-500" : conversation.custom.chat ? "bg-900" : "bg-primary"}
        >
          {isUserRemoved
            ? <Icon className="text-light w-75 h-75" icon={userDeleteOutlined} />
            : conversation.custom.chat
              ? <b className="text-light fs--2">{fullNameInitials(conversationName)}</b>
              : <Icon className="text-light w-75 h-75" icon={teamOutlined} />
          }
        </Avatar>
      </div>

      <div className="d-flex flex-column ml-2 mr-auto" style={{overflow: "hidden"}}>
        <h6 className="mb-0 mr-2" style={{ wordBreak: "break-word"}}>{conversationName}</h6>
        {!conversation.custom.chat &&
          <div className="text-500" style={{ wordBreak: "break-word", display: "flex" }}>
            <Fragment>
              <span style={{
                maxWidth: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginRight: "0.5rem"
              }} id="description"> {conversation.description || `Add a description`} </span>
              {
                conversation.description && 
                <Tooltip
                  placement="top"
                  isOpen={isToolTipOpen}
                  target="description"
                  autohide={false}
                  toggle={() => setToolTipOpen(!isToolTipOpen)}
                  trigger="click hover"
                >
                  {conversation.description}
                </Tooltip>
              }
            </Fragment>
            
            {['OWNER', 'STAFF'].includes(myRole) &&
              <Button
                className="p-0"
                color="#fff"
                size="sm"
                style={{width: "20px", height: "20px", boxShadow: "none"}}
                onClick={() => dispatch(editChannelViewDisplayed())}
              >
                <Icon width="20px" height="20px" icon={editOutlined} />
              </Button>
            }
          </div>
        }
      </div>
      <div className="d-flex flex-column ml-3">
        <span className="text-right mb-auto d-none d-sm-none d-md-none d-lg-block" style={{ whiteSpace: "nowrap" }}>Last message: {lastMessageDate}</span>
        <div className="d-flex flex-row justify-content-end">
          <ConversationTaskListButton />
          {!conversation.custom.chat && <ConversationOccupancy />}
        </div>
      </div>
    </div>
    : null;
};

export { Header };
