import React, {useEffect, useState}from 'react';
import { Form, Row, Col, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, InputGroup, InputGroupAddon, Button, ModalFooter} from 'reactstrap';
import { toast } from 'react-toastify';
import editOutlined from '@iconify/icons-ant-design/edit-outlined';
import ButtonIcon from '../common/ButtonIcon';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon } from '@iconify/react';
import deleteOutlined from '@iconify/icons-ant-design/delete-outlined';
import dragOutlined from '@iconify/icons-ant-design/drag-outlined';
import InfoLabel from './common/InfoLabel';
import SessionService from '../../services/SessionService';
import { get, updateCustomFields } from '../../api/organization.api';
import { get as getUser } from '../../api/user.api';
import { useParams } from 'react-router-dom';

const CustomFields = ({user, setUser}) => {
    
    const orgTemplate = 
    {
        "Data Fields": {},
        "ordering": [],
        "lastIdCreated": 0
    };

    const userTemplate = {
        "value": ""
    }

    const { id } =  useParams();

    const [isEditing, setIsEditing] = useState(false);
    const [orgJSON, setOrgJSON] = useState(orgTemplate);
    const [userJSON, setUserJSON] = useState({});
    const [deletedFields, setDeletedFields] = useState([]);
    const [copyOfData, setCopyOfData] = useState({});
    const [finishedFetching, setFinishedFetching] = useState(false);
    const org = SessionService.getOrganization();

    const toggleEditing = (isEditing) => {
        if (isEditing) {
            setCopyOfData({user: userJSON, org: orgJSON})
            setIsEditing(true);
        } else {
            setOrgJSON(copyOfData.org);
            setUserJSON(copyOfData.user);
            setIsEditing(false);
        }

        setDeletedFields([]);
    }

    const onDragEnd = (result) => {
        // If dropped outside the list
        if (!result.destination) return;

        const new_order = reorder(
            orgJSON.ordering,
            result.source.index,
            result.destination.index
        );

        setOrgJSON(prev =>  ({...prev, ordering: new_order}) );
    }

    const handleSubmit = e => {
        e.preventDefault();
    }

    const submitOrgJSONChanges = async (e) => {
        e.preventDefault();
        const ojson = orgJSON;
        const dfields = deletedFields;
        
        if (deletedFields.length !== 0){
            for (const field of deletedFields){
                delete ojson['Data Fields'][field];
            }

            const new_ordering = ojson['ordering'].filter(field => !deletedFields.includes(field));
            ojson['ordering'] = new_ordering;
        }
        
        const changedFields = {};
        for (const field in ojson['Data Fields']){
            let f = ojson['Data Fields'][field];
            if (f.isChanged){
                delete f.isChanged;
                changedFields[field] = f;
            } 
        }
        
        /* API call to backend, re-render components */
        try {
            await updateCustomFields(changedFields, dfields, ojson['ordering']);
            setJSONData();
            toggleEditing(false);
            toast.success('Updated Custom Fields');
        } catch (e) {
            toggleEditing(false);
        }
    }

    const customInputColumn = (colData, value, id) => {
        return (
            <Col>
                <FormGroup>
                    <Label for="exampleName">{colData.label}</Label>
                    <Input 
                    type={colData.type} 
                    id={id}
                    value={value} 
                    onChange={(e) => {handleFieldValueChange(e.target.value, id)}}
                    // minLength="1" add these in parameters maybe?
                    // maxLength="20"
                    />
                    {/* Could be based on parameters -> <FormText>Make sure this field is not empty.</FormText> */}
              </FormGroup>
            </Col>
        )
    }

    const createRows = (cols, ordering, userJSON) => {
        let rows = []
        for (let i = 0; i < ordering.length; i += 2){
            rows.push(
                <Row>
                    {/* Refactor this */}
                    {customInputColumn(cols[ordering[i]], userJSON['Data Fields'][ordering[i]].value, ordering[i])}
                    {i + 1 < ordering.length ? customInputColumn(cols[ordering[i + 1]], userJSON['Data Fields'][ordering[i + 1]].value, ordering[i+1]) : <Col></Col>}
                </Row>
            )
        }
        return rows;
    }

    const handleFieldNameChange = (e) => {
        const new_label = e.target.value;
        const id = e.target.id;

        setOrgJSON(prev => ( // Refactor this?
            {
                ...prev,
                "Data Fields": {
                    ...prev["Data Fields"],
                    [id]: {
                        ...prev["Data Fields"][id],
                        label: new_label,
                        isChanged: true,
                    }
                }
            }
        ));
    }

    const handleFieldValueChange = (val, id) => {
        setUserJSON(prev => {
            const new_json = {
                ...prev,
                "Data Fields": {
                    ...prev["Data Fields"],
                    [id]: {value: val},
                }
            }
            setUser(prev => ({...prev, customFieldsValues: new_json}));
            return new_json;
        });
    }

    const handleDeleteFieldsChange = (id) => {
        let i = deletedFields.indexOf(id);
        if (i !== -1){
            setDeletedFields(deletedFields.filter((e) => (e !== id )));
        } else {
            setDeletedFields(prev => ([...prev, id]));
        }
    }

    const addField = () => {
        const template = {
            "label": "",
            "type": "text",
            "isChanged": true
        }
        const new_id = orgJSON.lastIdCreated + 1;

        setOrgJSON(prev => ({
            "Data Fields": {
              ...prev["Data Fields"],
              [new_id]: template,
            },
            lastIdCreated: new_id,
            ordering: [...prev.ordering, new_id],
        }));
    }

    const setJSONData = async () => {
        setFinishedFetching(false);
        /* Fetch data */
        const organization = await get(org.id);
        const user = await getUser(id);

        let ujson = null;

        if (user.data.customFieldsValues == null){
            ujson = {};
        } else {
            ujson = JSON.parse(user.data.customFieldsValues);
        }

        if (organization.data.customFields !== null){
            let parsedOrgJSON = JSON.parse(organization.data.customFields);
            setOrgJSON(parsedOrgJSON);
            for (const field in parsedOrgJSON['Data Fields']){
                if (!ujson['Data Fields'][field]){
                    ujson['Data Fields'][field] = userTemplate;
                }
            }
        } else {
            setOrgJSON(orgTemplate);
        }
        setUserJSON(ujson);
        setFinishedFetching(true);
    }

    useEffect (() => {
        setJSONData();
    }, []);

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        margin: `0 0 8px 0`,
        background: isDragging ? "white" : "white", /* When dragging */
        ...draggableStyle
    });
      
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "white" : "white",
        padding: 8,
        width: '100%'
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };
      
  return (
    <>
        {/* Display custom fields */}
        <Form onSubmit={handleSubmit}>
            {/* Custom fields */}
            {(finishedFetching && !isEditing) && createRows(orgJSON['Data Fields'], orgJSON['ordering'], userJSON)}

            {/* Submit button */}
            <ButtonIcon className="mr-2" color="falcon-default" icon={editOutlined} transform="shrink-3" onClick={() => {toggleEditing(true)}}>
                Modify Fields
            </ButtonIcon>
        </Form>

        {/* Edit custom field names - user clicks the modify fields button */}
        <Modal isOpen={isEditing} toggle={() => toggleEditing(!isEditing)}>
        {finishedFetching && <Form onSubmit={submitOrgJSONChanges}>
        <FormGroup>
            <ModalHeader>Modify Custom Fields</ModalHeader>
            <ModalBody>
                <InfoLabel className="mb-2" text="Changes will occur on all client profiles" />
                <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    >
                    {orgJSON.ordering.map((item, index) => (
                        <Draggable key={item.toString()} draggableId={item.toString()} index={index}>
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            >
                            <Label for="label" >Field Name</Label>
                            <InputGroup>
                                <Input required type='text' pattern='^(?!\s*$).+' maxLength="256" placeholder={"Enter field name"} style={{backgroundColor: deletedFields.includes(item) ? '#FAD7DD' : ''}} value={orgJSON["Data Fields"][item].label} onChange={handleFieldNameChange} id={item}/>
                                <InputGroupAddon addonType="append">
                                    <Icon style={{cursor: 'pointer', color: '#E63757', width: "2.5rem", height: "2.5rem", padding: "0 0 10px 10px" }} icon={deleteOutlined} onClick={() => {handleDeleteFieldsChange(item)}} />
                                    <Icon style={{color: '#2C7BE5', width: "2.5rem", height: "2.5rem", padding: "0 0 10px 10px" }} icon={dragOutlined} />
                                </InputGroupAddon>
                            </InputGroup>
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            <ButtonIcon className="mr-2" color="falcon-default" icon="plus" transform="shrink-3" onClick={() => {addField()}}> Add Field </ButtonIcon>
        </ModalBody>
        </FormGroup>
        <ModalFooter>
            <Button outline color={'primary'} size="sm" className="px-4 ml-2" onClick={() => {toggleEditing(false)}}>
            Cancel
            </Button>
            <Button color={'primary'} size="sm" className="px-4 ml-2">
            Save
            </Button>
        </ModalFooter>
        </Form>}
      </Modal>
    </>
  )
}

export default CustomFields;