import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import FalconCardHeader from './FalconCardHeader';
import { toast } from 'react-toastify';
import { deleteUser } from '../../api/user.api';
import SetupUserInvitationEmail from './SetupUserInvitationEmail';
import { Redirect } from 'react-router-dom';
import logger from '../../utils/logger';

const ManageAccountCard = ({user}) => {
  const [openEmail, openEmailModal] = useState(false);
  const [deletePressed, setDeletePressed] = useState(false)

  const handleDeleteUser = async e => {
    e.preventDefault();
    try {
      await deleteUser(user.id);
      toast.success(`Successfully deleted user`)
      openEmailModal(!openEmail)
      setDeletePressed(true);
    } catch (err) {
      logger.warn(err, `Error while deleting user ${user.id}`)
      toast.error(err);
    }
  }
  
  if (deletePressed){
    if (user.role == 'CLIENT'){
      return <Redirect to= '/clients'/>
    } else {
      return <Redirect to='/settings/staff'/>
    }
  }
  return (
    <>
    <Card className="mb-3">
      <FalconCardHeader title="Manage Account" light={false} />
      <CardBody className="bg-light">
          <Row>
            <Col md>
              <Button block color={'falcon-danger'} className="my-2" onClick={() => openEmailModal(!openEmail)}>Delete User</Button>
              <p>User profile will be permenantly deleted. This action cannot be reversed.</p>
              <p>Documents stored in your connected drive are unaffected.</p>
            </Col>
            <Col md> <SetupUserInvitationEmail user={user}/> </Col>
          </Row>
      </CardBody>
    </Card>

      <Modal isOpen={openEmail} toggle={() => openEmailModal(!openEmail)}>
        <ModalHeader>Delete User</ModalHeader> 
        <ModalBody>
          <div>
            <Col>
              <Row>
                <p>Are you sure you would like to delete the selected user(s)? <strong>This action cannot be reversed.</strong></p>
              </Row>
              <Row><Button color="falcon-danger" className="mb-2" onClick={handleDeleteUser} size="lg" block> Delete</Button></Row>
              <Row><Button color="falcon-primary" onClick={() => openEmailModal(!openEmail)} size="lg" block>Cancel</Button></Row>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

ManageAccountCard.protoTypes = {
  user: PropTypes.object.isRequired
}

export default ManageAccountCard;