import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types';
import { Card, CardBody, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button, FormText, Row, Col } from 'reactstrap';
import FalconCardHeader from './FalconCardHeader';
import { Icon } from '@iconify/react';
import editOutlined from '@iconify/icons-ant-design/edit-outlined';
import { toast } from 'react-toastify';
import { changePassword } from '../../api/user.api';
import { updateEmail } from '../../api/user.api';
import { dateFormatter } from '../../utils/formatter';
import FormGroupInput from '../../components/common/FormGroupInput';
import SessionService from '../../services/SessionService';
import logger from '../../utils/logger';

const LoginAndPasswordProfileCard = ({ user, setUser }) => {
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');

  const resetEmailModal = () => {
    setIsOpenEmailModal(false);
    setCurrentPassword('');
    setNewEmail('');
    setConfirmEmail('');
  }

  const resetPasswordModal = () => {
    setIsOpenPasswordModal(false);
    setCurrentPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  const submitEmailForm = async e => {
    e.preventDefault();
    if (newEmail !== confirmEmail) return toast.error(`Emails did not match!`);
    try {
      await updateEmail(user.id, currentPassword, newEmail)
      SessionService.setEmail(newEmail);
      SessionService.setUser({...SessionService.getUser(), email: newEmail});
      toast.success(`Successfully edited email`)
      setIsOpenEmailModal(false);
      resetEmailModal();
    } catch (err) {
      logger.warn(err, `Error while updating email for user ${user.id}`)
      toast.error(err);
    }
  }

  const submitPasswordForm = async e => {
    e.preventDefault();
    if (newPassword !== confirmPassword) return toast.error(`Passwords did not match!`);
    try {
      await changePassword(currentPassword, newPassword)
      toast.success(`Successfully changed password`)
      setIsOpenPasswordModal(false);
      resetPasswordModal();
    } catch (err) {
      logger.warn(err, `Error while updating password for user ${user.id}`)
      toast.error(err);
    }
  }

  const CustomerDetailRow = ({ title, isLastItem, children }) => (
    <Row>
      <Col xs={5} sm={4}>
        <p
          className={('font-weight-semi-bold', {
            'mb-0': isLastItem,
            'mb-1': !isLastItem
          })}
        >
          {title}
        </p>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Login and Password" />
        <CardBody className="bg-light border-top">
          <Row>
            <Col lg className="col-xxl-5">
              <CustomerDetailRow title="Login email:">
                <td>{user.email} <Icon style={{ color: '#2C7BE5', width: "2rem", height: "2rem" }} icon={editOutlined} onClick={() => setIsOpenEmailModal(!isOpenEmailModal)} /></td>
              </CustomerDetailRow>
              <CustomerDetailRow title="Password:">
                <td>*************** <Icon style={{ color: '#2C7BE5', width: "2rem", height: "2rem" }} icon={editOutlined} onClick={() => setIsOpenPasswordModal(!isOpenPasswordModal)} /></td>
              </CustomerDetailRow>
              <CustomerDetailRow title="Profile ID:">
                <td>{user.id}</td>
              </CustomerDetailRow>
              <CustomerDetailRow title="Last Active:">
                <td>{dateFormatter(user.lastActivity)}</td>
              </CustomerDetailRow>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={isOpenEmailModal} toggle={() => setIsOpenEmailModal(!isOpenEmailModal)}>
        <Form onSubmit={submitEmailForm}>
          <ModalHeader>Change Email</ModalHeader>
          <ModalBody>
            <FormGroupInput
              label="Current Password"
              value={currentPassword}
              onChange={({ target }) => setCurrentPassword(target.value)}
              type="password"
            />
            <FormGroupInput
              label="New Email"
              value={newEmail}
              onChange={({ target }) => setNewEmail(target.value)}
              type="email"
              required
              minLength="1"
            />
            <FormText>Make sure this field is not empty.</FormText>
            <FormGroupInput
              label="Confirm Email"
              value={confirmEmail}
              onChange={({ target }) => setConfirmEmail(target.value)}
              type="email"
              required
              minLength="1"
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={resetEmailModal}>Cancel</Button>
            <Button type="submit" color="primary">Update Email</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isOpenPasswordModal} toggle={() => setIsOpenPasswordModal(!isOpenPasswordModal)}>
        <Form onSubmit={submitPasswordForm}>
          <ModalHeader>Update Password</ModalHeader>
          <ModalBody>
            <FormGroupInput
              label="Current Password"
              id="current-password"
              value={currentPassword}
              onChange={({ target }) => setCurrentPassword(target.value)}
              type="password"
            />
            <FormGroupInput
              label="New Password"
              id="new-password"
              value={newPassword}
              onChange={({ target }) => setNewPassword(target.value)}
              type="password"
            />
            <FormText>Must be at least 8 characters including a number, lowercase and uppercase letter, and symbol (!@# etc)</FormText>
            <FormGroupInput
              label="Confirm Password"
              id="confirm-password"
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
              type="password"
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={resetPasswordModal}>Cancel</Button>
            <Button type="submit" color="primary">Update Password</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}

LoginAndPasswordProfileCard.protoTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired
}

export default LoginAndPasswordProfileCard;
