import React, {
  useEffect,
  useState
} from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import FalconCardHeader from '../../components/clienttable/FalconCardHeader';
import AccordionList from "../../components/clienttable/common/AccordionList";

const BillingFAQCard = ({ data, ...rest }) => {
  return (
    <Card {...rest}>
      <FalconCardHeader title="Billing FAQ" light={false} />
      <CardBody className="bg-light">
        <AccordionList
          data={data}
          numbered={true}
        />
      </CardBody>
    </Card>
  );
}

export default BillingFAQCard;