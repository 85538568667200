const _audioUrl = "https://ctbllogo.s3.amazonaws.com/mixkit-bubble-pop-up-alert-notification-2357.mp3";
const _audio = new Audio(_audioUrl);
const _windowTitle = 'Client Portal';
let _notification;

// Request permissions for the notifications feature
// Requires https
// Returns whether notifications have been granted
const requestPermission = async () => {
  if (Notification) {
    if (Notification.permission === 'default')
      await Notification.requestPermission();
    return Notification.permission === 'granted';
  }
  return false;
}

// Create a notification if permission is granted
// Requires access to the Notifications API
const notify = (title='Client Portal', body='') => {
  if (Notification && Notification.permission === 'granted')
    _notification = new Notification(title, { body })
}

// Close a notification
// Requires access to the Notifications API
const notifyClose = () => {
  _notification && _notification.close();
}

// Plays an audio ping
const notifyPing = () => {
  _audio.play();
}

// Display a message count on the window title
const notifyTitle = (unreadCount) => {
  window.document.title = unreadCount <= 0 ?
    _windowTitle :
    `(${unreadCount}) ${_windowTitle}`;
}

const NotificationService = {
  requestPermission,
  notify,
  notifyPing,
  notifyClose,
  notifyTitle,
}

export default NotificationService;